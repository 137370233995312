import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useParams } from "react-router-dom";
import ENDPOINT from "../../context/EndPoint";
import axios from "axios";
import { Modal } from "react-bootstrap";

import TextField from '@mui/material/TextField';
import AdminGetAssignmentSubmissions from "../../actions/student/AdminGetAssignmentSubmissions";
import dateFormat from "dateformat";
import AdminEditStudent from "../../actions/student/AdiminEditStudent";
import AdminUnlockAccess from "../../actions/course/AdminUnlockAccess";
import GetUserPersonalBadges from "../../actions/user/GetUserPersonalBadges";

import TextareaAutosize from '@mui/material/TextareaAutosize';
import AdminGetProjectSubmissions from "../../actions/student/AdminGetProjectSubmissions";
import GetStudentCourseBadges from "../../actions/student/GetStudentCourseBadges";
import SecurityHeaders from "../../context/SecurityHeaders";
import StudentReports from "./StudentReport";
import StudentAssignmentsSubmissions from "./StudentAssignmentsSubmissions";
import StudentProjects from "./StudentProjects";
import ProjectSubmissionsList from "../projects/ProjectSubmissionsList";
import PersonalBadgesList from "../badges/PersonalBadgesList";
import CourseBadgesList from "../badges/CourseBadgesList";
import StudentsDoubtSupportList from "../support/StudentsDoubtSupportList";
import NoPermissionModal from "../../components/modals/NoPermissionModal";
import AdminGetStudentDoubts from "../../actions/student/AdminGetStudentDoubts";
import StudentIDGenerator from "./StudentIDGenerator";
import StudentAttendance from "./StudentAttendance";
import DefaultModal from "../../components/modals/DefaultModal";
import { useActionsContext } from "../../context/ActionsContext";
import AddCourseAssignment from "../courses/AddCourseAssignment";
import AdminAddStudentReport from "../../actions/student/AdminAddStudentReport";
import { Close } from "@material-ui/icons";
import DefaultToast from "../../components/toasts/DefaultToast";
import { connect } from "react-redux";
const StudentProfile = ({value,dispatch}) => {
  const { user } = useContext(AuthContext);
  const userdata = user;
  const params = useParams();
  const purchase_id = params.student_id;
  const [status, setstatus] = useState("all");

  const [query, setquery] = useState(null);

  const [section, setsection] = useState(JSON.parse(sessionStorage.getItem("student_section"))||"reports");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let [profile, setprofile] = useState("start");

  useEffect(() => {
    getinternships();
  }, []);


  const Reload = ()=>{
    getinternships()
  }
  const [doubts, setdoubts] = useState(null);

  const GetDoubts = async () => {
    const res = await AdminGetStudentDoubts({
      payload: {
        user: user.SessionId,
        course_url: profile.course_url,
        user_id: profile.user_id,
      },
    });
    if (res) {
      if (res.status === "success") {
        setdoubts(res.data);
      }
    }
  };

  const [submissions, setsubmissions] = useState(null);
  const GetAssignmentSubmissions = async () => {
    const res = await AdminGetAssignmentSubmissions({
      payload: {
        user: user.SessionId,
        course_url: profile.course_url,
        user_id: profile.user_id,
      },
    });
    if (res) {
      if (res.status === "success") {
        setsubmissions(res.data);
      }
    }
  };

  const [projects, setprojects] = useState(null);

  const GetProjectSubmissions = async () => {
    const res = await AdminGetProjectSubmissions({
      payload: {
        user: user.SessionId,
        course_url: profile.course_url,
        user_id: profile.user_id,
      },
    });
    if (res) {
      if (res.status === "success") {
        setprojects(res.data);
      }
    }
  };

  useEffect(() => {
    if (section === "assignments") {
      GetAssignmentSubmissions();
    }
    if (section === "projects") {
      GetProjectSubmissions();
    }
    if (section === "doubts") {
      GetDoubts();
    }
    if (section === "attendance") {
    }
    if (section === "personal_badges") {
      NewUserBadges({user_id:profile.user_id})
    }
  }, [section]);

  const getinternships = async () => {
    setprofile("start");
    const data = new FormData();
    data.append("session", user.SessionId);
    if (query) {
      data.append("query", query);
    }
    if (status) {
      data.append("status", status);
    }
    data.append("purchase_id", purchase_id);
    const res = await axios.post(
      ENDPOINT + "student-course-profile.php",
      data,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    if (res) {
      //  // console.log("profile is " + JSON.stringify(res));
      if (res.data.status === "success") {
        dispatch({type:'STUDENT_PROFILE',payload:res.data.data})
        setprofile(res.data.data);
        setbatchid(res.data.data.batch_id)
        setprogress(res.data.data.progress)
        setteamid(res.data.data.team_id)
        setselectedname(res.data.data.first_name);
      } else {
      }
    }
  };
 

  const updateRequest = async ({ ticketstatus, ticketid }) => {
    if (userdata !== null) {
      fetch(
        `${ENDPOINT}admin-update-doubt-request.php?session=${user.SessionId}&status=${ticketstatus}&id=${ticketid}`
      )
        .then((r) => r.json())
        .then((result) => {
          // //  // console.log(JSON.stringify(result))
          if (result.status === "success") {
            settoasttitle("Successful");
            settoastmessage("Status changed to " + ticketstatus);
            settoast(true);
            getinternships();
          } else {
            settoasttitle("Something went wrong !");
            settoastmessage("Please try again... ");
            settoast(true);
          }
        });
    } else {
    }
  };

  const [toast, settoast] = useState(false);
  const [toasttitle, settoasttitle] = useState(null);

  const [toastmessage, settoastmessage] = useState(null);

  const NewUserBadges = async ({ user_id }) => {
    const res = await GetUserPersonalBadges({
      payload: {
        user: user.SessionId,
        user_id: user_id,
      },
    });
    if (res) {
      if (res.status === "success") {
        setpersonalbadges(res.badges);
      }
    }
  };

  const NewCourseBadges = async ({ user_id }) => {
    const res = await GetStudentCourseBadges({
      payload: {
        user: user.SessionId,
        user_id: user_id,
        course_url: profile.course_url,
      },
    });
    if (res) {
    }
    if (res.status === "success") {
      setcoursebadges(res.badges);
    }
  };

  const [personalbadges, setpersonalbadges] = useState(null);
  useEffect(() => {
    if (section === "personal_badges") {
      NewUserBadges({ user_id: profile.user_id });
    }

    if (section === "course_badges") {
      NewCourseBadges({ user_id: profile.user_id });
    }
  }, [section]);

  const [studentid, setstudentid] = useState(profile.payment_id);
  const [batchid, setbatchid] = useState(profile.batch_id);
  const [teamid, setteamid] = useState(profile.team_id);
  const [progress, setprogress] = useState(0);
  const [marks, setmarks] = useState(0);
  const [points, setpoints] = useState(0);

  const [editmodal, seteditmodal] = useState(false);
  const [coursebadges, setcoursebadges] = useState(null);

  const EditStudent = async (e) => {
    e.preventDefault();
    const res = await AdminEditStudent({
      payload: {
        user: user.SessionId,
        batch_id: batchid,
        student_id: studentid,
        team_id: teamid,
        progress: progress,
        total_marks: marks,
        points: points,
        purchase_id: purchase_id,
      },
    });
    if (res) {
      if (res.status === "success") {
        getinternships();
        seteditmodal(false);
        settoastdata({title:"Successful",message:"Changes saved successfully"})
        settoast(true)
      }
    }
  };

  const UnlockAccess = async ({ data }) => {
    const res = await AdminUnlockAccess({
      payload: {
        user: user.SessionId,
        key: data.key,
        value: data.value,
        purchase_id: purchase_id,
      },
    });
    if (res) {
       // console.log("ACCESS  " + JSON.stringify(res));
      if (res.status === "success") {
        getinternships();
      }
    }
  };

  const [loadingidcard, setloadingidcard] = useState(false);
  const [idcard, setidcard] = useState(null);
  const [selectedname, setselectedname] = useState(profile.first_name || null);
  useEffect(() => {
    GetIdCard({ method: "get" });
  }, [selectedname]);

  const GetIdCard = async ({ method }) => {
    const data = new FormData();
    setloadingidcard(true);
    data.append("username", profile.username);
    data.append("student_id", profile.purchase_id);
    data.append("image", profile.profile_pic);
    data.append("phone", profile.phone_number);
    data.append(
      "joining_date",
      dateFormat(profile.purchase_datetime, "d:mm:yyyy")
    );
    if (method === "save") {
      data.append("save", true);
    }
    data.append("course", profile.course_url);
    data.append("name", selectedname);
    const res = await axios.post(
      "https://skillsuprise.com/id-card-template.php",
      data,
      SecurityHeaders
    );

    if (res) {
      setloadingidcard(false);

      if (res.data.status === "success") {
        if (section === "idcard") {
          setidcard(res.data.idcard);
          let id = document.getElementById("idcard");
          id.innerHTML = res.data.idcard;
          setidcode(res.data);
        }
        if (res.data.msg === "saved") {
          settoasttitle("ID Card Updated !");
          settoastmessage(null);
          settoast(true);
          getinternships();
        }
      } else {
        settoasttitle("Something went wrong !");
        settoastmessage("Please try again... ");
        settoast(true);
      }
    }
  };

  const [xdirection, setxdirection] = useState(0);
  const [idcode, setidcode] = useState(null);
  const AlignName = ({ direction }) => {
    const name = document.getElementById("name");
    const idcard = document.getElementById("idcard");

    if (direction === "left") {
      name.setAttribute("x", xdirection - 2);
      setxdirection(xdirection - 2);
    } else {
      name.setAttribute("x", xdirection + 2);
      setxdirection(xdirection + 2);
    }
    setidcode(idcard.innerHTML);
  };

  const AlignCourse = ({ direction }) => {
    const role = document.getElementById("role");
    const idcard = document.getElementById("idcard");

    if (direction === "left") {
      role.setAttribute("x", xdirection - 5);
      setxdirection(xdirection - 5);
    } else {
      role.setAttribute("x", xdirection + 5);
      setxdirection(xdirection + 5);
    }
    setidcode(idcard.innerHTML);
  };

  const ChangeSection = ({ student_section }) => {
    setsection(student_section);
    sessionStorage.setItem("student_section",JSON.stringify(student_section))
  };
  useEffect(() => {
    if (section === "idcard") {
      GetIdCard({ method: "get" });
    }
  }, [section]);
  const { permission } = useActionsContext();
  const [edit, setedit] = useState(false);
  const [nopermission, setnopermission] = useState(false);
const [addreport,setaddreport] = useState(false)
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
const [toastdata,settoastdata] = useState(null)


const {voiceinput} = useActionsContext()
//voice commands
useEffect(()=>{


  if(voiceinput.includes("id")){
    ChangeSection({student_section:"idcard"})
 
  }
  if(voiceinput.includes("reports")){
    ChangeSection({student_section:"reports"})
 
  }
  if(voiceinput.includes("personal")){
    ChangeSection({student_section:"personal_badges"})
 
  }
  if(voiceinput.includes("coursebadges")){
    ChangeSection({student_section:"course_badges"})
 
  }

  if(voiceinput.includes("doubts")){
    ChangeSection({student_section:"doubts"})
 
  } 
  
  if(voiceinput.includes("assignments")){
    ChangeSection({student_section:"assignments"})
 
  } 
  if(voiceinput.includes("attendence")){
    ChangeSection({student_section:"attendence"})
 
  } 
  if(section==="idcard"){
    if(voiceinput.includes("first")){
      setselectedname(profile.first_name)
    }else if (voiceinput.includes("last")){
      setselectedname(profile.last_name)
    }else if (voiceinput.includes("full")){
      setselectedname(profile.first_name+" "+profile.last_name)
    }
    if(voiceinput.includes("save")){
      GetIdCard({method:"save"})
    }
  }
},[voiceinput])


  return (
    <>
      <NoPermissionModal
        show={nopermission}
        HandleClose={() => setnopermission(false)}
      />

     <DefaultToast show={toast} ToastHandleClose={()=>settoast(false)} toastdata={toastdata}/>
      <DefaultModal
      show={editmodal}
      HandleClose={()=>seteditmodal(false)}
  
        content={
          <>
       <div className="p-3 rounded">

 <div className="modal-header">
 <h2>Edit Student Profile</h2>
 <Close onClick={()=>seteditmodal(false)}/>
 </div>
       <hr></hr>
       <div className=" mt-3">
<form onSubmit={(e)=>EditStudent(e)}>


  

<div className="d-flex">

<div className="col">
<div className="d-flex">
<TextField value={studentid} onChange={(e)=>setstudentid(e.target.value)} fullWidth label="Student ID" />

</div>
</div>
<div className="col">
<TextField value={batchid} onChange={(e)=>setbatchid(e.target.value)}  fullWidth label="Batch ID"  />

</div>
</div>
<div className="d-flex mt-3">

<div className="col">
<select className="form-control" value={teamid} onChange={(e)=>setteamid(e.target.value)}>
      <option value="">Select Team</option>
      <option value={1}>1</option>
      <option value={2}>2</option>
      <option value={3}>3</option>
      <option value={4}>4</option>
      <option value={5}>5</option>
      <option value={6}>6</option>
      <option value={7}>7</option>
      <option value={8}>8</option>
      <option value={9}>9</option>
      <option value={10}>10</option>
     </select>
</div>


<div className="col">
<TextField value={progress} onChange={(e)=>setprogress(e.target.value)}  fullWidth label="Progress" />

</div>

</div>


<div className="d-flex mt-3">

<div className="col">
<TextField  value={marks} onChange={(e)=>setmarks(e.target.value)} fullWidth label="Marks"  />

</div>

<div className="col">
<TextField value={points} onChange={(e)=>setpoints(e.target.value)}  fullWidth label="Points"  />

</div>
</div>



<div className="mt-4">
  <button type="submit" className="btn btn-primary w-100">Save Changes</button>
</div>
</form>
</div>

   
        </div>
          </>
        }
      ></DefaultModal>

<Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {profile.course_url} {batchid}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <AddCourseAssignment
                modalClose={handleClose}
                  courseurl={profile.course_url}
                  batchid={batchid}
                />
              </Modal.Body>
            </Modal>

      {profile ? (
        <>
          <div className="container-fluid">
            <div className="profile-foreground position-relative mx-n4 mt-n4">
              <div className="profile-wid-bg">
                <img
                  src="assets/images/profile-bg.jpg"
                  alt=""
                  className="profile-wid-img"
                />
              </div>
            </div>
            <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper">
              <div className="row g-4">
                <div className="col-auto">
                  <div className="avatar-lg">
                    <img
                      src={profile.profile_pic ? profile.profile_pic : null}
                      alt="user-img"
                      className="img-thumbnail rounded-circle"
                    />
                  </div>
                </div>
                {/*end col*/}
                <div className="col">
                  <div className="p-2">
                    <h3 className="text-white mb-1">
                      {profile.first_name + " " + profile.last_name}
                    </h3>
                    <p className="text-white text-opacity-75 mb-1">
                      {profile.course_url}
                    </p>
                    <div className="hstack text-white-50 gap-1">
                      <div className="me-2">
                        <i className=" me-1 text-white text-opacity-75 fs-16 align-middle" />
                        Batch :{profile.batch_id}
                      </div>

                      <div className="me-2">
                        <i className=" me-1 text-white text-opacity-75 fs-16 align-middle" />
                        Team :{profile.team_id}
                      </div>
                    </div>

                    <div className="hstack text-white-50 gap-1">
                 
                    {profile.id_card?
                    <>
                      <a href={profile.id_card} target="_blank" className="me-2 text-light">
                        <i className=" me-1 text-white text-opacity-75 fs-16 align-middle" />
                        ID card
                      </a>
                    </>:
                    <>
                    </>}
                    </div>
                  </div>
                </div>
                {/*end col*/}
                <div className="col-12 col-lg-auto order-last order-lg-0">
               {/*
              <div className="row text text-white-50 text-center">
                    <div className="col-lg-6 col-4">
                      <div className="p-2">
                        <h4 className="text-white mb-1">24.3K</h4>
                        <p className="fs-14 mb-0">Followers</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-4">
                      <div className="p-2">
                        <h4 className="text-white mb-1">1.3K</h4>
                        <p className="fs-14 mb-0">Following</p>
                      </div>
                    </div>
                  </div>    
              
      */}
                </div>
                {/*end col*/}
              </div>
              {/*end row*/}
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div>
                  <div className="d-flex profile-wrapper">
                    {/* Nav tabs */}
                    <ul
                      className="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                      role="tablist"
                    >
                      <li
                        onClick={() =>
                          ChangeSection({ student_section: "overview" })
                        }
                        className="nav-item"
                      >
                        <a className="nav-link fs-14 active" role="tab">
                          <i className="ri-airplay-fill d-inline-block d-md-none" />{" "}
                          <span className="d-none d-md-inline-block">
                            Overview
                          </span>
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => {
                          parseInt(permission.student_reports)
                            ? ChangeSection({ student_section: "reports" })
                            : setnopermission(true);
                        }}
                      >
                        <a
                          className="nav-link fs-14"
                          data-bs-toggle="tab"
                          href="#activities"
                          role="tab"
                        >
                          <i className="ri-list-unordered d-inline-block d-md-none" />{" "}
                          <span className="d-none d-md-inline-block">
                            Reports
                          </span>
                        </a>
                      </li>
                      <li
                        onClick={() => {
                          parseInt(permission.personal_badges)
                            ? ChangeSection({ student_section: "personal_badges" })
                            : setnopermission(true);
                        }}
                        className="nav-item"
                      >
                        <a className="nav-link fs-14">
                          <i className="ri-price-tag-line d-inline-block d-md-none" />{" "}
                          <span className="d-none d-md-inline-block">
                            Personal Badges
                          </span>
                        </a>
                      </li>
                      <li
                        onClick={() => {
                          parseInt(permission.course_badges)
                            ? ChangeSection({
                                student_section: "course_badges",
                              })
                            : setnopermission(true);
                        }}
                        className="nav-item"
                      >
                        <a className="nav-link fs-14">
                          <i className="ri-price-tag-line d-inline-block d-md-none" />{" "}
                          <span className="d-none d-md-inline-block">
                            Course Badges
                          </span>
                        </a>
                      </li>
                      <li
                        onClick={() => {
                          parseInt(permission.student_doubts)
                            ? ChangeSection({ student_section: "doubts" })
                            : setnopermission(true);
                        }}
                        className="nav-item"
                      >
                        <a className="nav-link fs-14">
                          <i className="ri-price-tag-line d-inline-block d-md-none" />{" "}
                          <span className="d-none d-md-inline-block">
                            Doubts
                          </span>
                        </a>
                      </li>

                      <li
                        onClick={() => {
                          parseInt(permission.student_assignment_submissions)
                            ? ChangeSection({ student_section: "assignments" })
                            : setnopermission(true);
                        }}
                        className="nav-item"
                      >
                        <a className="nav-link fs-14">
                          <i className="ri-price-tag-line d-inline-block d-md-none" />{" "}
                          <span className="d-none d-md-inline-block">
                            Assignments
                          </span>
                        </a>
                      </li>

                      <li
                        onClick={() => {
                          parseInt(permission.student_attendance)
                            ? ChangeSection({ student_section: "attendance" })
                            : setnopermission(true);
                        }}
                        className="nav-item"
                      >
                        <a className="nav-link fs-14">
                          <i className="ri-price-tag-line d-inline-block d-md-none" />{" "}
                          <span className="d-none d-md-inline-block">
                            Attendance
                          </span>
                        </a>
                      </li>
                      <li
                        onClick={() => {
                          parseInt(permission.student_project_submissions)
                            ? ChangeSection({ student_section: "projects" })
                            : setnopermission(true);
                        }}
                        className="nav-item"
                      >
                        <a className="nav-link fs-14">
                          <i className="ri-price-tag-line d-inline-block d-md-none" />{" "}
                          <span className="d-none d-md-inline-block">
                            Projects
                          </span>
                        </a>
                      </li>

                      <li
                        onClick={() => {
                          parseInt(permission.student_idcards)
                            ? ChangeSection({ student_section: "idcard" })
                            : setnopermission(true);
                        }}
                        className="nav-item"
                      >
                        <a className="nav-link fs-14">
                          <i className="ri-price-tag-line d-inline-block d-md-none" />{" "}
                          <span className="d-none d-md-inline-block">
                            ID Card
                          </span>
                        </a>
                      </li>
                    </ul>
                    <div className="flex-shrink-0">
                      {parseInt(permission.edit_student_profile) ? (
                        <>
                          <a
                            onClick={() => seteditmodal(true)}
                            className="btn btn-success"
                          >
                            <i className="ri-edit-box-line align-bottom" /> Edit
                            Profile
                          </a>
                        </>
                      ) : null}
                    </div>
                  </div>
                  {/* Tab panes */}
                  <div className="tab-content pt-4 text-muted">
                    {section === "overview" ? (
                      <>
                        <div
                          className="tab-pane active"
                          id="overview-tab"
                          role="tabpanel"
                        >
                          <div className="row">
                            <div className="col-xxl-3">
                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title mb-5">
                                    Course Progress
                                  </h5>
                                  <div className="progress animated-progress custom-progress progress-label">
                                    <div
                                      className="progress-bar bg-danger"
                                      role="progressbar"
                                      style={{ width: `${profile.progress}%` }}
                                      aria-valuenow={profile.progress}
                                      aria-valuemin={0}
                                      aria-valuemax={100}
                                    >
                                      <div className="label">
                                        {profile.progress}%
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title mb-3">Info</h5>
                                  <div className="table-responsive h-fit">
                                    <table className="table table-borderless mb-0">
                                      <tbody>
                                        <tr>
                                          <th className="ps-0" scope="row">
                                            Name :
                                          </th>
                                          <td className="text-muted">
                                            {profile.first_name +
                                              " " +
                                              profile.last_name}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th className="ps-0" scope="row">
                                            Mobile :
                                          </th>
                                          <td className="text-muted">
                                            {profile.phone_number}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th className="ps-0" scope="row">
                                            E-mail :
                                          </th>
                                          <td className="text-muted">
                                            {profile.email}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th className="ps-0" scope="row">
                                            Address :
                                          </th>
                                          <td className="text-muted">
                                            {profile.address}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th className="ps-0" scope="row">
                                            Joining Date
                                          </th>
                                          <td className="text-muted">
                                            {profile.create_datetime}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                {/* end card body */}
                              </div>
                              {/* end card */}
                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title mb-4">Portfolio</h5>
                                  <div className="d-flex flex-wrap gap-2">
                                    {profile.linkedin ? (
                                      <>
                                        <div>
                                          <a
                                            href={
                                              profile.github
                                                ? profile.github
                                                : null
                                            }
                                            className="avatar-xs d-block"
                                          >
                                            <span className="avatar-title rounded-circle fs-16 bg-body text-body material-shadow">
                                              <i className="ri-linkedinz-fill" />
                                            </span>
                                          </a>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {profile.instagram ? (
                                      <>
                                        <div>
                                          <a
                                            href={
                                              profile.instagram
                                                ? profile.instagram
                                                : null
                                            }
                                            className="avatar-xs d-block"
                                          >
                                            <span className="avatar-title rounded-circle fs-16 bg-body text-body material-shadow">
                                              <i className="ri-instagram-fill" />
                                            </span>
                                          </a>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {profile.twitter ? (
                                      <>
                                        <div>
                                          <a
                                            href={
                                              profile.twitter
                                                ? profile.twitter
                                                : null
                                            }
                                            className="avatar-xs d-block"
                                          >
                                            <span className="avatar-title rounded-circle fs-16 bg-body text-body material-shadow">
                                              <i className="ri-twitter-fill" />
                                            </span>
                                          </a>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {profile.github ? (
                                      <>
                                        <div>
                                          <a
                                            href={
                                              profile.github
                                                ? profile.github
                                                : null
                                            }
                                            className="avatar-xs d-block"
                                          >
                                            <span className="avatar-title rounded-circle fs-16 bg-body text-body material-shadow">
                                              <i className="ri-github-fill" />
                                            </span>
                                          </a>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {profile.linkedin ? (
                                      <>
                                        <div>
                                          <a
                                            href={
                                              profile.github
                                                ? profile.github
                                                : null
                                            }
                                            className="avatar-xs d-block"
                                          >
                                            <span className="avatar-title rounded-circle fs-16 bg-body text-body material-shadow">
                                              <i className="ri-github-fill" />
                                            </span>
                                          </a>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    <div>
                                      <a
                                        href={
                                          profile.github ? profile.github : null
                                        }
                                        className="avatar-xs d-block"
                                      >
                                        <span className="avatar-title rounded-circle fs-16 bg-body text-body material-shadow">
                                          <i className="ri-github-fill" />
                                        </span>
                                      </a>
                                    </div>
                                    <div>
                                      <a
                                        href={
                                          profile.github ? profile.github : null
                                        }
                                        className="avatar-xs d-block"
                                      >
                                        <span className="avatar-title rounded-circle fs-16 bg-primary material-shadow">
                                          <i className="ri-global-fill" />
                                        </span>
                                      </a>
                                    </div>
                                    <div>
                                      <a
                                        href={
                                          profile.github ? profile.github : null
                                        }
                                        className="avatar-xs d-block"
                                      >
                                        <span className="avatar-title rounded-circle fs-16 bg-success material-shadow">
                                          <i className="ri-dribbble-fill" />
                                        </span>
                                      </a>
                                    </div>
                                    <div>
                                      <a
                                        href={
                                          profile.github ? profile.github : null
                                        }
                                        className="avatar-xs d-block"
                                      >
                                        <span className="avatar-title rounded-circle fs-16 bg-danger material-shadow">
                                          <i className="ri-pinterest-fill" />
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                {/* end card body */}
                              </div>
                              {/* end card */}
                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title mb-4">Skills</h5>
                                  <div className="d-flex flex-wrap gap-2 fs-15">
                                    <a
                                      href="javascript:void(0);"
                                      className="badge bg-primary-subtle text-primary"
                                    >
                                      Photoshop
                                    </a>
                                    <a
                                      href="javascript:void(0);"
                                      className="badge bg-primary-subtle text-primary"
                                    >
                                      illustrator
                                    </a>
                                    <a
                                      href="javascript:void(0);"
                                      className="badge bg-primary-subtle text-primary"
                                    >
                                      HTML
                                    </a>
                                    <a
                                      href="javascript:void(0);"
                                      className="badge bg-primary-subtle text-primary"
                                    >
                                      CSS
                                    </a>
                                    <a
                                      href="javascript:void(0);"
                                      className="badge bg-primary-subtle text-primary"
                                    >
                                      Javascript
                                    </a>
                                    <a
                                      href="javascript:void(0);"
                                      className="badge bg-primary-subtle text-primary"
                                    >
                                      Php
                                    </a>
                                    <a
                                      href="javascript:void(0);"
                                      className="badge bg-primary-subtle text-primary"
                                    >
                                      Python
                                    </a>
                                  </div>
                                </div>
                                {/* end card body */}
                              </div>
                              {/* end card */}
                              <div className="card">
                                <div className="card-body">
                                  <div className="d-flex align-items-center mb-4">
                                    <div className="flex-grow-1">
                                      <h5 className="card-title mb-0">
                                        Suggestions
                                      </h5>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <div className="dropdown">
                                        <a
                                          href="#"
                                          role="button"
                                          id="dropdownMenuLink2"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i className="ri-more-2-fill fs-14" />
                                        </a>
                                        <ul
                                          className="dropdown-menu dropdown-menu-end"
                                          aria-labelledby="dropdownMenuLink2"
                                        >
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                            >
                                              View
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                            >
                                              Edit
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                            >
                                              Delete
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="d-flex align-items-center py-3">
                                      <div className="avatar-xs flex-shrink-0 me-3">
                                        <img
                                          src="assets/images/users/avatar-3.jpg"
                                          alt=""
                                          className="img-fluid rounded-circle material-shadow"
                                        />
                                      </div>
                                      <div className="flex-grow-1">
                                        <div>
                                          <h5 className="fs-14 mb-1">
                                            Esther James
                                          </h5>
                                          <p className="fs-13 text-muted mb-0">
                                            Frontend Developer
                                          </p>
                                        </div>
                                      </div>
                                      <div className="flex-shrink-0 ms-2">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-outline-success material-shadow-none"
                                        >
                                          <i className="ri-user-add-line align-middle" />
                                        </button>
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center py-3">
                                      <div className="avatar-xs flex-shrink-0 me-3">
                                        <img
                                          src="assets/images/users/avatar-4.jpg"
                                          alt=""
                                          className="img-fluid rounded-circle material-shadow"
                                        />
                                      </div>
                                      <div className="flex-grow-1">
                                        <div>
                                          <h5 className="fs-14 mb-1">
                                            Jacqueline Steve
                                          </h5>
                                          <p className="fs-13 text-muted mb-0">
                                            UI/UX Designer
                                          </p>
                                        </div>
                                      </div>
                                      <div className="flex-shrink-0 ms-2">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-outline-success material-shadow-none"
                                        >
                                          <i className="ri-user-add-line align-middle" />
                                        </button>
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center py-3">
                                      <div className="avatar-xs flex-shrink-0 me-3">
                                        <img
                                          src="assets/images/users/avatar-5.jpg"
                                          alt=""
                                          className="img-fluid rounded-circle material-shadow"
                                        />
                                      </div>
                                      <div className="flex-grow-1">
                                        <div>
                                          <h5 className="fs-14 mb-1">
                                            George Whalen
                                          </h5>
                                          <p className="fs-13 text-muted mb-0">
                                            Backend Developer
                                          </p>
                                        </div>
                                      </div>
                                      <div className="flex-shrink-0 ms-2">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-outline-success material-shadow-none"
                                        >
                                          <i className="ri-user-add-line align-middle" />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* end card body */}
                              </div>
                              {/*end card*/}
                              <div className="card">
                                <div className="card-body">
                                  <div className="d-flex align-items-center mb-4">
                                    <div className="flex-grow-1">
                                      <h5 className="card-title mb-0">
                                        Popular Posts
                                      </h5>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <div className="dropdown">
                                        <a
                                          href="#"
                                          role="button"
                                          id="dropdownMenuLink1"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i className="ri-more-2-fill fs-14" />
                                        </a>
                                        <ul
                                          className="dropdown-menu dropdown-menu-end"
                                          aria-labelledby="dropdownMenuLink1"
                                        >
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                            >
                                              View
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                            >
                                              Edit
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                            >
                                              Delete
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex mb-4">
                                    <div className="flex-shrink-0">
                                      <img
                                        src="assets/images/small/img-4.jpg"
                                        alt=""
                                        height={50}
                                        className="rounded material-shadow"
                                      />
                                    </div>
                                    <div className="flex-grow-1 ms-3 overflow-hidden">
                                      <a href="javascript:void(0);">
                                        <h6 className="text-truncate fs-14">
                                          Design your apps in your own way
                                        </h6>
                                      </a>
                                      <p className="text-muted mb-0">
                                        15 Dec 2021
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex mb-4">
                                    <div className="flex-shrink-0">
                                      <img
                                        src="assets/images/small/img-5.jpg"
                                        alt=""
                                        height={50}
                                        className="rounded material-shadow"
                                      />
                                    </div>
                                    <div className="flex-grow-1 ms-3 overflow-hidden">
                                      <a href="javascript:void(0);">
                                        <h6 className="text-truncate fs-14">
                                          Smartest Applications for Business
                                        </h6>
                                      </a>
                                      <p className="text-muted mb-0">
                                        28 Nov 2021
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex">
                                    <div className="flex-shrink-0">
                                      <img
                                        src="assets/images/small/img-6.jpg"
                                        alt=""
                                        height={50}
                                        className="rounded material-shadow"
                                      />
                                    </div>
                                    <div className="flex-grow-1 ms-3 overflow-hidden">
                                      <a href="javascript:void(0);">
                                        <h6 className="text-truncate fs-14">
                                          How to get creative in your work
                                        </h6>
                                      </a>
                                      <p className="text-muted mb-0">
                                        21 Nov 2021
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {/*end card-body*/}
                              </div>
                              {/*end card*/}
                            </div>
                            {/*end col*/}
                            <div className="col-xxl-9">
                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title mb-3">About</h5>
                                  <p>
                                    {profile.about
                                      ? profile.about
                                      : "No about info added"}
                                  </p>

                                  <div className="row">
                                    <div className="col-6 col-md-4">
                                      <div className="d-flex mt-4">
                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                          <div className="avatar-title bg-light rounded-circle fs-16 text-primary material-shadow">
                                            <i className="ri-user-2-fill" />
                                          </div>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                          <p className="mb-1">
                                            Current Position :
                                          </p>
                                          <h6 className="text-truncate mb-0">
                                            Lead Designer / Developer
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                    {/*end col*/}
                                    <div className="col-6 col-md-4">
                                      <div className="d-flex mt-4">
                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                          <div className="avatar-title bg-light rounded-circle fs-16 text-primary material-shadow">
                                            <i className="ri-global-line" />
                                          </div>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                          <p className="mb-1">Website :</p>
                                          <a href="#" className="fw-semibold">
                                            www.velzon.com
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    {/*end col*/}
                                  </div>
                                  {/*end row*/}
                                </div>
                                {/*end card-body*/}
                              </div>

                              {/* end card */}
                            </div>
                            {/*end col*/}
                          </div>
                          {/*end row*/}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div className="card p-3">
                      {/*main reports*/}

                      {section === "reports" ? (
                        <>
                          <StudentReports profile={profile} Reload={Reload}/>
                        </>
                      ) : (
                        <></>
                      )}

                      {section === "assignments" ? (
                        <>
                          <div className="text-left ">
                            <div className="card-body border border-dashed border-end-0 border-start-0">
                              <form>
                                <div className="row g-3">
                                  <div className="col-xxl-3 col-sm-4">
                                    <div className="search-box">
                                      <input
                                        type="text"
                                        className="form-control search"
                                        placeholder="Search for order ID, customer, order status or something..."
                                      />
                                      <i className="ri-search-line search-icon" />
                                    </div>
                                  </div>
                                  {/*end col*/}
                                  <div className="col-xxl-2 col-sm-6">
                                    <div>
                                      <input
                                        type="text"
                                        className="form-control"
                                        data-provider="flatpickr"
                                        data-date-format="d M, Y"
                                        data-range-date="true"
                                        id="demo-datepicker"
                                        placeholder="Select date"
                                      />
                                    </div>
                                  </div>
                                  {/*end col*/}
                                  <div className="col-xxl-2 col-sm-4">
                                    <div>
                                      <select
                                        className="form-control"
                                        data-choices=""
                                        data-choices-search-false=""
                                        name="choices-single-default"
                                        id="idStatus"
                                      >
                                        <option value="">Status</option>
                                        <option value="all" selected="">
                                          All
                                        </option>
                                        <option value="Pending">Pending</option>
                                        <option value="Inprogress">
                                          Inprogress
                                        </option>
                                        <option value="Cancelled">
                                          Cancelled
                                        </option>
                                        <option value="Pickups">Pickups</option>
                                        <option value="Returns">Returns</option>
                                        <option value="Delivered">
                                          Delivered
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  {/*end col*/}
                                  <div className="col-xxl-2 col-sm-4">
                                    <div>
                                      <select
                                        className="form-control"
                                        data-choices=""
                                        data-choices-search-false=""
                                        name="choices-single-default"
                                        id="idPayment"
                                      >
                                        <option value="">Select Payment</option>
                                        <option value="all" selected="">
                                          All
                                        </option>
                                        <option value="Mastercard">
                                          Mastercard
                                        </option>
                                        <option value="Paypal">Paypal</option>
                                        <option value="Visa">Visa</option>
                                        <option value="COD">COD</option>
                                      </select>
                                    </div>
                                  </div>
                                  {/*end col*/}
                                  <div className="col-xxl-3 col-sm-4">
                                    <div className="d-flex">
                                      <button
                                        type="button"
                                        className="btn me-2 btn-primary "
                                        onclick="SearchData();"
                                      >
                                        {" "}
                                        <i className="ri-equalizer-fill me-1 align-bottom" />
                                        Filters
                                      </button>

                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setShow(true);
                                        }}
                                        className="btn btn-danger w-fit h-fit"
                                      >
                                        Add new Task
                                      </button>
                                    </div>
                                  </div>
                                  {/*end col*/}
                                </div>
                                {/*end row*/}
                              </form>
                            </div>

                            <StudentAssignmentsSubmissions
                              tasks={submissions}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {section === "projects" ? (
                        <>
                          <ProjectSubmissionsList projects={projects} />
                        </>
                      ) : (
                        <></>
                      )}

                      {section === "personal_badges" ? (
                        <>
                          <h4>Personal Badges</h4>

                          {personalbadges ? (
                            <>
                              <PersonalBadgesList badges={personalbadges} />
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      {section === "course_badges" ? (
                        <>
                          <h4>Course Badges</h4>

                          {coursebadges ? (
                            <>
                              <CourseBadgesList  badges={coursebadges} />
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      {section === "doubts" ? (
                        <>
                          <h4>Student Doubts</h4>

                          <StudentsDoubtSupportList doubts={doubts} />
                        </>
                      ) : (
                        <></>
                      )}
                      {section === "attendance" ? (
                        <>
                          <StudentAttendance student_id={profile.purchase_id} />
                        </>
                      ) : (
                        <></>
                      )}

                      {section === "idcard" ? (
                        <>
                          <StudentIDGenerator profile={profile} Reload={Reload}/>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>

                    {/*end tab-pane*/}
                  </div>
                  {/*end tab-content*/}
                </div>
              </div>
              {/*end col*/}
            </div>
            {/*end row*/}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const studentDetails =(prop)=>({
  value:prop.studentHandler
})

export default connect(studentDetails) (StudentProfile);
