import React, { useEffect, useState } from 'react'
import ENDPOINT from '../../context/EndPoint';
import PostItem from './PostItem';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';

const AllPosts = () => {

    const [posts,setposts] = useState(null)
const {user} = useContext(AuthContext)
    const  GetPosts = async ()=>{

        fetch(`${ENDPOINT}admin-get-posts.php?session=${user.SessionId}`)
        .then(r=>r.json())
        .then(result=>{
       
              if(result){
          
                if(result.status==="success"){
                     setposts(result.posts)
                }
             
               
              }
        
                    
        
            
        })
        
        }    ;

        useEffect(()=>{

GetPosts()
        },[])


        
const [initialData,setInitialData] = useState(posts)


const [response, setResponse] = useState(initialData);
const [searchTerm, setSearchTerm] = useState('');

const handleSearch = (e) => {
const term = e.target.value.toLowerCase();

const filteredData = initialData.filter((item) => {
  return (


    item.first_name.toLowerCase().includes(term) ||
    item.last_name.toLowerCase().includes(term) 
  );
});

setResponse(filteredData);
setSearchTerm(term);
};
  

useEffect(()=>{
setResponse(posts)
setInitialData(posts)
},[posts])

  return (
 <>
 

 <div className="container-fluid">

 <div className="d-flex align-items-center mb-4">
                          <h5 className="card-title flex-grow-1 mb-0">
                           All Posts
                          </h5>
                          <div className="card-body border border-dashed border-end-0 border-start-0">
                  <form>
                    <div className="row g-3">
                      <div className="col-xxl-5 col-sm-6">
                        <div className="search-box">
                          <input
                      onChange={handleSearch}
                            type="text"
                            className="form-control search"
                            placeholder="Search for class title, description, course..."
                          />
                          <i className="ri-search-line search-icon" />
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-2 col-sm-6">
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            data-provider="flatpickr"
                            data-date-format="d M, Y"
                            data-range-date="true"
                            id="demo-datepicker"
                            placeholder="Select date"
                          />
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-2 col-sm-4">
                        <div>
                          <select
                            className="form-control"
                            data-choices=""
                            data-choices-search-false=""
                            name="choices-single-default"
                            id="idStatus"
                          >
                            <option value="">Status</option>
                            <option value="all" selected="">
                              All
                            </option>
                            <option value="Pending">Pending</option>
                            <option value="Inprogress">Inprogress</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Pickups">Pickups</option>
                            <option value="Returns">Returns</option>
                            <option value="Delivered">Delivered</option>
                          </select>
                        </div>
                      </div>
                   
                      <div className="col-xxl-1 col-sm-4">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary h-fit"
                           
                          >
                            
                            <i className="ri-equalizer-fill me-1 align-bottom" />
                            Filters
                          </button>
                        </div>
                      </div>
                      {/*end col*/}
                    </div>
                    {/*end row*/}
                  </form>
                </div>
                        </div>
 </div>

 <div className="d-flex flex-wrap">

 {response?
<>
{response.map((post)=>(
     <>
     <div className="col-lg-3 p-2">
     <PostItem post={post}/>
     </div>

     </>
))}
</>:null}
 </div>

 
 </>
  )
}

export default AllPosts