import styled from "styled-components";
import PieChart from "../../components/charts/PieChart";
import Users from "../users/Users";
import DemoData from "../../data/DemoData";
import StudentTicketItem from "./StudentTicketItem";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import ENDPOINT from "../../context/EndPoint";
import SecurityHeaders from "../../context/SecurityHeaders";
import GetStatistics from "../../actions/statistics/GetStatistics";

import AdminDeleteTickets from "../../actions/student/AdminDeleteTickets";
import AdminUpdateDoubtRequest from "../../actions/support/AdminUpdateDoubtRequest";
import {
  StudentDoubtSupportProvider,
  useContactRequests,
} from "../../context/support/StudentDoubtSupportContext";
function StudentsSupportPage({options}) {
  const { user } = useContext(AuthContext);
  const userdata = user;
  const params = useParams();
  const [status, setstatus] = useState("all");

  const [query, setquery] = useState(null);

  const [resolutions, setresolutions] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const {
  //   doubts,
  //   GetDoubts,
  //   deleteResponse,
  //   DeleteSupportRequest,
  //   updateResponse,
  //   UpdateDoubtRequest,
  // } = useContactRequests(StudentDoubtSupportProvider);

  useEffect(() => {
    GetDoubts({ payload: { id: null } });
    //  // console.log("doubts " + doubts);
  }, []);

  const [toast, settoast] = useState(false);
  const [toasttitle, settoasttitle] = useState(null);
  const [info, setinfo] = useState(null);
  const [toastmessage, settoastmessage] = useState(null);
  const GetStats = async () => {
    const res = await GetStatistics({ payload: { SessionId: user.SessionId } });

    if (res) {
      if (res.status === "success") {
        setinfo(res.data);
      }
      //  // console.log("stat is" + JSON.stringify(res));
    }
  };

  const [requestDelete, setRequestDelete] = useState("");

  useEffect(() => {
    GetStats();
  }, []);


  const [doubts, setdoubts] = useState(null)



  const GetDoubts = async () => {
    const data = new FormData();
    data.append("session", user.SessionId);
if(options?.limit){
  data.append("limit",options?.limit)
}

    const res = await axios.post(
      ENDPOINT + "admin-get-doubt-requests.php",
      data,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    if (res) {
      //  // console.log("Resp  is " + JSON.stringify(res.data.data))
      if (res.data.status === "success") {
        setdoubts(res.data.data)

      }
    }
  };

  // Initialize the response state with the provided data

  const [response, setResponse] = useState(doubts);
  const [searchTerm, setSearchTerm] = useState("");

  // Function to handle changes in the search input
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    // Filter the data based on search term
    const filteredData = doubts.filter((item) => {
      return (
        item.email.toLowerCase().includes(term) ||
        item.phone_number.includes(term) ||
        item.first_name.toLowerCase().includes(term) ||
        item.last_name.toLowerCase().includes(term) ||
        item.create_datetime.includes(term) ||
        item.status.toLowerCase().includes(term)
      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };

  const Reload = () => {
    GetDoubts();
  };
  const [statistics, setstatistics] = useState(null);
  const Statistics = async () => {
    const data = new FormData();
    data.append("session", user.SessionId);

    const res = await axios.post(
      ENDPOINT + "doubts-statistics.php",
      data,
      SecurityHeaders
    );

    if (res) {
      if (res.data.status === "success") {
        setstatistics(res.data.data);
      }
    }
  };

  useEffect(() => {
    Statistics();
  }, []);

  useEffect(() => {
    setResponse(doubts);
  }, [doubts]);


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="h-100">
              {statistics &&options?.statistics? (
                <>
                  {/*end row*/}
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                <i className="ri-arrow-down-circle-fill align-middle" />
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                Initiated{" "}
                              </p>
                              <h4 className=" mb-0">
                                <span
                                  className="counter-value"
                                  data-target="14799.44"
                                >
                                  {statistics.total_initiated}
                                </span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0 align-self-end">
                              <span className="badge bg-danger-subtle text-danger">
                                <i className="ri-arrow-down-s-fill align-middle me-1" />
                                {Math.round(statistics.percentage_initiated)} %
                                <span> </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}

                    {/* end col */}
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                <i className="ri-arrow-down-circle-fill align-middle" />
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                Closed{" "}
                              </p>
                              <h4 className=" mb-0">
                                <span
                                  className="counter-value"
                                  data-target="14799.44"
                                >
                                  {Math.round(statistics.total_closed)}
                                </span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0 align-self-end">
                              <span className="badge bg-danger-subtle text-danger">
                                <i className="ri-arrow-down-s-fill align-middle me-1" />
                                {statistics.percentage_closed} %<span> </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}

                    {/* end col */}
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                <i className="ri-arrow-down-circle-fill align-middle" />
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                Total{" "}
                              </p>
                              <h4 className=" mb-0">
                                <span
                                  className="counter-value"
                                  data-target="14799.44"
                                >
                                  {statistics.total_doubts}
                                </span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0 align-self-end">
                              <span className="badge bg-danger-subtle text-danger">
                                <i className="ri-arrow-down-s-fill align-middle me-1" />
                                <span> </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}

                    {/* end col */}
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                <i className="ri-arrow-down-circle-fill align-middle" />
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                Avg Resolution Time{" "}
                              </p>
                              <h4 className=" mb-0">
                                <span
                                  className="counter-value"
                                  data-target="14799.44"
                                >
                                  {parseInt(
                                    statistics.average_closed_time_minutes
                                  ) > 60
                                    ? parseInt(
                                      statistics.average_closed_time_minutes
                                    ) /
                                    60 +
                                    " hrs"
                                    : statistics.average_closed_time_minutes +
                                    " mins"}
                                </span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0 align-self-end">
                              <span className="badge bg-danger-subtle text-danger">
                                <i className="ri-arrow-down-s-fill align-middle me-1" />
                                %<span> </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}
                  </div>{" "}
                  {/* end row*/}
                </>
              ) : (
                <></>
              )}

              <div className="row">
                {/* end col sliding section*/}
                <div className="col-12  order-xxl-0 order-first">
                  <div className="col-12 ">
                  
                    <div className="card ">
                      <div className="col-12 align-items-center p-3 d-flex justify-content-between">
                      <h5 className="m-0">Student Doubts</h5>
                      {options?.viewAll === false?<></>:
                      <Link className="m-0" to="/students-support">View All</Link>
                    }
                      </div>
                  {options?.search===false?null:    <div className="card-body border border-dashed border-end-0 border-start-0">
                        <form>
                          <div className="row g-3">
                            <div className="col-xxl-3 col-sm-4">
                              <div className="search-box">
                                <input
                                  onChange={handleSearch}

                                  type="text"
                                  className="form-control search"
                                  placeholder="Search for order ID, customer, order status or something..."
                                />
                                <i className="ri-search-line search-icon" />
                              </div>
                            </div>
                            {/*end col*/}
                            <div className="col-xxl-2 col-sm-6">
                              <div>
                                <input
                                  type="date"
                                  onChange={handleSearch}

                                  className="form-control"
                                  data-provider="flatpickr"
                                  data-date-format="d M, Y"
                                  data-range-date="true"
                                  id="demo-datepicker"
                                  placeholder="Select date"
                                />
                              </div>
                            </div>
                            {/*end col*/}
                            <div className="col-xxl-2 col-sm-4">
                              <div>
                                <select
                                  className="form-control"
                                  onChange={handleSearch}

                                  data-choices=""
                                  data-choices-search-false=""
                                  name="choices-single-default"
                                  id="idStatus"
                                >
                                  <option value="">Status</option>

                                  <option value="Pending">Pending</option>
                                  <option value="unresolved">Unresolved</option>
                                  <option value="closed">Closed</option>
                                  <option value="initiated">Initiated</option>
                                  <option value="resolved">Resolved</option>

                                </select>
                              </div>
                            </div>
                            {/*end col*/}
                            
                            {/*end col*/}

                            {/*end col*/}
                          </div>
                          {/*end row*/}
                        </form>
                      </div>
}
                      {/* end card header */}
                      <div className="card-body">
                        <div className="table-responsive table-card">
                          <table className="table table-borderless table-centered align-middle mb-0">
                            <thead className="text-muted table-light">
                              <tr>
                             {options?.userinfo===false?null:   <th scope="cqol">User</th>}
                             {options?.course===false?null:   <th scope="cqol">course</th>
                             }
                                <th scope="col">Date</th>
                                <th scope="col">Status</th>
                                <th scope="col">Actions</th>

                            {options?.view===false?null:    <th scope="col">View</th>}
                              </tr>
                            </thead>
                            <tbody>
                              {response ? (
                                <>
                                  {response.map((item) => (
                                    <>
                                      <StudentTicketItem
                                        Reload={Reload}
                                        item={item}
                                        options={options}
                                       
                                      />
                                    </>
                                  ))}
                                </>
                              ) : (
                                <></>
                              )}
                            </tbody>
                            {/* end tbody */}
                          </table>
                          {/* end table */}
                        </div>
                      </div>
                    </div>{" "}
                  </div>

                  <div className="d-flex mt-4">
                    {/*Graphical data */}

                    <div className="col-lg-5 ps-3">
                      <div className="card"></div>
                    </div>

                    <div className="col-lg-5 ps-2"></div>
                  </div>
                </div>
                {/* end col */}
              </div>

              <div className="row">
                <div className="col-xl-8">{/* end card */}</div>
                {/* end col */}
                <div className="col-xl-4">
                  {/* card */}
                  <div className="card card-height-100">
                    {/* end card body */}
                  </div>
                  {/* end card */}
                </div>
                {/* end col */}
              </div>
            </div>{" "}
            {/* end .h-100*/}
          </div>{" "}
          {/* end col */}
          {/* end col */}
        </div>
      </div>
    </>
  );
}

export default StudentsSupportPage;

const StyledSuperAdminDashboard = styled.div`
  h1 {
    color: red;
  }
`;
