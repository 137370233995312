import React, { useEffect, useState } from 'react'
import LeadsSection from './LeadsSection'
import { useActionsContext } from '../../context/ActionsContext'
import LeadItem from './LeadItem'
import DefaultModal from '../../components/modals/DefaultModal'
import { Close } from '@material-ui/icons'
import AddLead from './AddLead'

const LeadsPage = () => {


const {leads,fetchLeads,permission} = useActionsContext()

const {username,Addition} = useActionsContext()
const {internalteamlist,fetchInternalTeamList} = useActionsContext()
const [addlead,setaddlead] = useState(false)
useEffect(()=>{

  fetchInternalTeamList({payload:{}})

 // console.log(internalteamlist)
},[])
useEffect(()=>{
fetchLeads()
Addition()
},[])



  return (

  <>

  <DefaultModal show={addlead} HandleClose={()=>setaddlead(false)} content={<>
  
  <div className="p-2">
<div className="modal-header">
<h4>Add Lead</h4>


<Close onClick={()=>setaddlead(false)}/>
</div>
<hr></hr>

<AddLead/>
  </div>
  </>}></DefaultModal>
  
  <div className="container-fluid">
       
          <div className="card">
            <div className="card-body">
              <div className="row g-3">
                <div className="col-md-3">
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-control search"
                      placeholder="Search for deals..."
                    />
                    <i className="ri-search-line search-icon" />
                  </div>
                </div>
                {/*end col*/}
                <div className="col-md-auto ms-auto">
                  <div className="d-flex hastck gap-2 flex-wrap">
                    <div className="d-flex align-items-center gap-2">
                      <span className="text-muted">Sort by: </span>
                      <select
                        className="form-control mb-0"
                        data-choices=""
                        data-choices-search-false=""
                        id="choices-single-default"
                      >
                        <option value="Owner">Owner</option>
                        <option value="Company">Company</option>
                        <option value="Date">Date</option>
                      </select>
                    </div>
                    <button
                    onClick={()=>setaddlead(true)}
                      className="btn btn-success"
                    >
                      <i className="ri-add-fill align-bottom me-1" /> Add Lead
                    </button>
                    <div className="dropdown">
                      <button
                        className="btn btn-soft-info btn-icon fs-14"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="ri-settings-4-line" />
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a className="dropdown-item" href="#">
                            Copy
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Move to pipline
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Add to exceptions
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Switch to common form view
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Reset form view to default
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/*end col*/}
              </div>
              {/*end row*/}
            </div>
          </div>
          {/*end card*/}
        {leads&&leads.data?
        <>
        
        <div className="row row-cols-xxl-5 row-cols-lg-3 row-cols-md-2 row-cols-1">
            
          {parseInt(permission.view_scheduled_leads)?
          <>
            <div className="col">
                <div className="card">
                  <a
                    className="card-body bg-danger-subtle"
                    data-bs-toggle="collapse"
                    href="#leadDiscovered"
                    role="button"
                    aria-expanded="false"
                    aria-controls="leadDiscovered"
                  >
                    <h5 className="card-title text-uppercase fw-semibold mb-1 fs-15">
                      Demo Scheduled
                    </h5>
                    <p className="text-muted mb-0">
                      $265,200 <span className="fw-medium">4 Deals</span>
                    </p>
                  </a>
                </div>
                {/*end card*/}
                <div className="collapse show" id="leadDiscovered">
  
  
  
  {leads.data.demo_scheduled.map((item,index)=>(
      <>
      
      
  <LeadItem index={index} item={item}/>
      </>
  ))}
  







 {/*end card*/}
                </div>
              </div>
          
          </>:<>
          
          </>}

              {/*end col*/}
             


              {parseInt(permission.view_interested_leads)?
              <>
              <div className="col">
                <div className="card">
                  <a
                    className="card-body bg-success-subtle"
                    data-bs-toggle="collapse"
                    href="#contactInitiated"
                    role="button"
                    aria-expanded="false"
                    aria-controls="contactInitiated"
                  >
                    <h5 className="card-title text-uppercase fw-semibold mb-1 fs-15">
                      Interested
                    </h5>
                    <p className="text-muted mb-0">
                      $108,700 <span className="fw-medium">5 Deals</span>
                    </p>
                  </a>
                </div>
                {/*end card*/}
                <div className="collapse show" id="contactInitiated">
               
                {leads.data.interested.map((item,index)=>(
      <>
      
      
  <LeadItem index={index} item={item}/>
      </>
  ))}
  
 
  
  
                </div>
              </div>
              </>:<></>}
            

              {/*end col*/}
            {parseInt(permission.view_not_interested_leads)?
            <>
              <div className="col">
                <div className="card">
                  <a
                    className="card-body bg-warning-subtle"
                    data-bs-toggle="collapse"
                    href="#needsIdentified"
                    role="button"
                    aria-expanded="false"
                    aria-controls="needsIdentified"
                  >
                    <h5 className="card-title text-uppercase fw-semibold mb-1 fs-15">
                      Not Interested
                    </h5>
                    <p className="text-muted mb-0">
                      $708,200 <span className="fw-medium">7 Deals</span>
                    </p>
                  </a>
                </div>
                {/*end card*/}
                <div className="collapse show" id="needsIdentified">
               
        
  {leads.data.not_interested.map((item,index)=>(
      <>
      
      
  <LeadItem index={index} item={item}/>
      </>
  ))}
  
 
  
                 
                </div>
              </div>
            
            </>:
            <></>
            }

              {/*end col*/}
            {parseInt(permission.view_joined_leads)?
            <>
              <div className="col">
                <div className="card">
                  <a
                    className="card-body bg-info-subtle"
                    data-bs-toggle="collapse"
                    href="#meetingArranged"
                    role="button"
                    aria-expanded="false"
                    aria-controls="meetingArranged"
                  >
                    <h5 className="card-title text-uppercase fw-semibold mb-1 fs-15">
                      Joined
                    </h5>
                    <p className="text-muted mb-0">
                      $44,900 <span className="fw-medium">3 Deals</span>
                    </p>
                  </a>
                </div>
                {/*end card*/}
                <div className="collapse show" id="meetingArranged">
                  
  {leads.data.joined.map((item,index)=>(
      <>
      
      
  <LeadItem index={index} item={item}/>
      </>
  ))}
  
 
  
                </div>
              </div>
            
            </>:
            <></>
            
            }
              {/*end col*/}
          {parseInt(permission.view_pending_payment_leads)?
          <>
              <div className="col">
                <div className="card">
                  <a
                    className="card-body bg-secondary-subtle"
                    data-bs-toggle="collapse"
                    href="#offerAccepted"
                    role="button"
                    aria-expanded="false"
                    aria-controls="offerAccepted"
                  >
                    <h5 className="card-title text-uppercase fw-semibold mb-1 fs-15">
                      Pending Payment
                    </h5>
                    <p className="text-muted mb-0">
                      $819,300 <span className="fw-medium">8 Deals</span>
                    </p>
                  </a>
                </div>
                {/*end card*/}
                <div className="collapse show" id="offerAccepted">
                 
  {leads.data.pending_payment.map((item,index)=>(
      <>
      
      
  <LeadItem index={index} item={item}/>
      </>
  ))}
  
 
  
                 
                </div>
              </div>
          
          </>:
          <></>
        
        }
              {/*end col*/}
            </div>
        
        </>:
        <>
        
        
        </>}
          {/*end row*/}
         
        </div>

  </>)
}

export default LeadsPage