
import CloseIcon from '@mui/icons-material/Close';
import React, { useContext } from 'react'

import { useParams } from 'react-router'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { Toast } from 'react-bootstrap'
import { Avatar } from '@material-ui/core';
import { Close, MoreVert } from '@material-ui/icons'

import GetTeamList from '../../actions/team/GetTeamList';
import { AuthContext } from '../../context/AuthContext';
import ENDPOINT from '../../context/EndPoint';
import { connect } from 'react-redux';

const AddTeamTask = ({ item, HandleClose, value, dispatch }) => {


  const { user } = useContext(AuthContext)
  const params = useParams();
  //  //  // console.log("internship id"+internship_id);



  const [tasks, settasks] = useState(null)
  const [intern_id, setintern_id] = useState(item.user_id)
  const [internship_id, setinternship_id] = useState(item.internship_id)
  const [status, setstatus] = useState("all");
  const [description, setdescription] = useState(null)
  const [startdate, setstartdate] = useState(null)
  const [enddate, setenddate] = useState(null)
  const [begins, setbegins] = useState(null);
  const [ends, setends] = useState(null)
  const [meetlink, setmeetlink] = useState(null)
  const [reference, setreference] = useState(null)
  const [meetingtype, setmeetingtype] = useState("General Meeting")
  const [currentinternship, setcurrentinternship] = useState(internship_id)

  const scheduleMeeting = async () => {


    const formData = new FormData();

    //  // console.log(" submit" + currentinternship+" "+startdate+" "+begins+" "+meetingtype)
    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append('session', user.SessionId);


    formData.append('internship_id', internship_id)
    if (intern_id) {
      formData.append('intern_id', intern_id)
    }
    formData.append('date', startdate)

    formData.append('ends', ends)
    formData.append("title", title)
    formData.append('reference', reference)
    formData.append('description', description)

    const res = await axios.post(ENDPOINT + "admin-add-task.php", formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
    if (res) {

      if (res.data.status === "success") {
        settoasttitle("Task added")
        HandleClose()
         // console.log(res.data.status);
        dispatch({ type:'FUNCTION_RELOAD', payload:'get-intern-tasks' });


        settoast(true)
      }
      else {
        settoasttitle("Something went wrong")
        settoast(true)
      }
    }
  }







  const [submit, setsubmit] = useState(false)
  const [taskid, settaskid] = useState(null)

  const [toast, settoast] = useState(false)


  const [toastmessage, settoastmessage] = useState(null)
  const [toasttitle, settoasttitle] = useState(null)

  const [title, settitle] = useState(null)

  return (
    <>
      <>


        <div className="position-fixed container-fluid left-0 bottom-60 justify-center">
          <Toast className="bg-warning" show={toast} onClose={() => { settoast(false) }} autohide={true} delay={5000}>
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">{toasttitle}</strong>

            </Toast.Header>
            <Toast.Body>{toastmessage}</Toast.Body>
          </Toast>
        </div>




        <div className=" mx-auto  col-12">

          {item ?
            <>


              <div className="d-flex justify-content-between">
                <div className="d-flex">

                  <Avatar className="me-3" src={item.profile_pic ? item.profile_pic : null} />
                  <div>
                    <h6 className="mb-0">{item.first_name + " " + item.last_name}</h6>
                    <p className="mb-0">{item.role}</p>
                  </div>
                </div>

              </div>


              <hr className="mt-4" />
            </> :
            <>

            </>}


          <input onChange={(e) => settitle(e.target.value)} className="form-control mt-3" placeholder="Title"></input>


          <input onChange={(e) => setdescription(e.target.value)} className="form-control mt-3" placeholder="description"></input>


          <input onChange={(e) => setreference(e.target.value)} className="form-control mt-3" placeholder="reference"></input>

          <div className="mt-4">
            <h6>Deadline</h6>

            <div className="d-flex">

              <input onChange={(e) => setstartdate(e.target.value)} className="form-control" type="date"></input>

              <input onChange={(e) => setends(e.target.value)} className="form-control" type="time"></input>

            </div>
          </div>
          <div className="flex mt-2 space-between w-100">





          </div>

          <button onClick={() => scheduleMeeting()} className="btn btn-primary mt-5 w-100">Add Task</button>
          <div>

          </div>



        </div>





      </>

    </>
  )
}

const reload = (prop) => ({
  value: prop.modalHandler
})

export default connect(reload)(AddTeamTask)
