import { Avatar } from '@mui/material'
import axios from 'axios';
import React, { useContext, useState } from 'react'
import ENDPOINT from '../../context/EndPoint';
import { Dangerous } from "@mui/icons-material";
import { AttachMoney, CheckCircleOutline, Edit, GroupAddOutlined, MoreVert } from "@material-ui/icons";
import { Dropdown } from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import SecurityHeaders from '../../context/SecurityHeaders';
import EditLeadModal from './EditLeadModal';
import { useActionsContext } from '../../context/ActionsContext';
const LeadItem = ({index,item}) => {
const {user} = useContext(AuthContext)
const [edit,setedit] = useState(false)
const {permission}= useActionsContext()
  const updateLead = async ({status}) => {

 
      const data = new FormData();
      data.append('session',user.SessionId);
      data.append('status',status);
      data.append('id',item.id);
      const res = await axios.post(`${ENDPOINT}admin-update-lead.php`,data,SecurityHeaders);
      if(res){
        if (res.data.status === "success") {
      
        } else {
      
        }
      }

    
  };
  return (
   <>
 
    
   <EditLeadModal item={item} visibility={edit} handleClose={()=>setedit(false)}/>          
              
 <div className="card mb-1 ribbon-box ribbon-fill ribbon-sm position-relative">

  <div className="lead-options">
  <Dropdown className="options-dropdown">
                            <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
                              <MoreVert className="text-dark" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
{parseInt(permission.edit_lead)?
<>

<Dropdown.Item
                                type="button"
                                onClick={() =>
                               setedit(true)
                                }
                              >
                                <Edit className="mr-2 text-secondary" />
                                Edit Lead
                              </Dropdown.Item>
</>:
<>

</>}

{parseInt(permission.mark_lead_interested)?
<>
<Dropdown.Item
                                type="button"
                                onClick={() =>
                                  updateLead({
                                    status: "interested",
                                    ticketid: item.id,
                                  })
                                }
                              >
                                <CheckCircleOutline className="mr-2 text-success" />{" "}
                                Mark Interested
                              </Dropdown.Item>


</>:
<></>

}

{parseInt(permission.mark_lead_scheduled)?
<>
<Dropdown.Item
                                type="button"
                                onClick={() =>
                                  updateLead({
                                    status: "demo_scheduled",
                                    ticketid: item.id,
                                  })
                                }
                              >
                                <CheckCircleOutline className="mr-2 text-success" />{" "}
                            Demo Scheduled
                              </Dropdown.Item>


</>:
<></>
}                            

                            
{parseInt(permission.mark_lead_not_interested)?
<>
<Dropdown.Item
                                type="button"
                                onClick={() =>
                                  updateLead({
                                    status: "not_interested",
                                    ticketid: item.id,
                                  })
                                }
                              >
                                <Dangerous className="mr-2 text-danger" />
                                Not Interested
                              </Dropdown.Item>
</>:
<></>
}
                    
 {
  parseInt(permission.mark_lead_pending_payment)?
  <>

<Dropdown.Item
                                type="button"
                                onClick={() =>
                                  updateLead({
                                    status: "pending_payment",
                                    ticketid: item.id,
                                  })
                                }
                              >
                                <AttachMoney className="mr-2 text-info" />
                                Pending Payment
                              </Dropdown.Item>
  </>:
  <></>
 }
                          
{parseInt(permission.mark_lead_joined)?

<>
<Dropdown.Item
                                type="button"
                                onClick={() =>
                                  updateLead({
                                    status: "joined",
                                    ticketid: item.id,
                                  })
                                }
                              >
                                <GroupAddOutlined className="mr-2 text-success" />
                                Joined
                              </Dropdown.Item>
</>:
<></>


}





                         
                            </Dropdown.Menu>
                          </Dropdown>
  </div>
                  <div className="ribbon ribbon-info">
                    <i className="ri-flashlight-fill" />
                  </div>
                  <div className="card-body">
                    <a
                      className="d-flex align-items-center"
                      data-bs-toggle="collapse"
                      href={"#leadDiscovered"+item.id}
                      role="button"
                      aria-expanded="false"
                      aria-controls={"leadDiscovered"+item.id}
                    >
                      <div className="flex-shrink-0">
                       <Avatar src={item.profile_pic}></Avatar>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h6 className="fs-14 mb-1">{item.name}</h6>
                        <p className="text-muted mb-0">
                          {item.created}
                        </p>
                      </div>
                    </a>
                  </div>
                  <div
                    className="collapse border-top border-top-dashed "
                    id={"leadDiscovered"+item.id}
                  >
                    <div className="card-body">
                    
                      <p className="text-muted">
                      {item.note}
                      </p>
                      <ul className="list-unstyled vstack gap-2 mb-0">
                        <li>
                          <div className="d-flex">
                            <div className="flex-shrink-0 avatar-xxs text-muted">
                              <i className="ri-question-answer-line" />
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-0">WhatsApp</h6>
                              <small className="text-muted">
                              {item.whatsapp}
                              </small>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex">
                            <div className="flex-shrink-0 avatar-xxs text-muted">
                              <i className="ri-mac-line" />
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-0">Phone</h6>
                              <small className="text-muted">
                             +91-{item.phone}
                              </small>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex">
                            <div className="flex-shrink-0 avatar-xxs text-muted">
                              <i className="ri-earth-line" />
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-0">Email</h6>
                              <small className="text-muted">
                              {item.email}
                              </small>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="card-footer hstack gap-2">
                    <a target='_blank' href={"https://wa.me?91"+item.whatsapp} className="btn btn-info btn-sm w-100">
                        <i className="ri-question-answer-line align-bottom me-1" />{" "}
                     Message
                      </a>
                      <a target='_blank' href={"tel:91"+item.phone} className="btn btn-warning btn-sm w-100">
                        <i className="ri-phone-line align-bottom me-1" /> Call
                      </a>
                    
                    </div>
                  </div>
                </div>
                {/*end card*/}
   </>
  )
}

export default LeadItem