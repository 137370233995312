import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { ref, push, onValue, getDatabase } from "firebase/database";
import AboutPage from './pages/AboutPage'
import HomeSection from './pages/HomePage'
import HomePage from './pages/HomePage'
import ContactPage from './pages/ContactPage'
import Navbar from './components/Navbar'
import NavbarTheme from './themes/NavbarTheme'
import EmptyTheme from './themes/EmptyTheme'
import ProjectsListPage from './pages/projects/ProjectsListPage'
import AnalyticsPage from './pages/analytics/AnalyticsPage'
import ProjectsDashboard from './pages/projects/ProjectsDashboard'
import WalletPage from './pages/wallet/WalletPage'
import JobDashboard from './pages/jobs/JobDashboard'
import ChatPage from './pages/chat/ChatPage'
import ProductsPage from './pages/products/ProductsPage'
import PaymentsList from './pages/payments/PaymentsList'
import UsersList from './pages/users/UsersList'
import CourseRegistrations from './pages/course-registrations/CourseRegistrations'
import ProjectOverview from './pages/projects/ProjectOverview'
import InvoicesList from './pages/invoices/InvoicesList'
import CreateInvoice from './pages/invoices/CreateInvoice'
import CandidatesList from './pages/candidates/CandidatesList'
import Signin from './pages/auth/Signin'
import StudentProfile from './pages/students/StudentProfile'
import SuperAdminDashboard from './pages/superadmin/SuperAdminDashboard'
import LeadsPage from './pages/leads/LeadsPage'
import CompleteAnalytics from './pages/analytics/CompleteAnalytics'
import StudentsList from './pages/students/StudentsList'
import StudentSupportPage from './pages/support/StudentsSupportPage'
import StudentsPlacementSupport from './pages/support/StudentsPlacementSupport'
import CustomerSupportPage from './pages/customer/CustomerSupportPage'
import ClassesManagement from './pages/classes/ClassesManagement'
import firebaseConfig from './firebase-Config'
import EmployeesList from './pages/team/EmployeesList'
import CoursesList from './pages/courses/CoursesList'
import BatchesList from './pages/courses/BatchesList'
import TeamWorkSubmissions from './pages/team/TeamWorkSubmissions'
import Withdrawls from './pages/wallet/Withdrawls'
import CouponCodes from './pages/coupons/CouponCodes'
import Recordings from './pages/recordings/Recordings'
import Shorts from './pages/videos/Shorts'
import Tutorials from './pages/videos/Tutorials'
import JobHunt from './pages/jobs/JobHunt'
import CampusAmbassadors from './pages/campus-ambassadors/CampusAmbassadors'
import WorkshopRegistrations from './pages/workshops/WorkshopRegistrations'
import ReviewsList from './pages/reviews/ReviewsList'
import TechnicalQuizzes from './pages/quizzes/TechnicalQuizzes'
import AssignmentSubmissions from './pages/assignments/AssignmentSubmissions'
import ProjectSubmissions from './pages/projects/ProjectSubmissions'
import BatchManagement from './pages/courses/BatchManagement'
import TeamMemberProfile from './pages/team/TeamMemberProfile'
import InternshipApplications from './pages/internships/InternshipApplications'
import DoubtSupportDetails from './pages/support/DoubtSupportDetails'
import CourseManagement from './pages/courses/CourseManagement'
import Videos from './pages/videos/Tutorials'
import HackingWorkshopRegistrations from './pages/workshops/HackingWorkshopRegistrations'
import TeamMeetings from './pages/meetings/TeamMeetings'
import Localbase from 'localbase'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from './context/AuthContext'
import ENDPOINT from './context/EndPoint'
import axios from 'axios'
import SecurityHeaders from './context/SecurityHeaders'
import AddNewCourse from './pages/courses/AddNewCourse'
import AddNewBatch from './pages/courses/AddNewBatch'
import CourseTopicsManagement from './pages/courses/CourseTopicsManagement'
import MockTestsManagement from './pages/mock-tests/MockTestsManagement'
import CertificateManagement from './pages/certifications/CertificateManagement'
import MockTestQuestions from './pages/mock-tests/MockTestQuestions'
import ActiveBatchesList from './pages/courses/ActiveBatchesList'
import ClassRecordings from './pages/recordings/ClassRecordings'
import TeamList from './pages/team/TeamList'
import { TeamProvider } from './actions/team/TeamContext'
import { StudentDoubtSupportProvider } from './context/support/StudentDoubtSupportContext'
import AllEmployeesLeaveRequests from './pages/team/AllEmployeesLeaveRequests'
import TeamWorkSubmissionsPage from './pages/team/TeamWorkSubmissionsPage'
import { CompleteDataProvider } from './context/CompleteData'
import AllPosts from './pages/posts/AllPosts'
import AllComments from './pages/posts/AllComments'
import MilestonesPage from './pages/milestones/MilestonesPage'
import AddInternship from './pages/internships/AddInternship'
import DailyReports from './pages/reports/DailyReports'
import AddDailyReport from './pages/reports/AddDailyReport'
import WebsiteLogs from './pages/website/WebsiteLogs'
import Expenses from './pages/expenses/Expenses'
import PermissionsManagement from './pages/security/PermissionsManagement'
import EditCourseOverview from './pages/courses/EditCourseOverview'
import HackingInternshipRegistrations from './pages/internships/HackingInternshipRegistrations'
import InternalVideoManagement from './pages/videos/InternalVideoManagement'
import UploadVideo from './pages/videos/UploadVideo'
import CampusAmbassadorsPage from './pages/campus-ambassadors/CampusAmbassadorsPage'
import InternshipsList from './pages/internships/InternshipsList'
import UpdateVideoDetails from './pages/videos/UpdateVideoDetails'
import UploadShort from './pages/videos/UploadShort'
import CreateCertificate from './pages/certifications/CreateCertificate'
import WhatsAppSupport from './pages/whatsapp/WhatsAppSupport';
import ClassManagerDashboard from './pages/dashboard/ClassMaagerDashboard';
import CommunityManagerDashboard from './pages/dashboard/CommunityManagerDashboard';
import HRManagerDashboard from './pages/dashboard/HRManagerDashboard';
import CollegeLeadRegistrations from './pages/college/CollegeLeadRegistrations';
import UpdateShort from './pages/videos/UpdateShort';
import QuizAttemptHistory from './pages/quizzes/QuizAttemptHistory';
import AmbassadorProfile from './pages/campus-ambassadors/AmbassadorProfile';
import { ActionsProvider, useActionsContext } from './context/ActionsContext';
import FreeResources from './pages/resources/FreeResources';
import Test from './Test';
import WhatsAppNotifications from './pages/whatsapp/WhatsAppNotifications';
import PaymentScreenShots from './pages/payments/PaymentScreenShots';
import MarketingManagerDashboard from './pages/dashboard/MarketingManagerDashboard';
import CustomerSupportAdmin from './pages/dashboard/CustomerSupportAdmin';
import FinanceManagerDashboard from './pages/dashboard/FinanceManagerDashboard';
import PlacementOfficerDashboard from './pages/dashboard/PlacementOfficerDashboard';
import TeamGroups from './pages/community/TeamGroups';
import Test2 from './Test2';
import DailyClassFeedbacks from './pages/feedbacks/DailyClassFeedbacks';
import DetectDevice from './context/DetectDevice';
import NotificationsPage from './pages/notifications/NotificationsPage';


import { redirect } from "react-router-dom";
function App() {






  const { user } = useContext(AuthContext);


  const db = new Localbase('db');



  const [permission, setpermission] = useState(JSON.parse(sessionStorage.getItem("permissions")) || null)

  const [account, setaccount] = useState(null)

  useEffect(() => {



    db.collection('paths').doc('user').get().then(user => {
      setaccount(user.user)
    }).catch(error => {

    })

    if (user !== null || user !== "null") {
      getaccount();


    }


  }

    , [user, window.location.pathname])


  useEffect(() => {
    if (permission === null) {

    }

  }, [permission])

  const getaccount = async () => {


    try {

      fetch(`${ENDPOINT}get-account.php?session=${user.SessionId}`)
        .then(r => r.json())
        .then(result => {

          if (result) {

            if ((result.status === "success") && (result.data != null)) {



              setaccount(result.data);
              db.collection('paths').add({
                user: result.data
              }, 'user')
              GetPermissions()
            } else {
              try {
                db.collection('paths').doc('user').delete()

                db.collection("paths").doc("notifications").delete()

                db.collection('paths').doc('profile').delete()
              }
              catch (error) {


              }
              //localStorage.setItem("user",null);
              // window.location.replace("/")
            }

          }
          else {

            localStorage.setItem("user", null);
            window.location.replace("/")
          }







        })
    }
    catch (err) {

    }



  };





  const GetPermissions = async () => {
    const data = new FormData()
    if (user.SessionId) {
      data.append("session", user.SessionId)
      const res = await axios.post(ENDPOINT + "admin-permissions.php", data, SecurityHeaders)

      if (res) {

        if (res.data) {
          if (res.data.status === "success" & res.data.permissions !== null) {
            setpermission(res.data.permissions)
            sessionStorage.setItem("permissions", JSON.stringify(res.data.permissions))
          } else {

          }
        }
      }
    }


  }



  const [showsidebar, setshowsidebar] = useState(false)
  const [shownavbar, setshownavbar] = useState(true)
  useEffect(() => {



  }, [window.location.pathname])
  const pathname = window.location.pathname
  const updateActivity = async () => {


    const formData = new FormData();


    //formData.append('whyus',document.getElementById('whyus').value)
    if (user) {

      formData.append('session', user.SessionId);

    }


    formData.append('page', pathname);


    const res = await axios.post(ENDPOINT + "save-activity.php", formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
    if (res) {
      //  // console.log("activity "+JSON.stringify(res))


    }
  }

  useEffect(() => {

    updateActivity()
  }, [window.location])


  useEffect(() => {
    const device = DetectDevice()
    if(device==="mobile"){
      setshowsidebar(false)
    }
    if (permission) {
      let path = window.location.pathname

      if (permission) {

        setshownavbar(true)
        setshowsidebar(true)



        
        if (path.includes("login")) {
          setshownavbar(false)
          setshowsidebar(false)
        } else {
          if (account && permission) {
            setshowsidebar(true)
            setshownavbar(true)
          } else {
            setshowsidebar(false)

            setshownavbar(false)
          }
        }


      } else {
        setshowsidebar(false)
        setshownavbar(false)
        if (path.includes("login")) {
          setshownavbar(false)
          setshowsidebar(false)
        } else {

        }
      }


    }
if(!user){

  setshowsidebar(false)
}

  }, [user, window.location.pathname, permission])

  return (<>

    <ActionsProvider>


      <BrowserRouter>
       {account&&permission?
      <>
       <NavbarTheme account={account} showsidebar={showsidebar} shownavbar={shownavbar}>

<Routes>
  {account && permission ?
    <>
      <Route path='/test' element={<Test2 />} />
      <Route path='/class-feedbacks' element={<DailyClassFeedbacks />} />
      <Route path='/class-feedbacks/:course_url/:class_id' element={<DailyClassFeedbacks />} />



      <Route path='/invoice/:id' element={<CreateInvoice/>} />
      <Route path='/teams-groups' element={<TeamGroups/>} />


      <Route path='/payment-screen-shots' element={<PaymentScreenShots />} />

      <Route path='/hr-dashboard' element={<HRManagerDashboard />}></Route>

      <Route path='/marketing-dashboard' element={<MarketingManagerDashboard />}></Route>

      <Route path='/class-dashboard' element={<ClassManagerDashboard />}></Route>

      <Route path='/customer-dashboard' element={<CustomerSupportAdmin />}></Route>

      <Route path='/placement-dashboard' element={<PlacementOfficerDashboard />}></Route>

      <Route path='/finance-dashboard' element={<FinanceManagerDashboard />}></Route>



      <Route path='/marketing' element={<WhatsAppNotifications />} />


      <Route path='/leads' element={<LeadsPage />} />
      <Route path='/student-profile/:student_id' element={<StudentProfile />} />
   
      <Route path='/internship-applications' element={<InternshipApplications />} />
      <Route path='/quiz-attempts' element={<QuizAttemptHistory />} />


      <Route path='/daily-reports' element={<DailyReports />} />

      <Route path='/milestones' element={<MilestonesPage />} />

      {/* whatsapp  start */}
      <Route path='/whatsapp/:phone_number' element={<WhatsAppSupport />} />

      <Route path='/classes-management' element={<ClassManagerDashboard />} />
      <Route path='/college-registrations' element={<CollegeLeadRegistrations />} />



      {/* whatsapp  end */}
      <Route path='/' element={<>{(permission && user && account) ? <>




        <SuperAdminDashboard options={{userinfo:true}} permission={permission} />




      </> :null}</>} />
      <Route path="/classes-management" element={<ClassManagerDashboard />} />
      <Route path='/add-daily-report' element={<AddDailyReport />} />

      <Route path='/job-applications' element={<CandidatesList />} />
      <Route path='/create-invoice' element={<CreateInvoice />} />
      <Route path='/invoices-list' element={<InvoicesList />} />
      <Route path='/project-overview' element={<ProjectOverview />} />
      <Route path='/course-registrations' element={<CourseRegistrations />} />
      <Route path='/users' element={<UsersList permission={permission} />} />
      <Route path='/payments' element={<PaymentsList />} />
      <Route path='/products' element={<ProductsPage />} />
      <Route path='/chat' element={<ChatPage />} />
      <Route path='/job-dashboard' element={<JobDashboard />}></Route>
      <Route path='/analytics' element={<><AnalyticsPage /></>}></Route>
      <Route path='/projects-dashboard' element={<ProjectsDashboard />}></Route>

      <Route path='/edit-course/:course_id' element={<EditCourseOverview />}></Route>

      <Route path='/wallet' element={<WalletPage />}></Route>

      <Route path='/leads' element={<LeadsPage />} />

      <Route path='/notifications' element={<NotificationsPage />} />

      <Route path='/about' element={<NavbarTheme><AboutPage /></NavbarTheme>}></Route>

      <Route path='/permissions/:user_id' element={<PermissionsManagement />}></Route>

      <Route path='/add-internship' element={<AddInternship />}></Route>

      <Route path='/batch-management/:course_url/:batch_id' element={<><BatchManagement /></>}></Route>

      <Route path='/complete-analytics' element={<CompleteAnalytics />} />

      <Route path='/projects' element={<ProjectsListPage />} />

      <Route path='/videos' element={<InternalVideoManagement />} />

      <Route path='/videos/edit/:video_id' element={<UpdateVideoDetails />} />
      <Route path='/short/edit/:video_id' element={<UpdateShort />} />

      <Route path='/students' element={<StudentsList />} />

      <Route path='/shorts/upload' element={<UploadShort />} />

      <Route path='/course-management/:course_url/:course_id' element={<CourseManagement />} />

      <Route path='/students-support' element={
        <StudentDoubtSupportProvider>
          <StudentSupportPage />

        </StudentDoubtSupportProvider>
      } />

      <Route path='/students-support/:id' element={<DoubtSupportDetails />} />

      <Route path='/placement-support' element={<StudentsPlacementSupport />} />


      <Route path='/milestones' element={<MilestonesPage />} />


      <Route path='/active-batches' element={<ActiveBatchesList />} />

      <Route path='/customer-support' element={<CustomerSupportPage options={{limit:100}} />} />

      <Route path='/certificates' element={<CertificateManagement />} />

      <Route path='/course-certification/:course_url' element={<MockTestsManagement />} />

      <Route path='/mock-tests/:course_url' element={<MockTestsManagement />} />

      <Route path='/:test_type/:course_url/test-code/:test_code' element={<MockTestQuestions />} />

      <Route path='/add-course' element={<AddNewCourse />} />

      <Route path='/add-batch/:course_url/:course_id' element={<AddNewBatch />} />

      <Route path='/course-management/:course_url/chapters/:chapter_id' element={<CourseTopicsManagement />} />


      <Route path='/technical-quizzes' element={<TechnicalQuizzes />} />

      <Route path='/reviews' element={<ReviewsList />} />

      <Route path='/classes' element={<ClassesManagement />} />

      <Route path='/team' element={<TeamProvider>
        <TeamList />
      </TeamProvider>} />


      <Route path='/courses' element={<CoursesList />} />

      <Route path='/posts' element={<AllPosts />} />

      <Route path='/comments' element={<AllComments />} />

      <Route path='/batches' element={<BatchesList />} />

      <Route path='/withdrawls' element={<Withdrawls />} />

      <Route path='/job-hunt' element={<JobHunt />} />

      <Route path='/tutorials' element={<Tutorials />} />

      <Route path='/internships' element={<InternshipsList />} />

      <Route path='/shorts' element={<Shorts />} />

      <Route path='/campus-ambassadors' element={<CampusAmbassadorsPage />} />

      <Route path='/recordings' element={<ClassRecordings />} />

      <Route path='/team-member/internship/:internship_id/:user_id' element={<TeamMemberProfile />} />

      <Route path='/campus-ambassadors-profile/:user_id' element={< AmbassadorProfile />} />

      <Route path='/workshop-registrations' element={<WorkshopRegistrations />} />

      <Route path='/hacking-workshop-registrations' element={<HackingWorkshopRegistrations />} />

      <Route path='/technical-quizzes' element={<WorkshopRegistrations />} />

      <Route path='/hacking-internship-registrations' element={<HackingInternshipRegistrations />} />


      <Route path='/website-logs' element={<WebsiteLogs />} />


      <Route path='/team-meetings' element={<TeamMeetings />} />

      <Route path='/team-leaves' element={<AllEmployeesLeaveRequests />} />

      <Route path='/videos/upload' element={<UploadVideo />} />


      <Route path='/coupon-codes' element={<CouponCodes />} />

      <Route path='/team-task-submissions' element={<TeamWorkSubmissionsPage />} />

      <Route path='/assignment-submissions' element={<AssignmentSubmissions />} />

      <Route path='/project-submissions' element={<ProjectSubmissions />} />

      <Route path='/contact' element={<EmptyTheme><ContactPage /></EmptyTheme>}></Route>

      <Route path='/expenses' element={<Expenses />}></Route>


      <Route path='/test/:username/:user_id' element={<><ContactPage /></>}></Route>

      <Route path='/create-certificate' element={<CreateCertificate />}></Route>
      {/*dropdown added by manoj*/}

      <Route path='/free-resources' element={<FreeResources />}></Route>
      {/*dropdown added by manoj*/}


    </> :
    <>

    </>}



</Routes>

</NavbarTheme>
      
      </>:<EmptyTheme>
<Routes>

<Route path='/' element={account && permission ? null:<Signin/>}></Route>

</Routes>
</EmptyTheme>}



      </BrowserRouter>



    </ActionsProvider>
  </>)


}



export default App