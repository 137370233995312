import React, { useContext, useEffect, useState } from 'react'
import ClassScheduleItem from './ClassScheduleItem'
import DemoData from '../../data/DemoData'
import ClassesList from './ClassesList'
import { AuthContext } from '../../context/AuthContext'
import { useParams } from 'react-router-dom'
import ENDPOINT from '../../context/EndPoint'
import axios from 'axios';
import SecurityHeaders from '../../context/SecurityHeaders';

const ClassesManagement = ({ options }) => {

  const [classes, setclasses] = useState(null)
  const { user } = useContext(AuthContext)
  const getChapters = async () => {

    const formData = new FormData();

    formData.append('session', user.SessionId);
    if (options?.limit) {
      formData.append('limit', options?.limit);
    }
    const res = await axios.post(ENDPOINT + "get-all-class-schedules.php", formData, SecurityHeaders);
    if (res) {
      if (res.data.status === "success") {
        setclasses(res.data.data)
        setInitialData(res.data.data)
        setResponse(res.data.data)
      }
      else {

      }
    }
  }
  useEffect(() => {
    getChapters()
  }, [])

  const [initialData, setInitialData] = useState([]);
  const [response, setResponse] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setInitialData(classes);
    setResponse(classes);
  }, [classes]);

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return (
        item.course_url.toLowerCase().includes(term) ||
        item.class_title.toLowerCase().includes(term) ||
        item.class_starts.includes(term) ||
        item.class_status.toLowerCase().includes(term)
      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };



  return (
    <>

      <div className="tab-pane min-vh-80" id="documents" role="tabpanel">
        <div className="card">
          <div className="card-body">
            {
              options?.search === false ? <></> : <div className="d-flex align-items-center mb-4">
                <h5 className="card-title flex-grow-1 mb-0">
                  All Classes
                </h5>
                <div className="card-body border border-dashed border-end-0 border-start-0">
                  <form>
                    <div className="row g-3">
                      <div className="col-xxl-5 col-sm-6">
                        <div className="search-box">
                          <input
                            onChange={handleSearch}
                            type="text"
                            className="form-control search"
                            placeholder="Search for class title, description, course..."
                          />
                          <i className="ri-search-line search-icon" />
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-2 col-sm-6">
                        <div >
                          <input
                            type="date"
                            onChange={handleSearch}
                            className="form-control"
                            data-provider="flatpickr"
                            data-date-format="d M, Y"
                            data-range-date="true"
                            id="demo-datepicker"
                            ty
                            placeholder="Select date"
                          />
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-2 col-sm-4">
                        <div>
                          <select
                            className="form-control"
                            onChange={handleSearch}
                            data-choices=""
                            data-choices-search-false=""
                            name="choices-single-default"
                            id="idStatus"
                          >
                            <option value="">Status</option>

                            <option value="ongoing">Ongoing</option>
                            <option value="incomplete">Incomplete</option>

                          </select>
                        </div>
                      </div>

                      <div className="col-xxl-1 col-sm-4">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary h-fit"

                          >

                            <i className="ri-equalizer-fill me-1 align-bottom" />
                            Filters
                          </button>
                        </div>
                      </div>
                      {/*end col*/}
                    </div>
                    {/*end row*/}
                  </form>
                </div>
              </div>
            }

            {response ?
              <>


                <ClassesList options={options} Reload={() => { getChapters() }} classes={response} />
              </> :
              <>

              </>}
          </div>
        </div>
      </div>

    </>
  )
}

export default ClassesManagement