import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import ENDPOINT from '../../context/EndPoint';
import SecurityHeaders from '../../context/SecurityHeaders';
import { AuthContext } from '../../context/AuthContext';

const MilestonesPage = () => {

  const { user } = useContext(AuthContext);
  const [milestones, setMilestones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getMilestones = async () => {
    try {
      const data = new FormData();
      data.append('session', user.SessionId);

      const res = await axios.post(ENDPOINT + 'admin-get-team-milestones.php', data, SecurityHeaders);

      if (res.data && res.data.status === 'success') {
        setMilestones(res.data.data);
      } else {
        setError(res.data.message || 'Unknown error');
      }
    } catch (error) {
      setError('An error occurred while fetching team milestones');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMilestones();
  }, []);
  return (
  <>
  
  <div className="row">
  <div className="col-lg-12">
    <div>
      <h5>Center Timeline</h5>
      <div className="timeline">


      {milestones.length > 0 && (
        <>
          {milestones.map((item, index) => (
            <div key={index}>
                  <div className="timeline-item left">
          <i className="icon ri-stack-line" />
          <div className="date">15 Dec 2021</div>
          <div className="content">
            <div className="d-flex">
              <div className="flex-shrink-0">
                <img
                  src="assets/images/users/avatar-5.jpg"
                  alt=""
                  className="avatar-sm rounded material-shadow-none"
                />
              </div>
              <div className="flex-grow-1 ms-3">
                <h5 className="fs-15">
                  @Erica245{" "}
                  <small className="text-muted fs-13 fw-normal">
                    - 10 min Ago
                  </small>
                </h5>
                <p className="text-muted mb-2">
                ewf  Wish someone a sincere ‘good luck in your new job’ with these
                  sweet messages. Make sure you pick out a good luck new job
                  card to go with the words, and pop a beautiful bunch of
                  flowers from our gift range in your basket, to make them feel
                  super special.
                </p>
                <div className="hstack gap-2">
                  <a className="btn btn-sm btn-light">
                    <span className="me-1">🔥</span> 19
                  </a>
                  <a className="btn btn-sm btn-light">
                    <span className="me-1">🤩</span> 22
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
            </div>
          ))}
        </>
      )}

        <div className="timeline-item right">
          <i className="icon ri-vip-diamond-line" />
          <div className="date">22 Oct 2021</div>
          <div className="content">
            <h5>Adding a new event with attachments</h5>
            <p className="text-muted">
              Too much or too little spacing, as in the example below, can make
              things unpleasant for the reader.
            </p>
            <div className="row g-2">
              <div className="col-sm-6">
                <div className="d-flex border border-dashed p-2 rounded position-relative">
                  <div className="flex-shrink-0 avatar-xs">
                    <div className="avatar-title bg-danger-subtle text-danger fs-15 rounded material-shadow-none">
                      <i className="ri-image-2-line" />
                    </div>
                  </div>
                  <div className="flex-shrink-0"></div>
                  <div className="flex-grow-1 overflow-hidden ms-2">
                    <h6 className="text-truncate mb-0">
                      <a href="javascript:void(0);" className="stretched-link">
                        Business Template - UI/UX design
                      </a>
                    </h6>
                    <small>685 KB</small>
                  </div>
                </div>
              </div>
              {/*end col*/}
              <div className="col-sm-6">
                <div className="d-flex border border-dashed p-2 rounded position-relative">
                  <div className="flex-shrink-0 avatar-xs">
                    <div className="avatar-title bg-info-subtle text-info fs-15 rounded material-shadow-none">
                      <i className="ri-file-zip-line" />
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-2 overflow-hidden">
                    <h6 className="mb-0 text-truncate">
                      <a href="javascript:void(0);" className="stretched-link">
                        Bank Management System - PSD
                      </a>
                    </h6>
                    <small>8.78 MB</small>
                  </div>
                </div>
              </div>
              {/*end col*/}
            </div>
            {/*end row*/}
          </div>
        </div>
        <div className="timeline-item left">
          <i className="icon ri-gift-line" />
          <div className="date">10 Jul 2021</div>
          <div className="content">
            <h5>Create new project buildng product</h5>
            <p className="text-muted">
              Every team project can have a velzon. Use the velzon to share
              information with your team to understand and contribute to your
              project.
            </p>
            <div className="avatar-group mb-2">
              <a
                href="javascript: void(0);"
                className="avatar-group-item material-shadow-none"
                data-bs-toggle="tooltip"
                data-bs-trigger="hover"
                data-bs-placement="top"
                title=""
                data-bs-original-title="Christi"
              >
                <img
                  src="assets/images/users/avatar-4.jpg"
                  alt=""
                  className="rounded-circle avatar-xs"
                />
              </a>
              <a
                href="javascript: void(0);"
                className="avatar-group-item material-shadow-none"
                data-bs-toggle="tooltip"
                data-bs-trigger="hover"
                data-bs-placement="top"
                title=""
                data-bs-original-title="Frank Hook"
              >
                <img
                  src="assets/images/users/avatar-3.jpg"
                  alt=""
                  className="rounded-circle avatar-xs"
                />
              </a>
              <a
                href="javascript: void(0);"
                className="avatar-group-item material-shadow-none"
                data-bs-toggle="tooltip"
                data-bs-trigger="hover"
                data-bs-placement="top"
                title=""
                data-bs-original-title=" Ruby"
              >
                <div className="avatar-xs">
                  <div className="avatar-title rounded-circle bg-light text-primary">
                    R
                  </div>
                </div>
              </a>
              <a
                href="javascript: void(0);"
                className="avatar-group-item material-shadow-none"
                data-bs-toggle="tooltip"
                data-bs-trigger="hover"
                data-bs-placement="top"
                title=""
                data-bs-original-title="more"
              >
                <div className="avatar-xs">
                  <div className="avatar-title rounded-circle">2+</div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="timeline-item right">
          <i className="icon ri-shield-star-line" />
          <div className="date">18 May 2021</div>
          <div className="content">
            <h5>
              Donald Palmer{" "}
              <small className="text-muted fs-13 fw-normal">
                - Has changed 2 attributes
              </small>
            </h5>
            <p className="text-muted fst-italic mb-2">
              " This is an awesome admin dashboard template. It is extremely
              well structured and uses state of the art components (e.g. one of
              the only templates using boostrap 5.1.3 so far). I integrated it
              into a Rails 6 project. Needs manual integration work of course
              but the template structure made it easy. "
            </p>
            <div className="hstack gap-2">
              <a className="btn btn-sm bg-light">
                <span className="me-1">💗</span> 35
              </a>
              <a className="btn btn-sm btn-light">
                <span className="me-1">👍</span> 10
              </a>
              <a className="btn btn-sm btn-light">
                <span className="me-1">🙏</span> 10
              </a>
            </div>
          </div>
        </div>
        <div className="timeline-item left">
          <i className="icon ri-user-smile-line" />
          <div className="date">10 Feb 2021</div>
          <div className="content">
            <h5>Velzon admin dashboard templates layout upload</h5>
            <p className="text-muted">
              Powerful, clean &amp; modern responsive bootstrap 5 admin
              template. The maximum file size for uploads in this demo :
            </p>
            <div className="row border border-dashed rounded gx-2 p-2">
              <div className="col-3">
                <img
                  src="assets/images/small/img-2.jpg"
                  alt=""
                  className="img-fluid rounded material-shadow-none"
                />
              </div>
              {/*end col*/}
              <div className="col-3">
                <img
                  src="assets/images/small/img-3.jpg"
                  alt=""
                  className="img-fluid rounded material-shadow-none"
                />
              </div>
              {/*end col*/}
              <div className="col-3">
                <img
                  src="assets/images/small/img-4.jpg"
                  alt=""
                  className="img-fluid rounded material-shadow-none"
                />
              </div>
              {/*end col*/}
              <div className="col-3">
                <img
                  src="assets/images/small/img-6.jpg"
                  alt=""
                  className="img-fluid rounded material-shadow-none"
                />
              </div>
              {/*end col*/}
            </div>
            {/*end row*/}
          </div>
        </div>
        <div className="timeline-item right">
          <i className="icon ri-fire-line" />
          <div className="date">01 Jan 2021</div>
          <div className="content">
            <h5>
              New ticket received{" "}
              <span className="badge bg-success-subtle text-success fs-10 align-middle ms-1">
                Completed
              </span>
            </h5>
            <p className="text-muted mb-2">
              It is important for us that we receive email notifications when a
              ticket is created as our IT staff are mobile and will not always
              be looking at the dashboard for new tickets.
            </p>
            <a
              href="javascript:void(0);"
              className="link-primary text-decoration-underline"
            >
              Read More <i className="ri-arrow-right-line" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*end col*/}
</div>

  </>
  )
}

export default MilestonesPage