import { ChevronLeft } from '@material-ui/icons'
import { Avatar } from '@mui/material'
import React, { useState } from 'react'
import dateFormat from 'dateformat'
const DailyReport = ({report}) => {

    const [display,setdisplay] = useState(false)
  return (
    <div className='col-lg-4 p-2'>

<div className="shadow p-3">

<div className="d-flex click" onClick={()=>setdisplay(!display)}>
     <Avatar src={report.profile_pic?report.profile_pic:null}/>
        <div className='text-cener ms-2'>
        <h5>{report.create_datetime?dateFormat(report.create_datetime,"dS mmm yyyy"):null}</h5>

<p className="mr-4">Reported by {report.reported_by} </p>
</div>

     </div>


{display?
<>

<div>
    <hr/>
    <p>{report.report}</p>

    <div className="d-flex"><p className=" text-secondary">Reported Time : {report.create_datetime?report.create_datetime:null}</p>
</div>
</div>
</>:
<>

</>}
</div>
</div>
  )
}

export default DailyReport