import React, { useContext, useEffect, useState } from 'react'
import CourseBatchesList from './CourseBatchesList'
import GetActiveBatches from '../../actions/course/GetActiveBatches'
import { AuthContext } from '../../context/AuthContext'
import { useSelector } from 'react-redux'

const ActiveBatchesList = () => {

    const value = useSelector((state)=>{
        return state.modalHandler
    });
const {user} = useContext(AuthContext)
    const [batches,setbatches] = useState(null)
    const[total,setTotal]=useState(null);
const GetBatches = async()=>{


    const res = await GetActiveBatches({payload:{
        session:user.SessionId,
        status:"active"
    
    }})

    if(res){

        if(res.status==="success"){

            setbatches(res.data)
            setTotal(res)
        }
    }
}
 // console.log(total?total.total_batches:null)


useEffect(()=>{

    GetBatches()
},[])
useEffect(() => {
    if(value.functionName === 'batches'){
        setbatches(null)
        GetBatches()
    }
}, [value]);

 // console.log(value);

  return (
<>
{batches?
<>


<CourseBatchesList addbatch={false} batches={batches}/>
</>:
<>


</>}
</>
  )
}

export default ActiveBatchesList