import styled from "styled-components"
import PieChart from "../../components/charts/PieChart"
import Users from "../users/Users"
import DemoData from "../../data/DemoData"
import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../context/AuthContext"
import { useParams } from "react-router-dom"
import axios from "axios"
import ENDPOINT from "../../context/EndPoint"
import SecurityHeaders from "../../context/SecurityHeaders"
import GetStatistics from "../../actions/statistics/GetStatistics"
import { connect } from "react-redux";
import AdminDeleteTickets from "../../actions/student/AdminDeleteTickets";
import AdminUpdateDoubtRequest from "../../actions/support/AdminUpdateDoubtRequest"
import { StudentDoubtSupportProvider, useContactRequests } from "../../context/support/StudentDoubtSupportContext"
import CustomerSupportTicketItem from "./CustomerTicketItem"
import { useActionsContext } from "../../context/ActionsContext"
import FetchCustomerTickets from "../../actions/support/FetchCustomerTickets"
import CustomerTicketsList from "./CustomerTicketsList"
function CustomerSupportPage({ value, dispatch, options }) {


  const { user } = useContext(AuthContext);
  const userdata = user;


  const { enablestats } = useActionsContext()
  const [doubts, setdoubts] = useState(null)
  const params = useParams();
  const [status, setstatus] = useState("all");

  const [query, setquery] = useState(null);

  const [resolutions, setresolutions] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);




  useEffect(() => {
    FetchCustomerRequests()

  }, [])

  const [toast, settoast] = useState(false);
  const [toasttitle, settoasttitle] = useState(null);
  const [info, setinfo] = useState(null);
  const [toastmessage, settoastmessage] = useState(null);
  const GetStats = async () => {
    const res = await GetStatistics({ payload: { SessionId: user.SessionId } });

    if (res) {
      if (res.status === "success") {
        setinfo(res.data);
      }
       // console.log("stat is" + JSON.stringify(res));
    }
  };

  const [requestDelete, setRequestDelete] = useState("");


  useEffect(() => {
    GetStats();
  }, []);

  // Initialize the response state with the provided data

  const [response, setResponse] = useState(doubts);
  const [searchTerm, setSearchTerm] = useState("");

  // Function to handle changes in the search input
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    // Filter the data based on search term
    const filteredData = doubts.filter((item) => {
      return (
        item.email.toLowerCase().includes(term) ||
        item.phone.includes(term) ||
        item.name.toLowerCase().includes(term) ||
        item.message.toLowerCase().includes(term)

      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };


  const Reload = () => {

    FetchCustomerRequests()
  }
  const [statistics, setstatistics] = useState(null)
  const Statistics = async () => {
    const data = new FormData()
    data.append("session", user.SessionId)

    const res = await axios.post(ENDPOINT + "customer-support-statistics.php", data, SecurityHeaders)

    if (res) {

      if (res.data.status === "success") {


        setstatistics(res.data.data)
      }
    }
  }
  const [limit, setlimit] = useState(100)

  const FetchCustomerRequests = async () => {

    const res = await FetchCustomerTickets({
      payload:
        { SessionId: user.SessionId, limit: options.limit || limit }
    }
    )

    if (res) {
      setdoubts(res)
    }
  }
  useEffect(() => {
    Statistics()
  }, [])




  useEffect(() => {

    setResponse(doubts)
  }, [doubts])

  const [stats, setstats] = useState(value.statisticsHandler.statistics)
  useEffect(() => {
    setstats(value.statisticsHandler.statistics)
  }, [value.statisticsHandler.statistics])
  return (

    <>

      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="h-100">












              {stats && (options && options.statistics) ?
                <>


                  {statistics ?
                    <>

                      {/*end row*/}
                      <div className="row">

                        <div className="col-lg-3 col-md-3 col-6">
                          <div className="card">
                            <div className="card-body">
                              <div className="d-flex align-items-center">
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                    <i className="ri-arrow-down-circle-fill align-middle" />
                                  </span>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                    Initiated                           </p>
                                  <h4 className=" mb-0">

                                    <span
                                      className="counter-value"
                                      data-target="14799.44"
                                    >
                                      {statistics.total_initiated}
                                    </span>
                                  </h4>
                                </div>
                                <div className="flex-shrink-0 align-self-end">
                                  <span className="badge bg-danger-subtle text-danger">
                                    <i className="ri-arrow-down-s-fill align-middle me-1" />
                                    {Math.round(statistics.percentage_initiated)}  %<span> </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* end card body */}
                          </div>
                          {/* end card */}
                        </div>
                        {/* end col */}
                        {/* end col */}
                        <div className="col-lg-3 col-md-3 col-6">
                          <div className="card">
                            <div className="card-body">
                              <div className="d-flex align-items-center">
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                    <i className="ri-arrow-down-circle-fill align-middle" />
                                  </span>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                    Closed                     </p>
                                  <h4 className=" mb-0">

                                    <span
                                      className="counter-value"
                                      data-target="14799.44"
                                    >
                                      {Math.round(statistics.total_closed)}
                                    </span>
                                  </h4>
                                </div>
                                <div className="flex-shrink-0 align-self-end">
                                  <span className="badge bg-danger-subtle text-danger">
                                    <i className="ri-arrow-down-s-fill align-middle me-1" />
                                    {statistics.percentage_closed} %<span> </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* end card body */}
                          </div>
                          {/* end card */}
                        </div>
                        {/* end col */}

                        {/* end col */}
                        <div className="col-lg-3 col-md-3 col-6">
                          <div className="card">
                            <div className="card-body">
                              <div className="d-flex align-items-center">
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                    <i className="ri-arrow-down-circle-fill align-middle" />
                                  </span>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                    Total                      </p>
                                  <h4 className=" mb-0">

                                    <span
                                      className="counter-value"
                                      data-target="14799.44"
                                    >
                                      {statistics.total_doubts}
                                    </span>
                                  </h4>
                                </div>
                                <div className="flex-shrink-0 align-self-end">
                                  <span className="badge bg-danger-subtle text-danger">
                                    <i className="ri-arrow-down-s-fill align-middle me-1" />
                                    <span> </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* end card body */}
                          </div>
                          {/* end card */}
                        </div>
                        {/* end col */}

                        {/* end col */}
                        <div className="col-lg-3 col-md-3 col-6">
                          <div className="card">
                            <div className="card-body">
                              <div className="d-flex align-items-center">
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                    <i className="ri-arrow-down-circle-fill align-middle" />
                                  </span>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                    Avg Resolution Time                         </p>
                                  <h4 className=" mb-0">

                                    <span
                                      className="counter-value"
                                      data-target="14799.44"
                                    >
                                      {parseInt(statistics.average_closed_time_minutes) > 60 ? (parseInt(statistics.average_closed_time_minutes) / 60) + " hrs" : statistics.average_closed_time_minutes + " mins"}
                                    </span>
                                  </h4>
                                </div>
                                <div className="flex-shrink-0 align-self-end">
                                  <span className="badge bg-danger-subtle text-danger">
                                    <i className="ri-arrow-down-s-fill align-middle me-1" />
                                    %<span> </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* end card body */}
                          </div>
                          {/* end card */}
                        </div>
                        {/* end col */}

                      </div>{" "}
                      {/* end row*/}

                    </> :
                    <>

                    </>}


                </> :
                <>

                </>}

              <div className="row">

                {/* end col sliding section*/}
                <div className="col-12 order-xxl-0 order-first">



                  <CustomerTicketsList data={response} Reload={Reload} options={options} handleSearch={handleSearch} />

                  <div className="d-flex mt-4">


                    {/*Graphical data */}

                    <div className="col-lg-5 ps-3">
                      <div className="card">

                      </div>
                    </div>

                    <div className="col-lg-5 ps-2">

                    </div>
                  </div>

                </div>
                {/* end col */}

              </div>

              <div className="row">
                <div className="col-xl-8">

                  {/* end card */}
                </div>
                {/* end col */}
                <div className="col-xl-4">
                  {/* card */}
                  <div className="card card-height-100">

                    {/* end card body */}
                  </div>
                  {/* end card */}
                </div>
                {/* end col */}
              </div>

            </div>{" "}
            {/* end .h-100*/}
          </div>{" "}
          {/* end col */}

          {/* end col */}
        </div>
      </div>

    </>
  )


}





const SetStatistics = (props) => ({
  value: props
})
export default connect(SetStatistics)(CustomerSupportPage)

const StyledSuperAdminDashboard = styled.div`



h1{

    color:red;
}




`