import React from 'react'
import ClassScheduleItem from './ClassScheduleItem'

const ClassesList = ({classes,Reload,notification,options}) => {
  
 
  return (
 <>
 <div className="w-100">


 </div>
 <div className="row">
                          <div className="col-lg-12">
                            <div className="table-responsive">
                              <table className="table table-borderless align-middle mb-0">
                                <thead className="table-light">
                                  <tr>
                                    {options?.title === false?<></>:
                                    <th scope="col">Class Title</th>
                                  }
                                    <th scope="col">Batch</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Timings</th>
                                    {options?.action === false?<></>:
                                     <th scope="col">Actions</th>}
                                    <th scope="col">Options</th>
                                  </tr>
                                </thead>
                                <tbody>
                                 {classes.map((item)=>(
                                    <>
                                    
                                    <ClassScheduleItem options={options} notification={notification} Reload={Reload} item={item}/>
                                    </>
                                 ))}
                               
                                </tbody>
                              </table>
                            </div>
                            {/* <div className="text-center mt-3">
                              <a
                                href="javascript:void(0);"
                                className="text-success"
                              >
                                <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2" />{" "}
                                Load more{" "}
                              </a>
                            </div> */}
                          </div>
                        </div>
 </> )
}

export default ClassesList