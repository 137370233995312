import React from 'react'
import { Link } from 'react-router-dom'
import defaultUser  from '../../assets/images/users/user-dummy-img.jpg'

function StudentListItem({ item,options }) {
  return (
    <>

      <div className="col-md-6 col-lg-12">

        <div className="card mb-0">

          <div className="card-body">

            <div className="d-lg-flex align-items-center">

              <div className="col-lg-3 d-flex">
                <div className="flex-shrink-0">

                  <div className="avatar-sm rounded">
                    <img
                      src={item.profile_pic ? item.profile_pic : defaultUser}
                      alt=""
                      className="member-img img-fluid d-block rounded"
                    />
                  </div>
                </div>
                <div className="ms-lg-3 my-3 my-lg-0">

                  <a href="pages-profile.html">
                    <h5 className="fs-16 mb-2">{item.first_name + " " + item.last_name}</h5>
                  </a>
                  <p className="text-muted mb-0"> st_id : {item.purchase_id}</p>
                </div>
              </div>
              <div className="d-flex col-lg-3 gap-4 mt-0 text-muted mx-auto">

                <div>
                  <i className=" text-primary  me-1 align-bottom" />
               <p className="text-muted text-center mb-0">   {item.course_url}</p>


<p className="text-muted text-center mb-0">{item.mode}</p>


                </div>
                <div>

                </div>
              </div>
           

              {options?.batch === false?<></>:

   <div className="d-flex flex-wrap gap-2 align-items-center mx-auto my-3 my-lg-0">


<div className="text-muted">{item.batch_id + " - " + item.team_id}</div>
</div>}

{options?.date === false?<></>:

<div className="d-flex flex-wrap gap-2 align-items-center mx-auto my-3 my-lg-0">


<div className="text-muted">{item.create_datetime}</div>
</div>}
              <div>

                <Link to={"/student-profile/" + item.purchase_id} className="btn btn-soft-success">
                  View Details
                </Link>

              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}

export default StudentListItem