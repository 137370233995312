import Avatar from '@mui/material/Avatar';
import React, { useContext, useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import AdminDeleteTickets from '../../actions/student/AdminDeleteTickets';
import { Check, Delete, MoreVert } from '@material-ui/icons';
import DeleteModal from '../../components/modals/DeleteModal';
import AdminUpdateDoubtRequest from '../../actions/support/AdminUpdateDoubtRequest';
import { AuthContext } from '../../context/AuthContext';
import { Pending } from '@mui/icons-material';
import DefaultToast from '../../components/toasts/DefaultToast';

import { useTeamData } from '../../actions/team/TeamContext';
import { useActionsContext } from '../../context/ActionsContext';
import NoPermissionModal from '../../components/modals/NoPermissionModal';
import axios from 'axios'
import ENDPOINT from '../../context/EndPoint';
import SecurityHeaders from '../../context/SecurityHeaders';
import { useDispatch, useSelector } from 'react-redux';

const StudentTicketItem = ({item,Reload,options}) => {
  const notification = useSelector((state)=>state.sendNotification.notification)
const {user} = useContext(AuthContext)
const [deletemodal,setdeletemodal] = useState(false)
const [toastdata,setoastdata] = useState(null)
const [toast,settoast] = useState(false)
const {permission} = useActionsContext()
const [nopermission,setnopermission] = useState(false)

const {deleting,setdeleting} =   useActionsContext();


  // console.log(notification);



 useEffect(()=>{

 },[])
const [deletedoubtrequestresponse,setdeletedoubtrequestresponse] = useState(null)


 const DeleteDoubtRequest = async ({payload}) => {
setdeleting(true)
try{
  const data = new FormData();

  data.append('id',item.id);
data.append("session",user.SessionId)


const res = await axios.post(ENDPOINT+'admin-delete-doubt-request.php',data,SecurityHeaders);
  
if(res){
  setdeletemodal(false)
  setdeletedoubtrequestresponse(res.data)
  if(res.data.status==="success"){
    setdeletemodal(false)
  }
}

}
catch(error){

}
}

useEffect(()=>{
  setoastdata(deletedoubtrequestresponse)
  if(deletedoubtrequestresponse&&deletedoubtrequestresponse.status==="success"){
    Reload()
    setdeletemodal(false)
  }
   },[deletedoubtrequestresponse])
  const UpdateRequest = async ({status})=>{


    const res = await AdminUpdateDoubtRequest({payload:{
      notification:notification,      
      session:user.SessionId,
      id:item.id,
      status:status
    }})
    if(res){

setoastdata(res.data)
if(res.status==="success"){

Reload()
}
else{


}

    }
  }
  const [deleteResponse,setDeleteResponse] = useState(null)
  const [updateResponse,setUpdateResponse] = useState(null)
  
  
 

  return (
<>
<DefaultToast toastdata={toastdata} show={toast}/>
<DeleteModal deleting={deleting} show={deletemodal} HandleClose={()=>setdeletemodal(false)} HandleDelete={()=>DeleteDoubtRequest({payload:{id:item.id}})}/>
<NoPermissionModal show={nopermission} HandleClose={()=>setnopermission(false)}/>

<tr>
                
               {options?.user===false?null: <td className='col-lg-3'>
                  <div className="d-flex  align-items-center">
                    <div className="flex-shrink-0 me-2">
                    <Avatar src={item.profile_pic?item.profile_pic:null}/>
                    </div>
                    <div className="flex-grow-1 ">
                     <h5> {item.first_name+" "+item.last_name}</h5>
                 
                    </div>
                  </div>
                </td>}
              {options?.course===false?null:  <td>{item.course_url}</td>}
                <td>    <p>{item.create_datetime}</p></td>
                <td>
                  <span className="badge bg-success-subtle text-success">
                  {item.status}
                  </span>
                </td>
              
                <td>
                <Dropdown className="options-dropdown">   
  <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
  <MoreVert className="text-dark"/>
  </Dropdown.Toggle>
  <Dropdown.Menu>
    
  
  <Dropdown.Item onClick={()=>{parseInt(permission.initiate_student_support)?UpdateRequest({status:"initiated"}):setnopermission(true)}}   ><Pending/> Initiate</Dropdown.Item>

  <Dropdown.Item   onClick={()=>{parseInt(permission.close_student_support)?UpdateRequest({status:"closed"}):setnopermission(true)}} ><Check/>Closed</Dropdown.Item>
  
  <Dropdown.Item   onClick={()=>{parseInt(permission.delete_student_support)?setdeletemodal(true):setnopermission(true)}} ><Delete/>Delete</Dropdown.Item>
 </Dropdown.Menu>
</Dropdown>
                </td>
              {options?.view===false?null:  <td>
                  <Link to={"/students-support/"+item.id} className="btn btn-outline-primary">View</Link>
                </td>}
              
             
              </tr>
</>
  )
}

export default StudentTicketItem