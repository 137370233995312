import { ChevronRightOutlined, MoreVert } from "@material-ui/icons";
import React, { useContext, useState } from "react";
import { Dropdown } from "react-bootstrap";
import EditInternMeetingTopipc from "../../components/modals/EditInternMeetingTopic";
import DeleteModal from "../../components/modals/DeleteModal";
import RescheduleTeamMeetngModal from "../../components/modals/RescheduleTeamMeetingModal";
import ENDPOINT from "../../context/EndPoint";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import HistoryIcon from "@mui/icons-material/History";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import BasicToast from "../../components/toasts/BasicToast";
import { connect } from "react-redux";

const TeamMeetingItem = ({ item, index, value,dispatch }) => {
  const { user } = useContext(AuthContext);

  const [toast, setToast] = useState(false);
  const [toastData, setToastData] = useState({ title: null, message: null });

  const [edittopic, setedittopic] = useState(false);
  const [deletemodal, setdeletemodal] = useState(false);
  const [reschedule, setreschedule] = useState(false);

  const DeleteTeamMeeting = async () => {
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)
    formData.append("session", user.SessionId);

    formData.append("id", item.id);

    const res = await axios.post(
      ENDPOINT + "delete-team-meeting.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (res) {
      if (res.data.status === "success") {
        dispatch({type:'FUNCTION_RELOAD',payload:'get-intern-meetings'});

        setdeletemodal(false);
      } else {
      }
      setToast(true);
      setToastData({title:res.data.title,message:res.data.message})
    }
  };

  return (
    <>
      <BasicToast
        visibility={toast}
        item={toastData}
        ToastHandleClose={() => setToast(false)}
      />
      <EditInternMeetingTopipc
       
        visibility={edittopic}
        handleClose={() => setedittopic(false)}
        item={item}
      />
      <RescheduleTeamMeetngModal
       
        visibility={reschedule}
        item={item}
        handleClose={() => setreschedule(false)}
      />
      <DeleteModal
        HandleClose={() => setdeletemodal(false)}
        show={deletemodal}
        HandleDelete={DeleteTeamMeeting}
      />
      <div className="col-lg-6 p-2">
        <div className=" shadow border bg-white  rounded p-3 mb-2">
          <div className=" w-100 flex">
            <div className="w-100">
              <h6 className="mb-0 w-100">{item.title}</h6>
              <p className="p-0 m-0">{item.starts}</p>
            </div>

            <Dropdown className="options-dropdown">
              <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0">
                <MoreVert className="text-dark" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setreschedule(true)}>
                  {" "}
                  <HistoryIcon className="me-2" /> Rechedule
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setedittopic(true)}>
                  {" "}
                  <EditIcon className="me-2" /> Edit Topic
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setdeletemodal(true)}>
                  {" "}
                  <DeleteIcon className="me-2" /> Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <hr />
          <div className="flex space-between">
            <p
              className={
                index / 2
                  ? "mb-0 w-auto text-success"
                  : index / 3
                  ? "mb-0  w-auto  text-danger"
                  : index / 5
                  ? "mb-0  w-auto  text-primary"
                  : "mb-0  w-auto  text-warning"
              }
            >
              <b>{item.day}</b>
            </p>

            <p className="mb-0 text-secondary"></p>
          </div>

          <div className="container-fluid p-0 text-secondary">
            <p className="text-truncate">{item.description}</p>
          </div>
          <div className="flex space-between">
            <p className="mb-0 text-secondary">{item.time}</p>
            {item.status === "incomplete" ? (
              <>
                <a
                  href={item.link}
                  className="w-auto btn btn-primary btn-sm rounded-pill"
                >
                  Join Meeting <ChevronRightOutlined />{" "}
                </a>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const reload = (prop)=>({
  value:prop.modalHandler
})

export default connect(reload) (TeamMeetingItem);
