// TeamList.jsx
import React, { useContext, useEffect, useState } from 'react';
import { useTeamData } from '../../actions/team/TeamContext';
import TeamItem from './TeamItem';
import TeamWorkSubmissions from './TeamWorkSubmissions';
import { useActionsContext } from '../../context/ActionsContext';
import ENDPOINT from '../../context/EndPoint';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import SecurityHeaders from '../../context/SecurityHeaders';


const TeamWorkSubmissionsPage = ({options}) => {
  const [internstasks,setinternstasks ] = useState(null)
  const {user} = useContext(AuthContext)
  const fetchAllInternsTasks = async ()=>{
          
  
    const formData = new FormData();
    
    
    //formData.append('whyus',document.getElementById('whyus').value)
    
    formData.append('session',user.SessionId);
    if(options?.status ==='pending'){
      formData.append('status',options.status)      
      
    }
    if(options?.limit){
      formData.append('limit',options.limit)  
    }
    
    
    const res = await axios.post(ENDPOINT+"admin-get-tasks.php", formData,SecurityHeaders);
    if(res){
    
   setinternstasks(res.data)
    }
  }
  
useEffect(()=>{
 fetchAllInternsTasks()
 // console.log("data ",internstasks)
},[])
  // Use teamData to render team list

  return (
    <div>
    
    {internstasks&&internstasks.data?
    <>
    
    <TeamWorkSubmissions options={options} Reload={fetchAllInternsTasks} tasks={internstasks.data} />
    </>:
    <>
    
    </>}
   
    </div>
  );
};

export default TeamWorkSubmissionsPage;
