import React, { useContext, useEffect, useState } from "react";
import Avatar from '@mui/material/Avatar';
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import ENDPOINT from "../../context/EndPoint";
import SecurityHeaders from "../../context/SecurityHeaders";
import EmployeeLeaderboard from "../team/EmployeeLeaderboard";
import TeamWorkSubmissionsPage from "../team/TeamWorkSubmissionsPage";
import { Link } from "react-router-dom";
export const convertMinutesToTime = (minutes) => {
  const days = Math.floor(minutes / 1440);
  const hours = Math.floor((minutes % 1440) / 60);
  const mins = Math.floor(minutes % 60);
  return `${days}d  ${hours}h  ${mins}m`;
};
const HRManagerDashboard = () => {

  const {user} = useContext(AuthContext);
  const [statistics,setStatistics] = useState('')

  const GetStatistics = async ()=>{
    try{
      const data = new FormData();
      data.append('session',user.SessionId);

const res = await axios.post(ENDPOINT+"hr-dashboard.php",data,SecurityHeaders);
if(res){
  if(res.data.status === "success"){
    setStatistics(res.data)
  }
}

    }
    catch(err){
       // console.log(err.message);
    }
  }

  useEffect(() => {
    GetStatistics();
  }, []);

  const [internsTasks,setInternsTasks ] = useState(null)

  const fetchAllInternsTasks = async ()=>{
          
  
    const formData = new FormData();
    
    
    //formData.append('whyus',document.getElementById('whyus').value)
    
    formData.append('session',user.SessionId);

    
    const res = await axios.post(ENDPOINT+"admin-get-tasks.php", formData,SecurityHeaders);
    if(res){
    
   setInternsTasks(res.data.stats)
    }
  }
  
useEffect(()=>{
 fetchAllInternsTasks()

},[])
 

  return (
    <>

      <section className="container-fluid">
        <div className="row">
          <div className="col-lg-6">



          <div className='border border-1 rounded mb-3 p-2 text-uppercase'>
              <h4><b>Users</b></h4>
              <div className="row">
                <div className='col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-wrap bdr'>


                  <div className='col-lg-6 col-6 p-1 '>

                    <div className=' py-2 text-center border border-1 rounded bg-white'>
                
                      <h4 className='m-0 mt-3'><b>{statistics?.doubts?.total_doubts}</b></h4>

                      <p className='txt m-0 p-0'>Employees</p>
                    </div>
                  </div>

                  <div className='col-lg-6 col-6 p-1 '>

<div className='p-2 text-center border border-1 rounded bg-white'>

  <h6 className='m-0 mt-3'><b>{convertMinutesToTime(statistics?.doubts?.average_initiated_time_minutes)} </b></h6>

  <p className='txt m-0 p-0 mt-2'>Interns</p>
</div>
</div>
                  <div className='col-lg-6 col-6 p-1 '>

                    <div className='p-2 text-center border border-1 rounded bg-white'>
             
                      <h4 className='m-0 mt-3'><b>{statistics?.doubts?.total_pending}</b></h4>

                      <p className='txt m-0 p-0'>Students</p>
                    </div>
                  </div>
                  <div className='col-lg-6 col-6 p-1 '>

                    <div className='p-2 text-center border border-1 rounded bg-white'>
       
                      <h4 className='m-0 mt-3'><b>{statistics?.doubts?.total_closed} </b></h4>

                      <p className='txt m-0 p-0'>Users</p>
                    </div>
                  </div>
                 

                </div>

                <div className='col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-wrap '>
                  <div className='col-lg-6 col-6 p-1 '>
                    <div className='py-2 text-center border border-1 rounded bg-white'>
                
                      <h4 className='m-0 mt-3'><b>{statistics?.contacts?.total_contacts}</b></h4>
                      <p className='txt m-0 p-0'>Peding Leads</p>
                    </div>
                  </div>

                  <div className='col-lg-6 col-6 p-1 '>
                    <div className='p-2 text-center border border-1 rounded bg-white'>
              
                      <h6 className='m-0 mt-3'><b>{convertMinutesToTime(statistics?.contacts?.average_initiated_time_minutes)}</b></h6>
                      <p className='txt m-0 p-0 mt-2'>Total Leads</p>
                    </div>
                  </div>
                  <div className='col-lg-6 col-6 p-1 '>
                    <div className='p-2 text-center border border-1 rounded bg-white'>
                
                      <h4 className='m-0 mt-3'><b>{statistics?.contacts?.total_pending}</b></h4>
                      <p className='txt m-0 p-0'>Today Leads</p>
                    </div>
                  </div>
                  <div className='col-lg-6 col-6 p-1 '>
                    <div className='p-2 text-center border border-1 rounded bg-white'>
            
                      <h4 className='m-0 mt-3'><b>{statistics?.contacts?.total_closed}</b></h4>
                      <p className='txt m-0 p-0'>This Month</p>
                    </div>
                  </div>
                

                </div>
              </div>


            </div>












       
          </div>

          <div className='col-lg-6'>

            <div className='border border-1 rounded mb-3 p-2 text-uppercase'>
              <h4><b>Tickets</b></h4>
              <div className="row">
                <div className='col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-wrap bdr'>


                  <div className='col-lg-6 col-6 p-1 '>

                    <div className=' py-2 text-center border border-1 rounded bg-white'>
                
                      <h4 className='m-0 mt-3'><b>{statistics?.doubts?.total_doubts}</b></h4>

                      <p className='txt m-0 p-0'>Customers</p>
                    </div>
                  </div>

                  <div className='col-lg-6 col-6 p-1 '>

<div className='p-2 text-center border border-1 rounded bg-white'>

  <h6 className='m-0 mt-3'><b>{convertMinutesToTime(statistics?.doubts?.average_initiated_time_minutes)} </b></h6>

  <p className='txt m-0 p-0 mt-2'>Average</p>
</div>
</div>
                  <div className='col-lg-6 col-6 p-1 '>

                    <div className='p-2 text-center border border-1 rounded bg-white'>
             
                      <h4 className='m-0 mt-3'><b>{statistics?.doubts?.total_pending}</b></h4>

                      <p className='txt m-0 p-0'>Pending</p>
                    </div>
                  </div>
                  <div className='col-lg-6 col-6 p-1 '>

                    <div className='p-2 text-center border border-1 rounded bg-white'>
       
                      <h4 className='m-0 mt-3'><b>{statistics?.doubts?.total_closed} </b></h4>

                      <p className='txt m-0 p-0'>Completed</p>
                    </div>
                  </div>
                 

                </div>

                <div className='col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-wrap '>
                  <div className='col-lg-6 col-6 p-1 '>
                    <div className='py-2 text-center border border-1 rounded bg-white'>
                
                      <h4 className='m-0 mt-3'><b>{statistics?.contacts?.total_contacts}</b></h4>
                      <p className='txt m-0 p-0'>Students</p>
                    </div>
                  </div>

                  <div className='col-lg-6 col-6 p-1 '>
                    <div className='p-2 text-center border border-1 rounded bg-white'>
              
                      <h6 className='m-0 mt-3'><b>{convertMinutesToTime(statistics?.contacts?.average_initiated_time_minutes)}</b></h6>
                      <p className='txt m-0 p-0 mt-2'>Average</p>
                    </div>
                  </div>
                  <div className='col-lg-6 col-6 p-1 '>
                    <div className='p-2 text-center border border-1 rounded bg-white'>
                
                      <h4 className='m-0 mt-3'><b>{statistics?.contacts?.total_pending}</b></h4>
                      <p className='txt m-0 p-0'>PENDING</p>
                    </div>
                  </div>
                  <div className='col-lg-6 col-6 p-1 '>
                    <div className='p-2 text-center border border-1 rounded bg-white'>
            
                      <h4 className='m-0 mt-3'><b>{statistics?.contacts?.total_closed}</b></h4>
                      <p className='txt m-0 p-0'>Completed</p>
                    </div>
                  </div>
                

                </div>
              </div>


            </div>
          </div>
        </div>
      </section>








      <section className='container-fluid d-flex flex-wrap'>
      
        <div className='col-lg-5 col-md-12 col-12 my-3 text-uppercase'>
         <div className="d-flex justify-content-between">
         <h4><b>Project Overview</b></h4>

         </div>
          <div className='d-flex'>
            <div className='col-lg-4 col-md-4 col-4 p-2'>
              <div className='p-3 shadow rounded text-center text-bg'>
                <h4 className='m-0 mt-3 '>{internsTasks?.total}</h4>
                <h6 className='txt m-0 p-0 mt-1 text-dark'>Total Works</h6>
              </div>
            </div>

            <div className='col-lg-4 col-md-4 col-4 p-2 '>
              <div className='p-3 shadow text-center rounded text-bg'>
                <h4 className='m-0 mt-3'>{internsTasks?.pending} </h4>
                <h6 className='txt m-0 p-0 mt-1 text-dark'>Complete Works</h6>
              </div>
            </div>
            <div className='col-lg-4 col-md-4 col-4 p-2 '>
              <div className='p-3 text-center rounded text-bg shadow'>
                <h4 className='m-0 mt-3'>{internsTasks?.submitted} </h4>
                <h6 className='txt m-0 p-0 mt-1 text-dark' >Pending Works</h6>
              </div>
            </div>
          </div>

          <div className='text-uppercase mt-3'>
<div className="d-flex justify-content-between">
<h4><b>Pending Tasks</b></h4>
<Link className="text-decoration-none text-primary" to={"/team-task-submissions"}><b>View All Tasks</b></Link>
</div>
    <TeamWorkSubmissionsPage options={{status:'pending',limit:5,search:false,title:false,assigned:false,assigned_by:false,submissions:false}}/>
          </div>

        </div>
        <div className='col-lg-7 col-md-12 col-12 my-3 pe-3'>
    <EmployeeLeaderboard/>

        </div>
      </section>




          
    </>
  );
};

export default HRManagerDashboard;
