import React, { useContext, useEffect, useState } from 'react'
import { Link, unstable_HistoryRouter } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'

import SpeechRecognition, {
    useSpeechRecognition,
  } from "react-speech-recognition";
import { useActionsContext } from '../context/ActionsContext'
import LogoutUser from '../actions/user/LogoutUser'
import { connect } from "react-redux";
import logo from '../assets/images/logo-main.png'
import { Mic, Search, WhatsApp } from '@material-ui/icons'
import DefaultToast from './toasts/DefaultToast'
import { GraphUp } from 'react-bootstrap-icons';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DetectDevice from '../context/DetectDevice';
const Navbar = ({account,value,dispatch}) => {
   




const {user} = useContext(AuthContext)
const { transcript, listening } = useSpeechRecognition();

const {notifications,unread,setvoiceinput,togglesidebar,settogglesidebar,hearing,sethearing,enablestats,setenablestats} = useActionsContext()

const [loading,setloading] = useState(false)
const Logout = async ()=>{

setloading(true)

    const res= await LogoutUser({payload:{
        session:user.SessionId
    }})

    if(res){
setloading(false)

    }
}

const [isToggled, setIsToggled] = useState(value.sendNotification.notification);

const handleChange = () => {
  setIsToggled(!isToggled);



  if(isToggled) {
    settoastdata({title: "Whatsapp Disabled",message:"Notification wont be sent to students "})
    
  }else{
    settoastdata({title: "Whatsapp Enabled",message:"Notification will be sent to students "})
  }
settoast(true)


  dispatch({ type: 'SEND', payload:!isToggled  });
}

const [toastdata,settoastdata] = useState(null)
const [toast,settoast] = useState(false)


useEffect(()=>{

var input  = transcript
    while(input.includes(" ")){
        input = input.replace(" ",'')

    }
    input = input.toLowerCase()
    setvoiceinput(input)

var input  = transcript.toLowerCase()

if(input.includes("payment")){
   window.location.replace("/payments")
}
if(input.includes("class")){
    window.location.replace("/classes")
 }
 if(input.includes("team")){
    window.location.replace("/team")
 }
 if(input.includes("course")){
    window.location.replace("/courses")
 }
 if(input.includes("student")){
    window.location.replace("/students")
 }
 if(input.includes("user")){
    window.location.replace("/users")
 }
 if(input.includes("batch")){
    window.location.replace("/active-batches")
 }


if(listening){
    sethearing(listening)
}else{
    sethearing(listening)
}
var input = transcript.toLowerCase()
input = input.replace(" ",'')
if(input.includes("disablewhatsapp")){
    setIsToggled(false)
    settoast(true)
}
else if(input.includes("enablewhatsapp")){
    setIsToggled(true)
    settoast(true)
}
if(isToggled) {
    settoastdata({title: "Whatsapp Enabled",message:"Notification will be sent to students "})

  }else{
    settoastdata({title: "Whatsapp Disabled",message:"Notification wont be sent to students "})
   
     }

    },[transcript])


    setTimeout(function() {
   if(listening){

   }else{
  if(turnon){
    SpeechRecognition.startListening();
  }
   }  // Schedule the function to execute again in 5 seconds
     }, 1000);


   const [stats,setstats] = useState(value.statisticsHandler.statistics)
const [turnon,setTurnon] = useState(value.micHandler.mic)

const statisticsHandler = ()=>{
    setstats(!stats)
    
    dispatch({ type:'STATISTICS_UPDATE', payload:!stats  });
}

const micHandler = () => {
    setTurnon(!turnon);
  
  
  
    if(turnon) {
      settoastdata({title: "Mic Disabled",message:"Mic is turn off"})
      
    }else{
      settoastdata({title: "Mic Enabled",message:"Mic is turn on "})
    }
  settoast(true)
  
  
    dispatch({ type:'MIC_HANDEL', payload:!turnon  });
  }
  
const device = DetectDevice()
    return (
   <>


   <div className="toast-container">
    <DefaultToast show={toast} toastdata={toastdata} ToastHandleClose={()=>settoast(false)} />
   </div>
   
   {account?
   <>
 <>
    <header id="page-topbar" className={togglesidebar?"":'left-0'}>
<div class="layout-width">


<div class="navbar-header">

    <div class="d-flex align-items-center">
     
    {!togglesidebar&&device!=="mobile"?   <Link to="/">
        <img className='' style={{ height:40}} src={logo}>
            </img></Link>:null}

        <button type="button" onClick={()=>settogglesidebar(!togglesidebar)} class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger material-shadow-none" id="topnav-hamburger-icon">
            <span class="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
            </span>
        </button>

        
        <form class="app-search d-none">
            <div class="position-relative d-flex">
        <Search/>
                <input type="text" class="form-control" placeholder={transcript?<><Mic/> {transcript}</>:"Search..."} autocomplete="off" id="search-options" value=""/>
              
                <span class="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none" id="search-close-options"></span>
            </div>
            <div class="dropdown-menu dropdown-menu-lg" id="search-dropdown">
                <div data-simplebar style={{maxheight: 450}}>
                    <div class="dropdown-header">
                        <h6 class="text-overflow text-muted mb-0 text-uppercase">Recent Searches</h6>
                    </div>

                    <div class="dropdown-item bg-transparent text-wrap">
                        <Link to="index.html" class="btn btn-soft-secondary btn-sm rounded-pill">how to setup <i class="mdi mdi-magnify ms-1"></i></Link>
                        <Link to="index.html" class="btn btn-soft-secondary btn-sm rounded-pill">buttons <i class="mdi mdi-magnify ms-1"></i></Link>
                    </div>
                    {/*item*/}
                    <div class="dropdown-header mt-2">
                        <h6 class="text-overflow text-muted mb-1 text-uppercase">Pages</h6>
                    </div>

                    {/*item*/}
                    <Link to="javascript:void(0);" class="dropdown-item notify-item">
                        <i class="ri-bubble-chart-line align-middle fs-18 text-muted me-2"></i>
                        <span>Analytics Dashboard</span>
                    </Link>

                    {/*item*/}
                    <Link to="javascript:void(0);" class="dropdown-item notify-item">
                        <i class="ri-lifebuoy-line align-middle fs-18 text-muted me-2"></i>
                        <span>Help Center</span>
                    </Link>

                    {/*item*/}
                    <Link to="javascript:void(0);" class="dropdown-item notify-item">
                        <i class="ri-user-settings-line align-middle fs-18 text-muted me-2"></i>
                        <span>My account settings</span>
                    </Link>

                    {/*item*/}
                    <div class="dropdown-header mt-2">
                        <h6 class="text-overflow text-muted mb-2 text-uppercase">Members</h6>
                    </div>

                    <div class="notification-list">
                        {/*item*/}
                        <Link to="javascript:void(0);" class="dropdown-item notify-item py-2">
                            <div class="d-flex">
                                <img src="assets/images/users/avatar-2.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic"/>
                                <div class="flex-grow-1">
                                    <h6 class="m-0">Angela Bernier</h6>
                                    <span class="fs-11 mb-0 text-muted">Manager</span>
                                </div>
                            </div>
                        </Link>
                        {/*item*/}
                        <Link to="javascript:void(0);" class="dropdown-item notify-item py-2">
                            <div class="d-flex">
                                <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic"/>
                                <div class="flex-grow-1">
                                    <h6 class="m-0">David Grasso</h6>
                                    <span class="fs-11 mb-0 text-muted">Web Designer</span>
                                </div>
                            </div>
                        </Link>
                        {/*item*/}
                        <Link to="javascript:void(0);" class="dropdown-item notify-item py-2">
                            <div class="d-flex">
                                <img src="assets/images/users/avatar-5.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic"/>
                                <div class="flex-grow-1">
                                    <h6 class="m-0">Mike Bunch</h6>
                                    <span class="fs-11 mb-0 text-muted">React Developer</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>

                <div class="text-center pt-3 pb-1">
                    <Link to="pages-search-results.html" class="btn btn-primary btn-sm">View All Results <i class="ri-arrow-right-line ms-1"></i></Link>
                </div>
            </div>
        </form>


    </div>

    <div class="d-flex align-items-center">

    {account?
    <>
     {account?
    <>

{/* 
<AssessmentIcon onClick={()=>{statisticsHandler()}} className={stats?"text-success me-3 click":"text-danger me-3 click"}/>
 */}



    <Mic onClick={()=>{micHandler();SpeechRecognition.startListening()}}    className={`${turnon ? 'text-success me-3 click' : 'text-danger me-3 click'}`}/>
    




  
    <WhatsApp onClick={(e) => { handleChange(); e.preventDefault() }}  className={`${isToggled ? 'text-success click' : 'text-danger click'} rounded me-2`}/>

    </>:
    <>
    </>}

    </>:
    <>
    </>}
        {/* <div class="dropdown d-md-none topbar-head-dropdown header-item">
            <button type="button" class="btn btn-icon btn-topbar material-shadow-none btn-ghost-secondary rounded-circle" id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="bx bx-search fs-22"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-search-dropdown">
                <form class="p-3">
                    <div class="form-group m-0">
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder={transcript?transcript:"Search"} aria-label="Recipient's username"/>
                            <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                        </div>
                    </div>
                </form>
            </div>
        </div> */}

      

        <div class="dropdown topbar-head-dropdown ms-1 header-item">
            <button type="button" class="btn btn-icon btn-topbar material-shadow-none btn-ghost-secondary rounded-circle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class='bx bx-category-alt fs-22'></i>
            </button>
            <div class="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
                <div class="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                    <div class="row align-items-center">
                        <div class="col">
                            <h6 class="m-0 fw-semibold fs-15"> Quick Actions </h6>
                        </div>
                        <div class="col-auto">
                            <Link to="#!" class="btn btn-sm btn-soft-info"> View All
                                <i class="ri-arrow-right-s-line align-middle"></i></Link>
                        </div>
                    </div>
                </div>

                <div class="p-2">
                    <div class="row g-0">
                        <div class="col">
                            <Link class="dropdown-icon-item" to="/students">
                                <img src="assets/images/brands/github.png" alt="Github"/>
                                <span>Students</span>
                            </Link>
                        </div>
                        <div class="col">
                            <Link class="dropdown-icon-item" to="/team">
                                <img src="assets/images/brands/bitbucket.png" alt="bitbucket"/>
                                <span>Team</span>
                            </Link>
                        </div>
                        <div class="col">
                            <Link class="dropdown-icon-item" to="/courses">
                                <img src="assets/images/brands/dribbble.png" alt="dribbble"/>
                                <span>Courses</span>
                            </Link>
                        </div>
                    </div>

                    <div class="row g-0">
                        <div class="col">
                            <Link class="dropdown-icon-item" to="/students-support">
                                <img src="assets/images/brands/dropbox.png" alt="dropbox"/>
                                <span>Doubts</span>
                            </Link>
                        </div>
                        <div class="col">
                            <Link class="dropdown-icon-item" to="/customer-support">
                                <img src="assets/images/brands/mail_chimp.png" alt="mail_chimp"/>
                                <span>Customer Support</span>
                            </Link>
                        </div>
                        <div class="col">
                            <Link class="dropdown-icon-item" to="/expenses">
                                <img src="assets/images/brands/slack.png" alt="slack"/>
                                <span>Finances</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      

      

    <Link to="/notifications" type="button" class="btn btn-icon btn-topbar material-shadow-none btn-ghost-secondary rounded-circle" >
                <i class='bx bx-bell fs-22'></i>
                <span class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{unread?unread:null}<span class="visually-hidden">unread messages</span></span>
            </Link>
    

        <div class="dropdown ms-sm-3 header-item topbar-user">
            <button type="button" class="btn material-shadow-none" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="d-flex align-items-center">
                    <img class="rounded-circle header-profile-user" src={account.profile_pic} alt="Header Avatar"/>
                    <span class="text-start ms-xl-2">
                        <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{account.first_name+" "+account.last_name}</span>
                        {/* <span class="d-none d-xl-block ms-1 fs-12 user-name-sub-text">Founder</span> */}
                    </span>
                </span>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
                {/*item*/}
                <h6 class="dropdown-header">Welcome {account.first_name+" "+account.last_name}!</h6>
                {/* <Link class="dropdown-item" to="pages-profile.html"><i class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span class="align-middle">Profile</span></Link>
                <Link class="dropdown-item" to="apps-chat.html"><i class="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i> <span class="align-middle">Messages</span></Link>
                <Link class="dropdown-item" to="apps-tasks-kanban.html"><i class="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i> <span class="align-middle">Taskboard</span></Link>
                <Link class="dropdown-item" to="pages-faqs.html"><i class="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span class="align-middle">Help</span></Link>
                <div class="dropdown-divider"></div>
                <Link class="dropdown-item" to="pages-profile.html"><i class="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i> <span class="align-middle">Balance : <b>$5971.67</b></span></Link>
                <Link class="dropdown-item" to="pages-profile-settings.html"><span class="badge bg-success-subtle text-success mt-1 float-end">New</span><i class="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span class="align-middle">Settings</span></Link>
                <Link class="dropdown-item" to="auth-lockscreen-basic.html"><i class="mdi mdi-lock text-muted fs-16 align-middle me-1"></i> <span class="align-middle">Lock screen</span></Link> */}
                <Link class="dropdown-item" onClick={()=>Logout()}><i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span class="align-middle" data-key="t-logout">Logout</span></Link>
            </div>
        </div>
    </div>

  


</div>

</div>
</header>
   
    </>
   
   </>:
   <>
   
   
   </>}
   
   </>
  )
}

const Notification = (prop)=>({

    value :prop
  
  
  })

export default connect(Notification) (Navbar)