import React, { useEffect, useState } from 'react'
import DemoData from '../../data/DemoData'
import CourseItem from './CourseItem'
import { Link, useParams } from 'react-router-dom';
import ENDPOINT from '../../context/EndPoint';

const CoursesList = () => {


  
  const params = useParams();
  const [mode, setmode] = useState("all");

  const [show, setShow] = useState(false);
  const [currentbatch, setcurrentbatch] = useState(null);
  const [courseurl, setcourseurl] = useState(null);
  const handleClose = () => setShow(false);
  const addAssignment = (course_url, batch_id) => {
    setcourseurl(course_url);
    setcurrentbatch(batch_id);
    setShow(true);
  };
  const [studentslist, setstudentslist] = useState(false);

  const [courses, setcourses] = useState(null);

  useEffect(() => {
    getcourses({ cat: "all" });
  }, [mode]);

  const getcourses = async ({ cat, query }) => {
    var LINK = null;
    if (cat) {
      LINK = `${ENDPOINT}admin-get-courses.php?mode=${cat}`;
    } else {
      LINK = `${ENDPOINT}admin-get-courses.php?mode=all`;
    }
    fetch(LINK)
      .then((r) => r.json())
      .then((result) => {
        setcourses(result)
        setInitialData(result);
        setResponse(result);
      });
  };

  const values = [true, "sm-down", "md-down", "lg-down", "xl-down", "xxl-down"];
  const [fullscreen, setFullscreen] = useState(true);
  const [currentcourse, setcurrentcourse] = useState(null);
  const displayStudents = (course) => {
    setcurrentcourse(course);
    setstudentslist(true);
  };

  const [editoverview, seteditoverview] = useState(false);

  const [courseid, setcourseid] = useState(null);

  const editCourseOverview = ({ courseurl, course_batch, course_id }) => {
    setcurrentbatch(course_batch);
    setcourseurl(courseurl);
    setcourseid(course_id);
    seteditoverview(true);
  };

  const [query, setquery] = useState(null);
  const [category, setcategory] = useState("all");

  const [initialData, setInitialData] = useState("");

  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return (
        item.course_id.toLowerCase().includes(term) ||
        item.course_title.toLowerCase().includes(term) ||
        item.mode.toLowerCase().includes(term)
      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };

  return (
   <>
   
   <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Course List</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">Course</a>
                    </li>
                    <li className="breadcrumb-item active">Course List</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row g-4 mb-3">
            <div className="col-sm-auto">
              <div>
                <Link to="/add-course" className="btn btn-success">
                  <i className="ri-add-line align-bottom me-1" /> Add New Course
                </Link>
              </div>
            </div>
            <div className="col-sm">
              <div className="d-flex justify-content-sm-end align-items-center gap-3">
                <div className="search-box ms-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    onChange={handleSearch}

                  />
                  <i className="ri-search-line search-icon" />
                </div>
          <div className='col-1 '>
          <select
                      className="form-control w-md choices__input p-2 "
                      data-choices=""
                      data-choices-search-false=""
                      hidden=""
                      tabIndex={-1}
                      data-choice="active"
                      onChange={handleSearch}
                    >
                       <option
                        value="live"
                        data-custom-properties="[object Object]"
                      >
              Course Mode
                      </option>
                      <option
                        value="live"
                        data-custom-properties="[object Object]"
                      >
              Live
                      </option>
                      <option
                        value="offline"
                        data-custom-properties="[object Object]"
                      >
                      Offline
                      </option>
                      <option
                        value="specialization"
                        data-custom-properties="[object Object]"
                      >
                      Specialization
                      </option> 
                      <option
                        value="selfpaced"
                        data-custom-properties="[object Object]"
                      >
                     selfpaced
                      </option>
                      <option
                        value="free"
                        data-custom-properties="[object Object]"
                      >
                     Free
                      </option>
                    </select>
          </div>
              </div>
            </div>
          </div>
          <div className="row">
  


{response?
<>
{response.map((item)=>(

<>
<div className="col-xxl-4 col-sm-6 project-card">
       <CourseItem item={item}/>
       {/* end card */}
     </div>
</>
))}

</>:
<>


</>}
          </div>
          {/* end row */}
      
        </div>

   </>
  )
}

export default CoursesList