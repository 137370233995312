import React, { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import axios from 'axios'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { AuthContext } from '../../context/AuthContext'

import { Dropdown, Modal } from 'react-bootstrap'
import { Add, MoreVert } from '@material-ui/icons'

import ENDPOINT from '../../context/EndPoint'
import TeamMeetingItem from './TeamMeetingItem'

const TeamMeetings = ({ page,role}) => {

const {user} = useContext(AuthContext)


const [edit,setedit] = useState(false);


const dateselector = useRef();
const tasktype= useRef()

const changeTaskStatus = (e)=>{
 
  settaskstatus(tasktype.current.value);
  getTasks(tasktype.current.value);

}

useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


    const [tasks,settasks] = useState("start");
const [category,setcategory] = useState("all")

const [taskstatus,settaskstatus]= useState("all")
    const[meeting,setMeeting]=useState(null)
    const [taskid,settaskid] = useState(null)
    const [remove,setremove] = useState(false)
    const [removeid,setremoveid] = useState(null);
    const [submit,setsubmit] = useState(false);

const showSubmit =(e)=>{
  const id= e.target.id;
settaskid((id.replace("submit","")));
setsubmit(true)
}


    const closeSubmit = ()=>{
      setsubmit(false);
    }
    const editTask = (e)=>{

const id= e.target.id;
settaskid((id.replace("edit","")));

setedit(true)
    }

const closeEdit = ()=>{
  setedit(false)

}
const [add,setadd] = useState(false)

const closeAddition =()=>{
  setadd(false)
  getTasks(taskstatus);
}

const showRemove = (e)=>{
    
  const id= e.target.id;
  
settaskid(id.replace("delete",""));
  setremove(true)


}
const closeRemove = ()=>{
  setremove(false)
}




useEffect(() => {
 getTasks(taskstatus)
}, [])





const getTasks = async (status)=>{
        

const formData = new FormData();


//formData.append('whyus',document.getElementById('whyus').value)

formData.append('session',user.SessionId);


  formData.append('status',status);


const res = await axios.post(ENDPOINT+"admin-meetings.php", formData,{
    headers: {
        'content-type': 'multipart/form-data'
    }
});
if(res){

  setdate(null)

 if(res.data.status==="success"){

  setResponse(res.data.data)
  setInitialData(res.data.data)
  setMeeting(res.data)
 }
 else{

 }
}
 // console.log(meeting?meeting.sucess_meeting:null)
}
const [date,setdate]= useState(null);
const changeDate = (e)=>{
setdate(dateselector.current.value);
getTasks();
}

//delete task code

const deleteTask = async ()=>{

const formData = new FormData();
formData.append('session',user.SessionId);
formData.append('id',taskid);

const res = await axios.post(ENDPOINT+"admin-delete-task.php",formData,{
  headers:{
    'content-type': 'multipart/form-data'
  }
});


if(res.data[0].status==="success"){
  getTasks()
  closeRemove();
}
else{

}
}


const [initialData,setInitialData] = useState(null)


const [response, setResponse] = useState(initialData);
const [searchTerm, setSearchTerm] = useState('');

const handleSearch = (e) => {
const term = e.target.value.toLowerCase();

const filteredData = initialData.filter((item) => {
  return (
    item.title.toLowerCase().includes(term) ||
    item.description.toLowerCase().includes(term) ||
    item.starts.includes(term) ||
    item.status.toLowerCase().includes(term)
  );
});

setResponse(filteredData);
setSearchTerm(term);
};
  

 // console.log(response)

    return (
        <>


           
<div className="tasks-page">
<div className="row">
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                <i className="ri-arrow-down-circle-fill align-middle" />
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                Total-meeting{" "}
                              </p>
                              <h4 className=" mb-0">
                                
                                <span
                                  className="counter-value"
                                  data-target="14799.44"
                                >
                                 {meeting?meeting.total_meeting.total_meeting:null}
                                </span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0 align-self-end">
                              <span className="badge bg-danger-subtle text-danger">
                                <i className="ri-arrow-down-s-fill align-middle me-1" />
                                4.80 %<span> </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}

                    {/* end col */}
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                <i className="ri-arrow-down-circle-fill align-middle" />
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                Success-meeting{" "}
                              </p>
                              <h4 className=" mb-0">
                                
                                <span
                                  className="counter-value"
                                  data-target="14799.44"
                                >
                                  {meeting?meeting.sucess_meeting.sucess_meeting:null}
                                </span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0 align-self-end">
                              <span className="badge bg-danger-subtle text-danger">
                                <i className="ri-arrow-down-s-fill align-middle me-1" />
                                4.80 %<span> </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}

                    {/* end col */}
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                <i className="ri-arrow-down-circle-fill align-middle" />
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                             Pending-meeting {" "}
                              </p>
                              <h4 className=" mb-0">
                                
                                <span
                                  className="counter-value"
                                  data-target="14799.44"
                                >
                                {meeting?meeting.pending_meeting.pending_meeting:null}
                                </span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0 align-self-end">
                              <span className="badge bg-danger-subtle text-danger">
                                <i className="ri-arrow-down-s-fill align-middle me-1" />
                                4.80 %<span> </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}

                    {/* end col */}
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                <i className="ri-arrow-down-circle-fill align-middle" />
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                specialization students{" "}
                              </p>
                              <h4 className=" mb-0">
                                
                                <span
                                  className="counter-value"
                                  data-target="14799.44"
                                >
                                  {}
                                </span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0 align-self-end">
                              <span className="badge bg-danger-subtle text-danger">
                                <i className="ri-arrow-down-s-fill align-middle me-1" />
                                4.80 %<span> </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}
                  </div>
   

<div className="d-flex align-items-center mb-4">
                          <h5 className="card-title flex-grow-1 mb-0">
                          All Meetings
                          </h5>
                          <div className="card-body border border-dashed border-end-0 border-start-0">
                  <form>
                    <div className="row g-3">
                      <div className="col-xxl-5 col-sm-6">
                        <div className="search-box">
                          <input
                          onChange={handleSearch}
                            type="text"
                            className="form-control search"
                            placeholder="Search for class title, description, course..."
                          />
                          <i className="ri-search-line search-icon" />
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-2 col-sm-6">
                        <div>
                          <input
                            type="date"
                            onChange={handleSearch}
                            className="form-control"
                            data-provider="flatpickr"
                            data-date-format="d M, Y"
                            data-range-date="true"
                            id="demo-datepicker"
                            placeholder="Select date"
                          />
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-2 col-sm-4">
                        <div>
                          <select
                            className="form-control"
                            data-choices=""
                            data-choices-search-false=""
                            name="choices-single-default"
                            id="idStatus"
                            onChange={handleSearch}
                          >
                            <option value="">Status</option>
                          
                            <option value="incomplete">Incomplete</option>
                            <option value="completed">Completed</option>
                          
                          </select>
                        </div>
                      </div>
                   
                      <div className="col-xxl-1 col-sm-4">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary h-fit"
                           
                          >
                            
                            <i className="ri-equalizer-fill me-1 align-bottom" />
                            Filters
                          </button>
                        </div>
                      </div>
                      {/*end col*/}
                    </div>
                    {/*end row*/}
                  </form>
                </div>
                        </div>
<div className="container-fluid space-between w-100">

<h4  className="sm-18 mb-0"><b className="mr-2">{tasks?tasks.length:0}</b>{taskstatus==="all"?"Total":taskstatus==="upcoming"?"Upcoming":"Completed "} Meetings </h4>


{/*<div className="flex">
  <input placeholder="Date" type="date" id="dateselector" ref={dateselector} onChange={changeDate}/>
  
    </div>*/}



<div className="flex">

<select className="sm-14" ref={tasktype} onChange={changeTaskStatus}>
  <option value="all">All</option>
  <option value="upcoming">Upcoming</option>
  <option value="completed">Completed</option>
 
  </select>
  {role==="admin"||role==="manager"||role==="leader" || role==="hr"||role==="bde"?
<>
<Dropdown className="options-dropdown ml-4">   
  <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
  <Add className="text-white bg-primary"/>
  </Dropdown.Toggle>
  <Dropdown.Menu>
  <Dropdown.Item href="#"   onClick={()=>{setadd(true)}}>Schedule New Meeting</Dropdown.Item>
   </Dropdown.Menu>
</Dropdown>

</>
:null

}

</div>
</div>

<hr/>
<div className="internships-list  sm-p-0  container-fluid">

{ response==="start"?
<div className="container-fluid d-flex flex-wrap">

<>Loadings</>

    </div>

:<div className="container-fluid d-flex flex-wrap">
  {response?response.map((internship,index)=>(

<>


<TeamMeetingItem item={internship}/>
</>
)):
<div className="container-fluid">

No tasks

    </div>}
</div>

}





</div>




</div>



<Modal show={edit} 
animation={false}
onHide={closeEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
     
        </Modal.Body>
      
      </Modal>

      <Modal show={submit} 
animation={false}
onHide={closeSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        </Modal.Body>
      
      </Modal>


      <Modal show={add} 
animation={false}
onHide={closeAddition}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
   
        </Modal.Body>
      
      </Modal>



      <Modal show={remove} 
animation={false}
onHide={closeRemove}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        <button className="btn btn-danger" onClick={deleteTask}>Delete</button>
        </Modal.Body>
      
      </Modal>






        </>
    )
}

export default TeamMeetings

