import Avatar from '@mui/material/Avatar';
import React, { useContext, useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import AdminDeleteTickets from '../../actions/student/AdminDeleteTickets';
import { Check, Delete, MoreVert } from '@material-ui/icons';
import DeleteModal from '../../components/modals/DeleteModal';
import AdminUpdateDoubtRequest from '../../actions/support/AdminUpdateDoubtRequest';
import { AuthContext } from '../../context/AuthContext';
import { Pending } from '@mui/icons-material';
import DefaultToast from '../../components/toasts/DefaultToast';
import dateFormat from 'dateformat';
import { useTeamData } from '../../actions/team/TeamContext';
import { useActionsContext } from '../../context/ActionsContext';
import AdminUpdateSupportRequest from '../../actions/support/AdminUpdateSuppportRequest';
import axios from 'axios';
import SecurityHeaders from '../../context/SecurityHeaders';
import ENDPOINT from '../../context/EndPoint';

const CustomerSupportTicketItem = ({item,Reload,options}) => {
const {user} = useContext(AuthContext)
const [deletemodal,setdeletemodal] = useState(false)
const [toastdata,setoastdata] = useState(null)
const [toast,settoast] = useState(false)


const {deleting,setdeleting} = useActionsContext()
 const DeleteSupportRequest = async ({payload}) => {
  const data = new FormData();
setdeleting(true)
  data.append('id',payload.id);

  const res = await axios.post(ENDPOINT+'admin-delete-support-request.php',data,SecurityHeaders);
  
  if(res){
    setdeleting(false)
      if(res.data.status==="success"){
Reload()
setdeletemodal(false)
      }
  }
}


 useEffect(()=>{

 },[])
  const UpdateRequest = async ({status})=>{


    const res = await AdminUpdateSupportRequest({payload:{
      session:user.SessionId,
      id:item.id,
      status:status
    }})
    if(res){

setoastdata(res.data)
if(res.status==="success"){

Reload()
}
else{


}

    }
  }
  return (
<>
<DefaultToast toastdata={toastdata} show={toast}/>
<DeleteModal deleting={deleting} show={deletemodal} HandleClose={()=>setdeletemodal(false)} HandleDelete={()=>DeleteSupportRequest({payload:{id:item.id}})}/>


<tr>
                
            {options.user===false?null:    <td className='col-lg-3'>
                  <div className="d-flex  align-items-center">
                    <div className="flex-shrink-0 me-2">
                    <Avatar src={item.profile_pic?item.profile_pic:null}/>
                    </div>
                    <div className="flex-grow-1 ">
                      <h5 className='m-0 p-0'>{item.name}</h5>
                      <p className='p-0 m-0'>{dateFormat(item.create_datetime,"dS mmm hh:mm tt")}</p>
                    </div>
                  </div>
                </td>}
          {options.contact===false?null:      <td><div>
                    <h6>{item.phone}</h6>
                    <p>{item.email}</p>
                    </div></td>}

                    <td><div>
           
                    <p>{item.message}</p>
                    </div></td>
                <td>
                  <span className="badge bg-success-subtle text-success">
                  {item.status}
                  </span>
                </td>
              
                <td>
                <Dropdown className="options-dropdown">   
  <Dropdown.Toggle className="bg-white options p-0 w-fit h-fit  border-0" >
  <MoreVert className="text-dark"/>
  </Dropdown.Toggle>
  <Dropdown.Menu>
    
  
  <Dropdown.Item onClick={()=>UpdateRequest({status:"initiated"})}  ><Pending/> Initiate</Dropdown.Item>

  <Dropdown.Item   onClick={()=>UpdateRequest({status:"closed"})} ><Check/>Closed</Dropdown.Item>
  
  <Dropdown.Item   onClick={()=>setdeletemodal(true)} ><Delete/>Delete</Dropdown.Item>
 </Dropdown.Menu>
</Dropdown>
                </td>
              {options.view===false?null:  <td>
                  <Link to={"/students-support/"+item.id} className="btn btn-outline-primary">View</Link>
                </td>}
              
             
              </tr>
</>
  )
}

export default CustomerSupportTicketItem