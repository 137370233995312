import React from 'react'
import CustomerSupportTicketItem from './CustomerTicketItem'
import { Link } from 'react-router-dom'


const CustomerTicketsList = ({data,handleSearch,Reload,options}) => {

     // console.log(options)
  return (
    <div>
        

        <div className="col-12 ">


<div className="card ">


<div className="card-header border-0 align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
           Customer Support Requests
          </h4>
{
  options?.view === false?<></>:
  <Link to='/customer-support' className='text-decoration-none text-primary h6'>VIEW ALL </Link>

}          <div>

      {options&&options.search===false?<></>:   <div className="d-flex">
         <div className="search-box ms-2 me-2">
                  <input
                    type="text"
                
                    onChange={handleSearch}
                    className="form-control"
                    placeholder="Search..."
                  />
                  <i className="ri-search-line search-icon" />
                </div>
            <button
              type="button"
              className="btn btn-soft-secondary btn-sm material-shadow-none me-1"
            >
              7D
            </button>
            <button
              type="button"
              className="btn btn-soft-secondary btn-sm material-shadow-none me-1"
            >
              1M
            </button>
            <button
              type="button"
              className="btn btn-soft-secondary btn-sm material-shadow-none me-1"
            >
              1Y
            </button>
            <button
              type="button"
              className="btn btn-soft-primary btn-sm material-shadow-none"
            >
              ALL
            </button>
         </div>}
          </div>
        </div>
        
        {/* end card header */}
        <div className="card-body">
          <div className="table-responsive table-card">
            <table className="table table-borderless table-centered align-middle mb-0">
              <thead className="text-muted table-light">
                <tr>
                
                {options?.userinfo===false?  null:<th scope="cqol">User</th>}
                  {options&&options.contact===false?<> </>:<th scope="cqol">Contact</th>}
                  {options&&options.message===false?<> </>:<th scope="cqol">message</th>}

                  
                 
                  <th scope="col">Status</th>
               {options&&options.actions===false?null:   <th scope="col">Actions</th>
                }
               {options&&options.view===false?null:   <th scope="col">View</th>
                  }
                
                </tr>
              </thead>
             <tbody>


             {data?<>
             
              {data.map((item)=>(
                <>
                
                <CustomerSupportTicketItem options={options} Reload={Reload} item={item}/>
                </>
              ))}

             </>:
             <>
             
             
             </>}
             </tbody>
              {/* end tbody */}
            </table>
            {/* end table */}
          </div>
        </div>
      </div>{" "}

</div>



    </div>
  )
}

export default CustomerTicketsList