import React, { useState } from 'react'
import BatchItem from './BatchItem'
import { OtherHouses } from '@mui/icons-material'
import { Link, useParams } from 'react-router-dom'

const CourseBatchesList = ({ batches, addbatch }) => {
  const params = useParams()
  const course_url = params.course_url
  const course_id = params.course_id

  
  const [initialData, setInitialData] = useState(batches);
  //  // console.log(batches)

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = initialData.filter(item => {
    // Filter by batch_id, course_url, or student name
    return (
      item.batch_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.course.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.student.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.course_status.toLowerCase().includes(searchTerm) ||
      item.mode.toLowerCase().includes(searchTerm)
    );
  });


  return (
    <>

      <div className="p-3">
        <div className="row d-none">
          <div className="col-lg-3 col-md-3 col-6">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                      <i className="ri-arrow-down-circle-fill align-middle" />
                    </span>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                      online batches{" "}
                    </p>
                    <h4 className=" mb-0">

                      <span
                        className="counter-value"
                        data-target="14799.44"
                      >
                        
                      </span>
                    </h4>
                  </div>
                  <div className="flex-shrink-0 align-self-end">
                    <span className="badge bg-danger-subtle text-danger">
                      <i className="ri-arrow-down-s-fill align-middle me-1" />
                      4.80 %<span> </span>
                    </span>
                  </div>
                </div>
              </div>
              {/* end card body */}
            </div>
            {/* end card */}
          </div>
          <div className="col-lg-3 col-md-3 col-6">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                      <i className="ri-arrow-down-circle-fill align-middle" />
                    </span>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                      payment success{" "}
                    </p>
                    <h4 className=" mb-0">

                      <span
                        className="counter-value"
                        data-target="14799.44"
                      >

                      </span>
                    </h4>
                  </div>
                  <div className="flex-shrink-0 align-self-end">
                    <span className="badge bg-danger-subtle text-danger">
                      <i className="ri-arrow-down-s-fill align-middle me-1" />
                      4.80 %<span> </span>
                    </span>
                  </div>
                </div>
              </div>
              {/* end card body */}
            </div>
            {/* end card */}
          </div>
          {/* end col */}

          {/* end col */}
          <div className="col-lg-3 col-md-3 col-6">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                      <i className="ri-arrow-down-circle-fill align-middle" />
                    </span>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                      payment pending{" "}
                    </p>
                    <h4 className=" mb-0">

                      <span
                        className="counter-value"
                        data-target="14799.44"
                      >

                      </span>
                    </h4>
                  </div>
                  <div className="flex-shrink-0 align-self-end">
                    <span className="badge bg-danger-subtle text-danger">
                      <i className="ri-arrow-down-s-fill align-middle me-1" />
                      4.80 %<span> </span>
                    </span>
                  </div>
                </div>
              </div>
              {/* end card body */}
            </div>
            {/* end card */}
          </div>
          {/* end col */}

          {/* end col */}
          <div className="col-lg-3 col-md-3 col-6">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                      <i className="ri-arrow-down-circle-fill align-middle" />
                    </span>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                      payment pending{" "}
                    </p>
                    <h4 className=" mb-0">

                      <span
                        className="counter-value"
                        data-target="14799.44"
                      >

                      </span>
                    </h4>
                  </div>
                  <div className="flex-shrink-0 align-self-end">
                    <span className="badge bg-danger-subtle text-danger">
                      <i className="ri-arrow-down-s-fill align-middle me-1" />
                      4.80 %<span> </span>
                    </span>
                  </div>
                </div>
              </div>
              {/* end card body */}
            </div>
            {/* end card */}
          </div>
          {/* end col */}
        </div>

        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <h4 className='m-0 p-0'>Course Batchess</h4>



          <div className="card-body mt-2 mt-lg-0 ms-lg-3 ms-0 border border-dashed border-end-0 border-start-0">
            <div className="d-flex flex-wrap justify-content-between">
              <div className="col-xxl-4 col-sm-6 col-9">
                <div className="search-box">
                  <input
                    onChange={handleSearch}
                    type="text"
                    className="form-control search"
                    placeholder="Search for batch name, batch id or course"
                  />
                  <i className="ri-search-line search-icon" />
                </div>
              </div>
              {/*end col*/}

              {/*end col*/}
              <div className="d-flex col-lg-3 col-3">
                <div className="col-xxl-8 col-sm-4">
                  <div>
                    <select
                      className="form-control"
                      data-choices=""
                      data-choices-search-false=""
                      name="choices-single-default"
                      id="idStatus"
                      onChange={handleSearch}

                    >
                      <option value="">Mode</option>

                      <option value="live">Live</option>
                      <option value="offline">Offline</option>
                      <option value="specialization">Specialization</option>
                      <option value="selfpaced">Selfpaced</option>

                    </select>
                  </div>
                </div>
                {/*end col*/}

                {/*end col*/}
                <div className="ms-2 d-none d-md-block">
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary w-100"

                    >

                      <i className="ri-equalizer-fill me-1 align-bottom" />
                      Filters
                    </button>
                  </div>
                </div>
              </div>
              {/*end col*/}
            </div>
          </div>
          {addbatch ? <>
            <Link to={"/add-batch/" + course_url + "/" + course_id} className='btn ms-3 btn-danger'>Add New Batch</Link>
          </> :
            <>

            </>}
        </div>
      </div>
      <hr className='mt-0'></hr>
      <div className="d-flex flex-wrap">

        {filteredData ?
          <>

            {filteredData.map((item) => (
              <>

                <div className="col-lg-4 p-3 col-12">
                  <BatchItem item={item} />
                </div>
              </>
            ))}

          </> :
          <>


          </>}
      </div>

    </>
  )
}

export default CourseBatchesList