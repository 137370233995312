import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useActionsContext } from '../context/ActionsContext'
import { Close } from '@material-ui/icons'
import { AuthContext } from '../context/AuthContext'

const Sidebar = ({account}) => {

    const { permission } = useActionsContext()

    const {user} = useContext(AuthContext)
    
  const {togglesidebar,settogglesidebar} = useActionsContext()
    return (
        <>

            {permission &&account?
                <>

                    <div id="scrollbar">
                        <div class="container-fluid vh-90">


                            <div id="two-column-menu">
                            </div>
                            <ul class="navbar-nav" id="navbar-nav">
                                <div className="d-flex">
                                    <Close onClick={()=>settogglesidebar(false)}/>
                                </div>

                            {permission?
                                    <>
                                        <li class="nav-item">
                                            <Link to="/" class="nav-link" data-key="t-classes">
                                             Home
                                            </Link>
                                        </li>

                                    </> :
                                    <>

                                    </>}
                       

                                {parseInt(permission.upcoming_classes) ?
                                    <>
                                        <li class="nav-item">
                                            <Link to="/classes" class="nav-link" data-key="t-classes">
                                                Upcoming Classes
                                            </Link>
                                        </li>

                                    </> :
                                    <>

                                    </>}



                                {parseInt(permission.active_batches) ?
                                    <>

                                        <li class="nav-item">
                                            <Link to="/active-batches" class="nav-link" data-key="t-active-batches">
                                                Active Batches
                                            </Link>
                                        </li>

                                    </> :
                                    <>

                                    </>}



                                    {parseInt(permission.customer_support) ?
                                    <>

                                        <li class="nav-item">
                                            <Link to="/customer-support" class="nav-link menu-link" >
                                                <i class="ri-honour-line"></i> <span data-key="t-customer-support">Customer Support</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>

                                    </>}



                                    {parseInt(permission.doubt_support) ?
                                                    <>
                                                        <li class="nav-item">
                                                            <Link to="/students-support" class="nav-link" data-key="t-main-support">
                                                                Doubt Support
                                                            </Link>
                                                        </li>
                                                    </> :

                                                    <>

                                                    </>}


                                                    {parseInt(permission.course_registrations) ?
                                                        <>

                                                            <li class="nav-item">
                                                                <Link to="/course-registrations" class="nav-link " >
                                                                    <span data-key="t-trainings">Course Registrations</span>
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>


                                                        </>}

                                {parseInt(permission.employees) || parseInt(permission.team_management) ?
                                    <>
                                        <li class="nav-item">
                                            <Link to="/team" class="nav-link" data-key="t-employees">
                                                Employees
                                            </Link>
                                        </li>
                                    </> :
                                    <>

                                    </>}



                        

                                    {parseInt(permission.dashboards) ?
                                    <>

                                        <li class="nav-item">
                                            <a href="#sidebarDashboard" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarDashboard" data-key="t-dashboard">
                                                Dashboards
                                            </a>

                                            <div class="collapse menu-dropdown" id="sidebarDashboard">
                                                <ul class="nav nav-sm flex-column">

                                                    {parseInt(permission.hr_dashboard) ?
                                                        <>

                                                            <li class="nav-item">
                                                                <Link to="/hr-dashboard" class="nav-link " >
                                                                    <span data-key="t-dashboard">Hr Dashboard</span>
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>


                                                        </>}
                                                    {parseInt(permission.marketing_manager_dashboard) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/marketing-dashboard" class="nav-link" data-key="t-dashboard">
                                                                    Marketing Dashboard
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>
                                                        </>}


                                                    {parseInt(permission.class_manager_dashboard) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/class-dashboard" class="nav-link" data-key="t-dashboard">
                                                                    Class Manager Dashboard
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>
                                                        </>}
                                                    {parseInt(permission.bde_dashboard) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/customer-dashboard" class="nav-link" data-key="t-dashboard">
                                                                    Customers Manager Dashboard
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>
                                                        </>}

                                                    {parseInt(permission.placement_officer_dashboard) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/placement-dashboard" class="nav-link" data-key="t-dashboard">
                                                                    Placements Manager Dashboard
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>
                                                        </>}

                                                    {parseInt(permission.finance_manager_dashboard) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/finance-dashboard" class="nav-link" data-key="t-dashboard">
                                                                    Finance Manager Dashboard
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>
                                                        </>}
                                                 



                                                </ul>
                                            </div>
                                        </li>



                                    </> :
                                    <>

                                    </>}



                                {parseInt(permission.students_management) ?
                                    <>

                                        <li class="nav-item">
                                            <a href="#sidebarstudents" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarstudents" data-key="t-students">
                                                Student Management
                                            </a>
                                            <div class="collapse menu-dropdown" id="sidebarstudents">
                                                <ul class="nav nav-sm flex-column">

                                                    {parseInt(permission.students_list) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/students" class="nav-link" data-key="t-main-students">
                                                                    Students List
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>
                                                        </>}

                                                    {parseInt(permission.doubt_support) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/students-support" class="nav-link" data-key="t-main-students">
                                                                    Doubts
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>
                                                        </>}


                                                    {parseInt(permission.assignment_submissions) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/assignment-submissions" class="nav-link" data-key="t-main-students">
                                                                    Assignment Submissions
                                                                </Link>
                                                            </li>

                                                        </> :
                                                        <>
                                                        </>}

                                                    {parseInt(permission.project_submissions) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/project-submissions" class="nav-link" data-key="t-main-students">
                                                                    Project Submissions
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>
                                                        </>}

                                                    {parseInt(permission.placement_support) ?
                                                        <>

                                                            <li class="nav-item">
                                                                <Link to="/placement-support" class="nav-link" data-key="t-main-students">
                                                                    Placement Support
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>

                                                        </>}





                                                </ul>
                                            </div>
                                        </li>




                                    </> :
                                    <>

                                    </>}






                                {parseInt(permission.courses_management) ?
                                    <>

                                        <li class="nav-item">
                                            <a href="#sidebartrainings" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebartrainings" data-key="t-trainings">
                                                Courses Management
                                            </a>

                                            <div class="collapse menu-dropdown" id="sidebartrainings">
                                                <ul class="nav nav-sm flex-column">

                                                    {parseInt(permission.course_registrations) ?
                                                        <>

                                                            <li class="nav-item">
                                                                <Link to="/course-registrations" class="nav-link " >
                                                                    <span data-key="t-trainings">Course Registrations</span>
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>


                                                        </>}
                                                    {parseInt(permission.courses) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/courses" class="nav-link" data-key="t-trainings">
                                                                    Courses
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>
                                                        </>}


                                                    {parseInt(permission.active_batches) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/active-batches" class="nav-link" data-key="t-trainings">
                                                                    Active Batches
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>
                                                        </>}
                                                    {parseInt(permission.all_batches) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/batches" class="nav-link" data-key="t-trainings">
                                                                    All Batches
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>
                                                        </>}

                                                    {parseInt(permission.upcoming_classes) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/classes" class="nav-link" data-key="t-trainings">
                                                                    Upcoming Classes
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>
                                                        </>}

                                                    {parseInt(permission.all_class_schedules) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/classes" class="nav-link" data-key="t-trainings">
                                                                    All Class Schedules
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>
                                                        </>}
                                                    {parseInt(permission.recordings) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/recordings" class="nav-link" data-key="t-trainings">
                                                                    Recordings
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>
                                                        </>}


                                                    {parseInt(permission.fee_payments) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/payments" class="nav-link" data-key="t-trainings">
                                                                    Fee Payments
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>
                                                        </>}



                                                </ul>
                                            </div>
                                        </li>



                                    </> :
                                    <>

                                    </>}



                                {parseInt(permission.team_management) ?
                                    <>

                                        <li class="nav-item">
                                            <a href="#sidebarteam" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarteam" data-key="t-team">
                                                Team Management
                                            </a>
                                            <div class="collapse menu-dropdown" id="sidebarteam">
                                                <ul class="nav nav-sm flex-column">

                                                    {parseInt(permission.team_list) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/team" class="nav-link" data-key="t-main-team">
                                                                    Team List
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>
                                                        </>}



                                                    {parseInt(permission.tasks) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/team-task-submissions" class="nav-link" data-key="t-main-team">
                                                                    Tasks
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>
                                                        </>}






                                                    {parseInt(permission.meetings) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/team-meetings" class="nav-link" data-key="t-main-team">
                                                                    Meetings
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>
                                                        </>}




                                                    {parseInt(permission.leaves) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/team-leaves" class="nav-link" data-key="t-main-team">
                                                                    Leaves
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>
                                                        </>}




                                                </ul>
                                            </div>
                                        </li>
                                    </> :
                                    <>

                                    </>}




                                {parseInt(permission.community_management) ?
                                    <>
                                        <li class="nav-item">
                                            <a href="#sidebarcommunity" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarcommunity" data-key="t-community">
                                                Community Management
                                            </a>
                                            <div class="collapse menu-dropdown" id="sidebarcommunity">
                                                <ul class="nav nav-sm flex-column">

                                                    
                                                {parseInt(permission.community_management) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/teams-groups" class="nav-link" data-key="t-main-community">
                                                                    Teams Groups
                                                                </Link>
                                                            </li>
                                                        </> :

                                                        <>

                                                        </>}

                                                    {parseInt(permission.posts) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/posts" class="nav-link" data-key="t-main-community">
                                                                    Posts
                                                                </Link>
                                                            </li>
                                                        </> :

                                                        <>

                                                        </>}



                                                    {parseInt(permission.comments) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/comments" class="nav-link" data-key="t-main-community">
                                                                    Comments
                                                                </Link>
                                                            </li>
                                                        </> :

                                                        <>

                                                        </>}


                                                    {parseInt(permission.messages) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/messages" class="nav-link" data-key="t-main-community">
                                                                    Messages
                                                                </Link>
                                                            </li>
                                                        </> :

                                                        <>

                                                        </>}





                                                </ul>
                                            </div>
                                        </li>
                                    </> :
                                    <>

                                    </>}




                                {parseInt(permission.student_support) ? <>

                                    <li class="nav-item">
                                        <a href="#sidebarsupport" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarsupport" data-key="t-support">
                                            Student Support
                                        </a>
                                        <div class="collapse menu-dropdown" id="sidebarsupport">
                                            <ul class="nav nav-sm flex-column">

                                                {parseInt(permission.doubt_support) ?
                                                    <>
                                                        <li class="nav-item">
                                                            <Link to="/students-support" class="nav-link" data-key="t-main-support">
                                                                Doubt Support
                                                            </Link>
                                                        </li>
                                                    </> :

                                                    <>

                                                    </>}




                                                {parseInt(permission.placement_support) ?
                                                    <>
                                                        <li class="nav-item">
                                                            <Link to="/placement-support" class="nav-link" data-key="t-main-support">
                                                                Placement Support
                                                            </Link>
                                                        </li>
                                                    </> :

                                                    <>

                                                    </>}





                                            </ul>
                                        </div>
                                    </li>
                                </> :
                                    <>

                                    </>}






                                {parseInt(permission.internships_management) ?
                                    <>
                                        <li class="nav-item">
                                            <a href="#sidebarinternships" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarintenrships" data-key="t-internships">
                                                Internships Management
                                            </a>

                                            <div class="collapse menu-dropdown" id="sidebarinternships">
                                                <ul class="nav nav-sm flex-column">

                                                    {parseInt(permission.intenships) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/internships" class="nav-link" data-key="t-main-internships">
                                                                    Internships
                                                                </Link>
                                                            </li>
                                                        </> :
                                                        <>
                                                        </>}



                                                    {parseInt(permission.intenship_applications) ?
                                                        <>

                                                            <li class="nav-item">
                                                                <Link to="/internship-applications" class="nav-link" data-key="t-main-internships">
                                                                    Internship Applications
                                                                </Link>
                                                            </li>
                                                        </> :

                                                        <>

                                                        </>}




                                                </ul>
                                            </div>
                                        </li>
                                    </> :
                                    <>

                                    </>}

                                {parseInt(permission.videos_management) ?
                                    <>
                                        <li class="nav-item">
                                            <a href="#sidebarvideos" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarvideos" data-key="t-videos">
                                                Videos
                                            </a>
                                            <div class="collapse menu-dropdown" id="sidebarvideos">
                                                <ul class="nav nav-sm flex-column">

                                                    {parseInt(permission.tutorials) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/videos" class="nav-link" data-key="t-main-videos">
                                                                    Tutorials
                                                                </Link>
                                                            </li>
                                                        </> :

                                                        <>

                                                        </>}


                                                    {parseInt(permission.shorts) ?
                                                        <>
                                                            <li class="nav-item">
                                                                <Link to="/shorts" class="nav-link" data-key="t-main-videos">
                                                                    Shorts
                                                                </Link>
                                                            </li>
                                                        </> :

                                                        <>

                                                        </>}




                                                </ul>
                                            </div>
                                        </li>
                                    </> :
                                    <>

                                    </>}






                                    {parseInt(permission.screen_shots) ?
                                    <>

                                        <li class="nav-item">
                                            <Link to="/payment-screen-shots" class="nav-link menu-link" >
                                                <i class="ri-honour-line"></i> <span data-key="t-customer-support">Payment Screen Shots</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>

                                    </>}

                                {parseInt(permission.leads) ?
                                    <>
                                        <li class="nav-item">
                                            <Link to="/leads" class="nav-link menu-link" >
                                                <i class="ri-honour-line"></i> <span data-key="t-leads">Leads</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>
                                    </>}


                                {parseInt(permission.users) ?
                                    <>
                                        <li class="nav-item">
                                            <Link to="/users" class="nav-link menu-link" >
                                                <i class="ri-honour-line"></i> <span data-key="t-users">Users</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>

                                    </>}


                                {parseInt(permission.payments) ?
                                    <>
                                        <li class="nav-item">
                                            <Link to="/payments" class="nav-link menu-link" >
                                                <i class="ri-honour-line"></i> <span data-key="t-payments">Payments</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>
                                    </>}

                                    {parseInt(permission.upcoming_classes) ?
                                    <>
                                        <li class="nav-item">
                                            <Link to="/quiz-attempts" class="nav-link" data-key="t-classes">
                                                Quiz Attempts
                                            </Link>
                                        </li>

                                    </> :
                                    <>

                                    </>}

                            {/* {parseInt(permission.upcoming_classes) ?
                                    <>
                                        <li class="nav-item">
                                            <Link to="/college-registrations" class="nav-link" data-key="t-classes">
                                                College Registrations
                                            </Link>
                                        </li>

                                    </> :
                                    <>

                                    </>} */}

                                {parseInt(permission.daily_reports) ?
                                    <>
                                        <li class="nav-item">
                                            <Link to="/daily-reports" class="nav-link menu-link" >
                                                <i class="ri-honour-line"></i> <span data-key="t-reports">Daily Reports</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>

                                    </>}
{/* 
                                {parseInt(permission.security) ?
                                    <>
                                        <li class="nav-item">
                                            <Link to="/security" class="nav-link menu-link" >
                                                <i class="ri-honour-line"></i> <span data-key="t-security">Security</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>

                                    </>} */}











                                {parseInt(permission.coupen_codes) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/coupon-codes" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-coupons">Coupon Codes</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>
                                    </>}


                                {/* {parseInt(permission.withdrawls) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/withdrawls" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-withdrawls">Withdrawls</span>
                                            </Link>
                                        </li>

                                    </> :
                                    <>
                                    </>} */}


                                {parseInt(permission.certificate_management) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/certificates" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-certificates">Certificate Management</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>
                                    </>}


{/* 
                                {parseInt(permission.sign_in) ?
                                    <>


                                        <li className="nav-item">
                                            <Link to="/signin" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-signin">Sign In</span>
                                            </Link>
                                        </li>

                                    </> :
                                    <>
                                    </>} */}

                                {/* {parseInt(permission.create_invoice) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/create-invoice" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-invoice">Create Invoice</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>
                                    </>} */}


{/* 
                                {parseInt(permission.invoices_list) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/invoices-list" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-invoiceslist">Invoices List</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>
                                    </>} */}

                                {/* {parseInt(permission.project_overview) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/project-overview" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-project-overview">Project Overview</span>
                                            </Link>
                                        </li>

                                    </> :
                                    <>
                                    </>} */}



                                {parseInt(permission.course_registrations) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/course-registrations" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-registrations-couse">Course Registrations</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>
                                    </>}

                                {/* {parseInt(permission.user_lists) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/users-list" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-users">Users List</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>
                                    </>}
 */}



                                {/* {parseInt(permission.analytics) ?
                                    <>

                                        <li className="nav-item">
                                            <Link to="/analytics" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-analytics">Analytics</span>
                                            </Link>
                                        </li>

                                    </> :
                                    <>
                                    </>} */}


                                {/* {parseInt(permission.projects_dashboard) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/projects-dashboard" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-projects">Projects Dashboard</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>
                                    </>} */}


                                {parseInt(permission.workshop_registrations) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/workshop-registrations" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i>
                                                <span data-key="t-workshops">Workshop Registrations</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>
                                    </>}




                                {/* {parseInt(permission.campus_ambassadors) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/campus-ambassadors" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-ambassadors">Campus Ambassadors</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>
                                    </>} */}



                                {/* {parseInt(permission.job_hunt) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/job-hunt" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-job">Job Hunt</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>
                                    </>} */}




                                    {/* {parseInt(permission.hacking_workshop_leads) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/hacking-workshop-registrations" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i>
                                                <span data-key="t-workshop-regs">CEH Workshop Leads</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>

                                    </>} */}


                                {parseInt(permission.hacking_internship_leads) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/hacking-internship-registrations" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i>
                                                <span data-key="t-internship-leads">CEH Internship Leads</span>
                                            </Link>
                                        </li>

                                    </> :
                                    <>

                                    </>}



                                {/* {parseInt(permission.wallet) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/wallet" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-wallet">Wallet</span>
                                            </Link>
                                        </li>
                                    </> :
                                    <>
                                    </>} */}


                                {parseInt(permission.super_admin_dashboard) ?
                                    <>
                                        <li className="nav-item">
                                            <Link to="/" className="nav-link menu-link">
                                                <i className="ri-honour-line"></i> <span data-key="t-super-admin">Super Admin Dashboard</span>
                                            </Link>
                                        </li>

                                    </> :
                                    <>
                                    </>
                                }




































                            </ul>
                        </div>

                    </div>


                </> :
                <>

                </>}


        </>
    )
}

export default Sidebar