import { createContext, useContext, useState } from "react";
import ENDPOINT from "../EndPoint";
import axios from "axios";
import { AuthContext } from "../AuthContext";
import SecurityHeaders from "../SecurityHeaders";
import Localbase from "localbase";


const StudentDoubtSupportContext  = createContext()
export const useContactRequests = ()=>useContext(StudentDoubtSupportContext)
export const StudentDoubtSupportProvider = ({children})=>{

    const {user} = useContext(AuthContext)
const db = new Localbase("db")

const [doubts,setdoubts] = useState(null)
const [deleteResponse,setDeleteResponse] = useState(null)
const [updateResponse,setUpdateResponse] = useState(null)


const DeleteSupportRequest = async ({payload}) => {
    const data = new FormData();

    data.append('id',payload.id);

    const res = await axios.post(ENDPOINT+'admin-delete-support-request.php',data,SecurityHeaders);
    
    if(res){
       // console.log(res+" res ")
        if(res.data.status==="success"){


        }  return res
       
    }
}


const GetDoubts = async () => {
    const data = new FormData();
    data.append("session", user.SessionId);
   

    const res = await axios.post(
      ENDPOINT + "admin-get-doubt-requests.php",
      data,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    if (res) {
       // console.log("Resp  is "+ JSON.stringify(res.data.data))
        if(res.data.status==="success"){
            setdoubts(res.data.data)
        }
    }
  };

const UpdateDoubtRequest = async()=>{



  


}
return (
    <StudentDoubtSupportContext.Provider value={{doubts,GetDoubts,deleteResponse,DeleteSupportRequest,updateResponse,UpdateDoubtRequest}}>
        {children}
    </StudentDoubtSupportContext.Provider>
)

}