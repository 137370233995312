import React, { useContext, useEffect, useState } from "react";
import ClassesList from "../classes/ClassesList";
import DemoData from "../../data/DemoData";
import AssignmentsList from "../assignments/AssignmentsList";
import GetBatchAssignments from "../../actions/assignments/GetBatchAssignments";
import { Link, useParams } from "react-router-dom";
import DefaultToast from "../../components/toasts/DefaultToast";
import { AuthContext } from "../../context/AuthContext";
import GetBatchClassSchedules from "../../actions/course/GetBatchClassSchedules";
import ProjectsList from "../projects/ProjectsList";
import GetCourseProjects from "../../actions/course/GetCourseProjects";
import AssignmentSubmissionsList from "../assignments/AssignmentSubmissionsList";
import GetBatchAssignmentSubmissions from "../../actions/assignments/GetBatchAssignmentSubmissions";
import ProjectSubmissionsList from "../projects/ProjectSubmissionsList";
import GetBatchwiseProjectSubissions from "../../actions/projects/GetBatchwiseProjectSubissions";
import BatchReports from "./BatchReports";
import GetBatchReports from "../../actions/reports/GetBatchReports";
import AddClassModal from "../../components/modals/AddClassModal";
import DefaultModal from "../../components/modals/DefaultModal";
import AddCourseAssignment from "./AddCourseAssignment";
import { Close, Edit, Visibility } from "@material-ui/icons";
import AddNewProject from "./AddNewProject";
import axios from "axios";
import ENDPOINT from "../../context/EndPoint";
import SecurityHeaders from "../../context/SecurityHeaders";
import { useActionsContext } from "../../context/ActionsContext";
import NoPermissionModal from "../../components/modals/NoPermissionModal";
import BatchAttendence from "./BatchAttendence";
import GetBatchAttendence from "../../actions/student/GetBatchAttendence";
import { Delete } from "@mui/icons-material";
import { connect } from 'react-redux';
import EditBatch from "./EditBatch";
const BatchManagement = ({ value, dispatch }) => {
  const [section, setsection] = useState(
    JSON.parse(sessionStorage.getItem("course_section")) || "overview"
  );
  const params = useParams();
  const { user } = useContext(AuthContext);
  const { permission } = useActionsContext();
  const course_url = params.course_url;
  const batch_id = params.batch_id;
  const course_id = params.course_id;
  const [addassignment, setaddassignment] = useState(false);
  useEffect(() => {
    var option = sessionStorage.getItem("course_section");
    if (option) {
      setsection(JSON.parse(option));
    }
  }, [section]);

  const ChangeSection = ({ section }) => {
    setsection(section);
    sessionStorage.setItem("course_section", JSON.stringify(section));
  };

  const [addproject, setaddproject] = useState(false);

  //   // console.log(value);

  const GetAssignments = async () => {
    const res = await GetBatchAssignments({
      payload: {
        session: user.SessionId,
        batch_id: batch_id,
        course_url: course_url,
      },
    });
    if (res) {
      if (res.status === "success") {
        setassignments(res.data);
      }
    }
  };


  const [info, setinfo] = useState(null);
  const GetInfo = async () => {
    const data = new FormData();
    data.append("session", user.SessionId);
    data.append("batch_id", batch_id);
    data.append("course_url", course_url);
    const res = await axios.post(
      ENDPOINT + "get-batch-info.php",
      data,
      SecurityHeaders
    );

    if (res) {
      if (res.data.status === "success") {
        setinfo(res.data.data);
         // console.log(res.data.data);
      }
    }
  };

  const [classes, setclasses] = useState(null);
  const GetClasses = async () => {
    const res = await GetBatchClassSchedules({
      payload: {
        session: user.SessionId,
        batch_id: batch_id,
        course_url: course_url,
        course_id: course_id,
      },
    });

    if (res) {
      if (res.status === "success") {
        setclasses(res.data);
      }
    }
  };
  const [assignments, setassignments] = useState(null);

  const [toastdata, settoastdata] = useState(null);

  const [toast, settoast] = useState(false);

  const [projects, setprojects] = useState(null);

  const [assignmentsubmissions, setassignmentsubmissions] = useState(null);
  const [projectsubmissions, setprojectsubmissions] = useState(null);
  const [leaderboard, setleaderboard] = useState(null);
  const GetProjects = async () => {
    const res = await GetCourseProjects({
      payload: {
        session: user.SessionId,
        course_url: course_url,
      },
    });

    if (res) {
      if (res.status === "success") {
        setprojects(res.data);
      }
    }
  };

  useEffect(() => {
    if (section === "overview") {
      GetReports();
    }
    if (section === "classes") {
      GetClasses();
    }
    if (section === "assignments") {
      GetAssignments();
    }
    if (section === "projects") {
      GetProjects();
    }
    if (section === "assignment-submissions") {
      GetAssignmentSubmissions();
    }
    if (section === "project-submissions") {
      GetProjectSubmissions();
    }
    if (section === "attendance") {
      GetAttendence();
    }
  }, [section]);

  const GetAssignmentSubmissions = async () => {
    const res = await GetBatchAssignmentSubmissions({
      payload: {
        session: user.SessionId,
        batch_id: batch_id,
        course_url: course_url,
      },
    });
    if (res) {
      if (res.status === "success") {
        setassignmentsubmissions(res.data);
      }
    }
  };

  const GetProjectSubmissions = async () => {
    const res = await GetBatchwiseProjectSubissions({
      payload: {
        session: user.SessionId,
        course_url: course_url,
        batch_id: batch_id,
      },
    });

    if (res) {
      if (res.status === "success") {
        setprojectsubmissions(res.data);
      }
    }
  };

  const [reports, setreports] = useState(null);
  const GetReports = async () => {
    const res = await GetBatchReports({
      payload: {
        session: user.SessionId,
        course_url: course_url,
        batch_id: batch_id,
      },
    });

    if (res) {
      if (res.status === "success") {
        setreports(res.reports);
      }
    }
  };

  useEffect(() => {
    GetInfo();
  }, []);

  const GetAttendence = async () => {
    const res = await GetBatchAttendence({
      payload: {
        batch_id: batch_id,
        course_url: course_url,
        session: user.SessionId,
      },
    });

    if (res) {
      if (res.status === "success") {
        setattendence(res.data);
      }
    }
  };
  const [attendence, setattendence] = useState(null);
  const [addclass, setaddclass] = useState(false);
  const [nopermission, setnopermission] = useState(false);
  const [button, setbutton] = useState(false);
  const [editBatch,setEditBatch] = useState(false);

  const modalClose = () => {
    setaddassignment(false)
  }
  const [isToggled, setIsToggled] = useState(true);

  const handleChange = () => {
    setIsToggled(!isToggled);
  };


  useEffect(() => {
    if (value.modal === false) {
      setaddclass(false);
    }

     // console.log('Function name:', value.functionName);

    switch (value.functionName) {
      case 'classes':
        GetClasses();
        break;
      case 'assignment':
        GetAssignments();
        break;
      case 'projects':
        GetProjects();
        break;
      case 'assignment-submissions':
        GetAssignmentSubmissions();
        break;
      case 'project-submissions':
        GetProjectSubmissions();
        break;
      case 'attendance':
        GetAttendence();
        break;
        case 'get-reports':
          GetReports();
        break;
      default:
        return;
    }
  }, [value]);




  return (
    <>
      <NoPermissionModal
        show={nopermission}
        HandleClose={() => setnopermission(false)}
      />
          <DefaultModal
        show={editBatch}
        HandleClose={() => setEditBatch(false)}
        content={
          <>
            <div className="p-3">
              <div className="modal-header">
                <h4>Edit Batch</h4>
                <Close onClick={() => setEditBatch(false)} />
              </div>
              <hr></hr>
              <EditBatch batch_id={batch_id} course_url={course_url} closeModal={() => setEditBatch(false)}/>
            </div>
          </>
        }
      ></DefaultModal>
      <DefaultModal
        show={addproject}
        HandleClose={() => setaddproject(false)}
        content={
          <>
            <div className="p-3">
              <div className="modal-header">
                <h4>Add Project</h4>
                <Close onClick={() => setaddproject(false)} />
              </div>
              <hr></hr>
              <AddNewProject courseId={course_id} course_url={course_url} closeModal={() => setaddproject(false)} batchId={batch_id} />
            </div>
          </>
        }
      ></DefaultModal>
      <AddClassModal
        visibility={addclass}
        handleClose={() => setaddclass(false)}
        Reload={GetClasses}
      />
      <DefaultToast show={toast} toastdata={toastdata} />
      <div className="container-fluid">
        {info ? (
          <>
            <div className="profile-foreground position-relative mx-n4 mt-n4">
              <div className="profile-wid-bg">
                <img
                  src="assets/images/profile-bg.jpg"
                  alt=""
                  className="profile-wid-img"
                />
              </div>
            </div>
            <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper d-flex">
              <div className="row g-4">
                <div className="col-auto">
                  <div className="avatar-lg">
                    <img
                      src={info.course_cover_url}
                      alt="user-img"
                      className="img-thumbnail"
                    />
                  </div>
                </div>
                {/*end col*/}
                <div className="col">

                  <div className="p-2 pt-0">
                    <h3 className="text-white mb-1">Batch ID : {batch_id}</h3>
                    <p className="text-white text-opacity-75 mb-1">
                      {course_url}
                    </p>
                    <div className="hstack text-white-50 gap-1">
                      <div className="me-2">
                        <i className=" me-1 text-white text-opacity-75 fs-16 align-middle" />
                        {info.class_starts} -{info.class_ends}
                      </div>
                    </div>
                  </div>
                </div>




                {/*end col*/}

                {/*end col*/}
              </div>



              {/*end row*/}
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="row">
          <div className="col-lg-12">
            <div>
              <div className="d-flex profile-wrapper">
                {/* Nav tabs */}
                <ul
                  className="nav nav-pills overflow-x-auto flex-row no-wrap animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                  role="tablist"
                >
                  <li
                    onClick={() => {
                      parseInt(permission.batch_overview)
                        ? ChangeSection({ section: "overview" })
                        : setnopermission(true);
                    }}
                    className="nav-item"
                  >
                    <a className="nav-link fs-14 d-flex  active" role="tab">
                      <i className="ri-airplay-fill d-inline-block d-md-none" />{" "}
                      <span className="d-md-inline-block">Overview</span>
                    </a>
                  </li>

                  <li
                    onClick={() => {
                      parseInt(permission.batch_attendance)
                        ? ChangeSection({ section: "attendance" })
                        : setnopermission(true);
                    }}
                    className="nav-item"
                  >
                    <a className="nav-link fs-14 d-flex  " role="tab">
                      <i className="ri-airplay-fill d-inline-block d-md-none" />{" "}
                      <span className="d-md-inline-block">Attendance</span>
                    </a>
                  </li>
                  <li
                    onClick={() => {
                      parseInt(permission.batch_classes)
                        ? ChangeSection({ section: "classes" })
                        : setnopermission(true);
                    }}
                    className="nav-item"
                  >
                    <a className="nav-link fs-14 d-flex " role="tab">
                      <i className="ri-list-unordered d-inline-block d-md-none" />{" "}
                      <span className="d-md-inline-block">Classes</span>
                    </a>
                  </li>
                  <li
                    onClick={() => {
                      parseInt(permission.batch_assignments)
                        ? ChangeSection({ section: "assignments" })
                        : setnopermission(true);
                    }}
                    className="nav-item"
                  >
                    <a className="nav-link fs-14 d-flex " role="tab">
                      <i className="ri-price-tag-line d-inline-block d-md-none" />{" "}
                      <span className="d-md-inline-block">Assignments</span>
                    </a>
                  </li>
                  <li
                    onClick={() => {
                      parseInt(permission.batch_projects)
                        ? ChangeSection({ section: "projects" })
                        : setnopermission(true);
                    }}
                    className="nav-item"
                  >
                    <a className="nav-link fs-14 d-flex " role="tab">
                      <i className="ri-price-tag-line d-inline-block d-md-none" />{" "}
                      <span className="d-md-inline-block">Projects</span>
                    </a>
                  </li>
                  <li
                    onClick={() => {
                      parseInt(permission.batch_assignment_submissions)
                        ? ChangeSection({ section: "assignment-submissions" })
                        : setnopermission(true);
                    }}
                    className="nav-item"
                  >
                    <a className="nav-link fs-14 d-flex " role="tab">
                      <i className="ri-price-tag-line d-inline-block d-md-none" />{" "}
                      <span className="d-md-inline-block">
                        Assignment Submissions
                      </span>
                    </a>
                  </li>
                  <li
                    onClick={() => {
                      parseInt(permission.batch_project_submissions)
                        ? ChangeSection({ section: "project-submissions" })
                        : setnopermission(true);
                    }}
                    className="nav-item"
                  >
                    <a className="nav-link fs-14 d-flex " role="tab">
                      <i className="ri-price-tag-line d-inline-block d-md-none" />{" "}
                      <span className="d-md-inline-block">
                        Project Submissions
                      </span>
                    </a>
                  </li>
                </ul>

                <div className="flex-shrink-0 d-none d-md-block">
                  {parseInt(permission.edit_batch) ? (
                    <>
                      {" "}
                      <button onClick={()=>setEditBatch(true)} className="btn btn-success">
                        <i className="ri-edit-box-line align-bottom" /> Edit
                        Batch
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {/* Tab panes */}
              <div className="tab-content pt-4 text-muted">
                {section === "overview" ? (
                  <>
                    <div
                      className="tab-pane active"
                      id="overview-tab"
                      role="tabpanel"
                    >
                      {info ? (
                        <>
                          <div className="row">
                            <div className="col-xxl-3">
                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title mb-3">
                                    Batch Progress
                                  </h5>
                                  <div className="progress animated-progress custom-progress progress-label">
                                    <div
                                      className="progress-bar bg-danger"
                                      role="progressbar"
                                      style={{ width: "30%" }}
                                      aria-valuenow={30}
                                      aria-valuemin={0}
                                      aria-valuemax={100}
                                    >
                                      <div className="label">30%</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="card h-fit">
                                <div className="card-body h-fit">
                                  <div className="table-responsive h-fit">
                                    <table className="table table-borderless mb-0">
                                      <tbody>
                                        <tr>
                                          <th className="ps-0" scope="row">
                                            Start Date :
                                          </th>
                                          <td className="text-muted">
                                            {info.registration_ends}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th className="ps-0" scope="row">
                                            End Date :
                                          </th>
                                          <td className="text-muted">
                                            +(1) 987 6543
                                          </td>
                                        </tr>
                                        <tr>
                                          <th className="ps-0" scope="row">
                                            Total Students
                                          </th>
                                          <td className="text-muted">
                                            {info.students
                                              ? info.students.length
                                              : 0}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th className="ps-0" scope="row">
                                            Duration
                                          </th>
                                          <td className="text-muted">
                                            {info.course_duration} days
                                          </td>
                                        </tr>
                                        <tr>
                                          <th className="ps-0" scope="row">
                                            Timings
                                          </th>
                                          <td className="text-muted">
                                            24 Nov 2021
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                {/* end card body */}
                              </div>


                            </div>
                            {/*end col*/}
                            <div className="col-xxl-9  bg-white">

                              <div className="d-flex w-100 justify-content-between">

                                <div className="col-lg-8">
                                  {reports ? (
                                    <>
                                      <BatchReports reports={reports} />
                                    </>
                                  ) : (
                                    <>
                                    <h3 className="mt-5  text-center">I don't have any reports.</h3>
                                    </>
                                  )}
                                </div>
                                <div className="col-lg-4 pe-2">
                                  <div className="card">
                                    <div className="card-body">
                                      <div className="d-flex align-items-center mb-4">
                                        <div className="flex-grow-1">
                                          <h5 className="card-title mb-0">
                                            Students
                                          </h5>
                                        </div>
                                        <div className="flex-shrink-0">
                                          <div className="dropdown">
                                            <a
                                              href="#"
                                              role="button"
                                              id="dropdownMenuLink2"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <i className="ri-more-2-fill fs-14" />
                                            </a>
                                            <ul
                                              className="dropdown-menu dropdown-menu-end"
                                              aria-labelledby="dropdownMenuLink2"
                                            >
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  href="#"
                                                >
                                                  <Visibility /> View
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  href="#"
                                                >
                                                  <Edit /> Edit
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  href="#"
                                                >
                                                  <Delete /> Delete
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="overflow-y-scroll vh-75">
                                        {info.students ? (
                                          <>
                                            {info.students.map((item) => (
                                              <>
                                                <Link to={"/student-profile/" + item.purchase_id}>
                                                  <div className="d-flex align-items-center py-3">
                                                    <div className="avatar-xs flex-shrink-0 me-3">
                                                      <img
                                                        src={item.profile_pic}
                                                        alt=""
                                                        className="img-fluid rounded-circle material-shadow"
                                                      />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                      <div>
                                                        <h5 className="fs-14 mb-1">
                                                          {item.first_name +
                                                            " " +
                                                            item.last_name}
                                                        </h5>

                                                      </div>
                                                    </div>

                                                  </div>
                                                </Link>

                                              </>
                                            ))}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                    {/* end card body */}
                                  </div>
                                </div>


                              </div>


                              {/* end card */}
                            </div>
                            {/*end col*/}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {section === "attendance" ? (
                  <>
                    <div
                      className="tab-pane active"
                      id="activities"
                      role="tabpanel"
                    >
                      <div className="card p-3">
                        <div className="d-flex justify-content-between">
                          <h4>Attendence</h4>
                        </div>
                        <hr></hr>
                        {attendence ? (
                          <>
                            <BatchAttendence attendence={attendence} />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {section === "classes" ? (
                  <>
                    <div
                      className="tab-pane active"
                      id="activities"
                      role="tabpanel"
                    >
                      <div className="card p-3">
                        <div className="d-flex justify-content-between">
                          <h4>Class Schedules</h4>
                          <button
                            onClick={() => { setaddclass(true); dispatch({ type: 'MODAL_OPEN', payload: 'add_class' }) }}
                            className="btn btn-primary"
                          >
                            Add Class
                          </button>
                        </div>
                        <hr></hr>
                        {classes ? (
                          <>
                            <ClassesList notification={isToggled} Reload={GetClasses} classes={classes} />
                          </>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <DefaultModal
                  show={addassignment}
                  HandleClose={modalClose}
                  content={
                    <>
                      <div className="p-3">
                        <div className="modal-header">
                          <h4>Add Assignment </h4>
                          <Close onClick={() => setaddassignment(false)} />
                        </div>
                        <hr></hr>
                        <AddCourseAssignment Reload={GetClasses} modalClose={modalClose} />
                      </div>
                    </>
                  }
                ></DefaultModal>

                {section === "assignments" ? (
                  <>
                    <div
                      className="tab-pane active"
                      id="activities"
                      role="tabpanel"
                    >
                      <div className="card p-3">
                        <div className="d-flex justify-content-between">
                          <h4>Assignments </h4>
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                              parseInt(permission.add_assignment)
                                ? setaddassignment(true)
                                : setnopermission(true);
                            }}
                          >
                            Add Assignment
                          </button>
                        </div>
                        <hr></hr>
                        {assignments ? (
                          <>
                            <AssignmentsList assignmentsList={GetAssignments} tasks={assignments} />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {section === "projects" ? (
                  <>
                    <div
                      className="tab-pane active"
                      id="activities"
                      role="tabpanel"
                    >
                      <div className="card p-3">
                        <div className="d-flex justify-content-between">
                          <h4>Projects</h4>
                          <button
                            className="btn btn-danger"
                            onClick={() => setaddproject(true)}
                          >
                            Add New Project
                          </button>
                        </div>
                        <hr></hr>
                        {projects ? (
                          <>
                            <ProjectsList projects={projects} />
                          </>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {section === "assignment-submissions" ? (
                  <>
                    <div
                      className="tab-pane active"
                      id="activities"
                      role="tabpanel"
                    >
                      <div className="card p-2">
                        <div className="d-flex mt-2">
                          <h4>Assignment Submissions</h4>
                        </div>
                        <hr></hr>
                        {assignmentsubmissions ? (
                          <>
                            <AssignmentSubmissionsList
                              tasks={assignmentsubmissions}
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {section === "project-submissions" ? (
                  <>
                    <div
                      className="tab-pane active"
                      id="activities"
                      role="tabpanel"
                    >
                      <div className="card p-2">
                        <div className="d-flex mt-2">
                          <h4>Project Submissions</h4>
                        </div>
                        <hr></hr>
                        {assignmentsubmissions ? (
                          <>
                            <ProjectSubmissionsList
                              tasks={projectsubmissions}
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="tab-pane fade" id="activities" role="tabpanel">
                  <div className="card">
                    <div className="card-body">
                      <div className="tab-content text-muted">
                        <div
                          className="tab-pane active"
                          id="today"
                          role="tabpanel"
                        >
                          <div className="profile-timeline">
                            <div
                              className="accordion accordion-flush"
                              id="todayExample"
                            >
                              <div className="accordion-item border-0">
                                <div
                                  className="accordion-header"
                                  id="headingOne"
                                >
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapseOne"
                                    aria-expanded="true"
                                  >
                                    <div className="d-flex">
                                      <div className="flex-shrink-0">
                                        <img
                                          src="assets/images/users/avatar-2.jpg"
                                          alt=""
                                          className="avatar-xs rounded-circle material-shadow"
                                        />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-1">
                                          Jacqueline Steve
                                        </h6>
                                        <small className="text-muted">
                                          We has changed 2 attributes on 05:16PM
                                        </small>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  id="collapseOne"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="headingOne"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body ms-2 ps-5">
                                    In an awareness campaign, it is vital for
                                    people to begin put 2 and 2 together and
                                    begin to recognize your cause. Too much or
                                    too little spacing, as in the example below,
                                    can make things unpleasant for the reader.
                                    The goal is to make your text as comfortable
                                    to read as possible. A wonderful serenity
                                    has taken possession of my entire soul, like
                                    these sweet mornings of spring which I enjoy
                                    with my whole heart.
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item border-0">
                                <div
                                  className="accordion-header"
                                  id="headingTwo"
                                >
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapseTwo"
                                    aria-expanded="false"
                                  >
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 avatar-xs">
                                        <div className="avatar-title bg-light text-success rounded-circle material-shadow">
                                          M
                                        </div>
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-1">
                                          Megan Elmore
                                        </h6>
                                        <small className="text-muted">
                                          Adding a new event with attachments -
                                          04:45PM
                                        </small>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  id="collapseTwo"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="headingTwo"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body ms-2 ps-5">
                                    <div className="row g-2">
                                      <div className="col-auto">
                                        <div className="d-flex border border-dashed p-2 rounded position-relative">
                                          <div className="flex-shrink-0">
                                            <i className="ri-image-2-line fs-17 text-danger" />
                                          </div>
                                          <div className="flex-grow-1 ms-2">
                                            <h6>
                                              <a
                                                href="javascript:void(0);"
                                                className="stretched-link"
                                              >
                                                Business Template - UI/UX design
                                              </a>
                                            </h6>
                                            <small>685 KB</small>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-auto">
                                        <div className="d-flex border border-dashed p-2 rounded position-relative">
                                          <div className="flex-shrink-0">
                                            <i className="ri-file-zip-line fs-17 text-info" />
                                          </div>
                                          <div className="flex-grow-1 ms-2">
                                            <h6 className="mb-0">
                                              <a
                                                href="javascript:void(0);"
                                                className="stretched-link"
                                              >
                                                Bank Management System - PSD
                                              </a>
                                            </h6>
                                            <small>8.78 MB</small>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item border-0">
                                <div
                                  className="accordion-header"
                                  id="headingThree"
                                >
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapsethree"
                                    aria-expanded="false"
                                  >
                                    <div className="d-flex">
                                      <div className="flex-shrink-0">
                                        <img
                                          src="assets/images/users/avatar-5.jpg"
                                          alt=""
                                          className="avatar-xs rounded-circle material-shadow"
                                        />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-1">
                                          {" "}
                                          New ticket received
                                        </h6>
                                        <small className="text-muted mb-2">
                                          User{" "}
                                          <span className="text-secondary">
                                            Erica245
                                          </span>{" "}
                                          submitted a ticket - 02:33PM
                                        </small>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div className="accordion-item border-0">
                                <div
                                  className="accordion-header"
                                  id="headingFour"
                                >
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapseFour"
                                    aria-expanded="true"
                                  >
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 avatar-xs">
                                        <div className="avatar-title bg-light text-muted rounded-circle material-shadow">
                                          <i className="ri-user-3-fill" />
                                        </div>
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-1">
                                          Nancy Martino
                                        </h6>
                                        <small className="text-muted">
                                          Commented on 12:57PM
                                        </small>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  id="collapseFour"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="headingFour"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body ms-2 ps-5 fst-italic">
                                    " A wonderful serenity has taken possession
                                    of my entire soul, like these sweet mornings
                                    of spring which I enjoy with my whole heart.
                                    Each design is a new, unique piece of art
                                    birthed into this world, and while you have
                                    the opportunity to be creative and make your
                                    own style choices. "
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item border-0">
                                <div
                                  className="accordion-header"
                                  id="headingFive"
                                >
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapseFive"
                                    aria-expanded="true"
                                  >
                                    <div className="d-flex">
                                      <div className="flex-shrink-0">
                                        <img
                                          src="assets/images/users/avatar-7.jpg"
                                          alt=""
                                          className="avatar-xs rounded-circle material-shadow"
                                        />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-1">
                                          Lewis Arnold
                                        </h6>
                                        <small className="text-muted">
                                          Create new project buildng product -
                                          10:05AM
                                        </small>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  id="collapseFive"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="headingFive"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body ms-2 ps-5">
                                    <p className="text-muted mb-2">
                                      {" "}
                                      Every team project can have a velzon. Use
                                      the velzon to share information with your
                                      team to understand and contribute to your
                                      project.
                                    </p>
                                    <div className="avatar-group">
                                      <a
                                        href="javascript: void(0);"
                                        className="avatar-group-item"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title=""
                                        data-bs-original-title="Christi"
                                      >
                                        <img
                                          src="assets/images/users/avatar-4.jpg"
                                          alt=""
                                          className="rounded-circle avatar-xs material-shadow"
                                        />
                                      </a>
                                      <a
                                        href="javascript: void(0);"
                                        className="avatar-group-item"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title=""
                                        data-bs-original-title="Frank Hook"
                                      >
                                        <img
                                          src="assets/images/users/avatar-3.jpg"
                                          alt=""
                                          className="rounded-circle avatar-xs material-shadow"
                                        />
                                      </a>
                                      <a
                                        href="javascript: void(0);"
                                        className="avatar-group-item"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title=""
                                        data-bs-original-title=" Ruby"
                                      >
                                        <div className="avatar-xs">
                                          <div className="avatar-title rounded-circle bg-light text-primary material-shadow">
                                            R
                                          </div>
                                        </div>
                                      </a>
                                      <a
                                        href="javascript: void(0);"
                                        className="avatar-group-item"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title=""
                                        data-bs-original-title="more"
                                      >
                                        <div className="avatar-xs">
                                          <div className="avatar-title rounded-circle material-shadow">
                                            2+
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*end accordion*/}
                          </div>
                        </div>
                        <div className="tab-pane" id="weekly" role="tabpanel">
                          <div className="profile-timeline">
                            <div
                              className="accordion accordion-flush"
                              id="weeklyExample"
                            >
                              <div className="accordion-item border-0">
                                <div className="accordion-header" id="heading6">
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapse6"
                                    aria-expanded="true"
                                  >
                                    <div className="d-flex">
                                      <div className="flex-shrink-0">
                                        <img
                                          src="assets/images/users/avatar-3.jpg"
                                          alt=""
                                          className="avatar-xs rounded-circle material-shadow"
                                        />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-1">
                                          Joseph Parker
                                        </h6>
                                        <small className="text-muted">
                                          New people joined with our company -
                                          Yesterday
                                        </small>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  id="collapse6"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="heading6"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body ms-2 ps-5">
                                    It makes a statement, it’s impressive
                                    graphic design. Increase or decrease the
                                    letter spacing depending on the situation
                                    and try, try again until it looks right, and
                                    each letter has the perfect spot of its own.
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item border-0">
                                <div className="accordion-header" id="heading7">
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapse7"
                                    aria-expanded="false"
                                  >
                                    <div className="d-flex">
                                      <div className="avatar-xs">
                                        <div className="avatar-title rounded-circle bg-light text-danger material-shadow">
                                          <i className="ri-shopping-bag-line" />
                                        </div>
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-1">
                                          Your order is placed{" "}
                                          <span className="badge bg-success-subtle text-success align-middle">
                                            Completed
                                          </span>
                                        </h6>
                                        <small className="text-muted">
                                          These customers can rest assured their
                                          order has been placed - 1 week Ago
                                        </small>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div className="accordion-item border-0">
                                <div className="accordion-header" id="heading8">
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapse8"
                                    aria-expanded="true"
                                  >
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 avatar-xs">
                                        <div className="avatar-title bg-light text-success rounded-circle material-shadow">
                                          <i className="ri-home-3-line" />
                                        </div>
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-1">
                                          Velzon admin dashboard templates
                                          layout upload
                                        </h6>
                                        <small className="text-muted">
                                          We talked about a project on linkedin
                                          - 1 week Ago
                                        </small>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  id="collapse8"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="heading8"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body ms-2 ps-5 fst-italic">
                                    Powerful, clean &amp; modern responsive
                                    bootstrap 5 admin template. The maximum file
                                    size for uploads in this demo :
                                    <div className="row mt-2">
                                      <div className="col-xxl-6">
                                        <div className="row border border-dashed gx-2 p-2">
                                          <div className="col-3">
                                            <img
                                              src="assets/images/small/img-3.jpg"
                                              alt=""
                                              className="img-fluid rounded material-shadow"
                                            />
                                          </div>
                                          {/*end col*/}
                                          <div className="col-3">
                                            <img
                                              src="assets/images/small/img-5.jpg"
                                              alt=""
                                              className="img-fluid rounded material-shadow"
                                            />
                                          </div>
                                          {/*end col*/}
                                          <div className="col-3">
                                            <img
                                              src="assets/images/small/img-7.jpg"
                                              alt=""
                                              className="img-fluid rounded material-shadow"
                                            />
                                          </div>
                                          {/*end col*/}
                                          <div className="col-3">
                                            <img
                                              src="assets/images/small/img-9.jpg"
                                              alt=""
                                              className="img-fluid rounded material-shadow"
                                            />
                                          </div>
                                          {/*end col*/}
                                        </div>
                                        {/*end row*/}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item border-0">
                                <div className="accordion-header" id="heading9">
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapse9"
                                    aria-expanded="false"
                                  >
                                    <div className="d-flex">
                                      <div className="flex-shrink-0">
                                        <img
                                          src="assets/images/users/avatar-6.jpg"
                                          alt=""
                                          className="avatar-xs rounded-circle material-shadow"
                                        />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-1">
                                          New ticket created{" "}
                                          <span className="badge bg-info-subtle text-info align-middle">
                                            Inprogress
                                          </span>
                                        </h6>
                                        <small className="text-muted mb-2">
                                          User{" "}
                                          <span className="text-secondary">
                                            Jack365
                                          </span>{" "}
                                          submitted a ticket - 2 week Ago
                                        </small>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div className="accordion-item border-0">
                                <div
                                  className="accordion-header"
                                  id="heading10"
                                >
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapse10"
                                    aria-expanded="true"
                                  >
                                    <div className="d-flex">
                                      <div className="flex-shrink-0">
                                        <img
                                          src="assets/images/users/avatar-5.jpg"
                                          alt=""
                                          className="avatar-xs rounded-circle material-shadow"
                                        />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-1">
                                          Jennifer Carter
                                        </h6>
                                        <small className="text-muted">
                                          Commented - 4 week Ago
                                        </small>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  id="collapse10"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="heading10"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body ms-2 ps-5">
                                    <p className="text-muted fst-italic mb-2">
                                      " This is an awesome admin dashboard
                                      template. It is extremely well structured
                                      and uses state of the art components (e.g.
                                      one of the only templates using boostrap
                                      5.1.3 so far). I integrated it into a
                                      Rails 6 project. Needs manual integration
                                      work of course but the template structure
                                      made it easy. "
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*end accordion*/}
                          </div>
                        </div>
                        <div className="tab-pane" id="monthly" role="tabpanel">
                          <div className="profile-timeline">
                            <div
                              className="accordion accordion-flush"
                              id="monthlyExample"
                            >
                              <div className="accordion-item border-0">
                                <div
                                  className="accordion-header"
                                  id="heading11"
                                >
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapse11"
                                    aria-expanded="false"
                                  >
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 avatar-xs">
                                        <div className="avatar-title bg-light text-success rounded-circle material-shadow">
                                          M
                                        </div>
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-1">
                                          Megan Elmore
                                        </h6>
                                        <small className="text-muted">
                                          Adding a new event with attachments -
                                          1 month Ago.
                                        </small>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  id="collapse11"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="heading11"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body ms-2 ps-5">
                                    <div className="row g-2">
                                      <div className="col-auto">
                                        <div className="d-flex border border-dashed p-2 rounded position-relative">
                                          <div className="flex-shrink-0">
                                            <i className="ri-image-2-line fs-17 text-danger" />
                                          </div>
                                          <div className="flex-grow-1 ms-2">
                                            <h6 className="mb-0">
                                              <a
                                                href="javascript:void(0);"
                                                className="stretched-link"
                                              >
                                                Business Template - UI/UX design
                                              </a>
                                            </h6>
                                            <small>685 KB</small>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-auto">
                                        <div className="d-flex border border-dashed p-2 rounded position-relative">
                                          <div className="flex-shrink-0">
                                            <i className="ri-file-zip-line fs-17 text-info" />
                                          </div>
                                          <div className="flex-grow-1 ms-2">
                                            <h6 className="mb-0">
                                              <a
                                                href="javascript:void(0);"
                                                className="stretched-link"
                                              >
                                                Bank Management System - PSD
                                              </a>
                                            </h6>
                                            <small>8.78 MB</small>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-auto">
                                        <div className="d-flex border border-dashed p-2 rounded position-relative">
                                          <div className="flex-shrink-0">
                                            <i className="ri-file-zip-line fs-17 text-info" />
                                          </div>
                                          <div className="flex-grow-1 ms-2">
                                            <h6 className="mb-0">
                                              <a
                                                href="javascript:void(0);"
                                                className="stretched-link"
                                              >
                                                Bank Management System - PSD
                                              </a>
                                            </h6>
                                            <small>8.78 MB</small>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item border-0">
                                <div
                                  className="accordion-header"
                                  id="heading12"
                                >
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapse12"
                                    aria-expanded="true"
                                  >
                                    <div className="d-flex">
                                      <div className="flex-shrink-0">
                                        <img
                                          src="assets/images/users/avatar-2.jpg"
                                          alt=""
                                          className="avatar-xs rounded-circle material-shadow"
                                        />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-1">
                                          Jacqueline Steve
                                        </h6>
                                        <small className="text-muted">
                                          We has changed 2 attributes on 3 month
                                          Ago
                                        </small>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  id="collapse12"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="heading12"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body ms-2 ps-5">
                                    In an awareness campaign, it is vital for
                                    people to begin put 2 and 2 together and
                                    begin to recognize your cause. Too much or
                                    too little spacing, as in the example below,
                                    can make things unpleasant for the reader.
                                    The goal is to make your text as comfortable
                                    to read as possible. A wonderful serenity
                                    has taken possession of my entire soul, like
                                    these sweet mornings of spring which I enjoy
                                    with my whole heart.
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item border-0">
                                <div
                                  className="accordion-header"
                                  id="heading13"
                                >
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapse13"
                                    aria-expanded="false"
                                  >
                                    <div className="d-flex">
                                      <div className="flex-shrink-0">
                                        <img
                                          src="assets/images/users/avatar-5.jpg"
                                          alt=""
                                          className="avatar-xs rounded-circle material-shadow"
                                        />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-1">
                                          New ticket received
                                        </h6>
                                        <small className="text-muted mb-2">
                                          User{" "}
                                          <span className="text-secondary">
                                            Erica245
                                          </span>{" "}
                                          submitted a ticket - 5 month Ago
                                        </small>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div className="accordion-item border-0">
                                <div
                                  className="accordion-header"
                                  id="heading14"
                                >
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapse14"
                                    aria-expanded="true"
                                  >
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 avatar-xs">
                                        <div className="avatar-title bg-light text-muted rounded-circle material-shadow">
                                          <i className="ri-user-3-fill" />
                                        </div>
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-1">
                                          Nancy Martino
                                        </h6>
                                        <small className="text-muted">
                                          Commented on 24 Nov, 2021.
                                        </small>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  id="collapse14"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="heading14"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body ms-2 ps-5 fst-italic">
                                    " A wonderful serenity has taken possession
                                    of my entire soul, like these sweet mornings
                                    of spring which I enjoy with my whole heart.
                                    Each design is a new, unique piece of art
                                    birthed into this world, and while you have
                                    the opportunity to be creative and make your
                                    own style choices. "
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item border-0">
                                <div
                                  className="accordion-header"
                                  id="heading15"
                                >
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapse15"
                                    aria-expanded="true"
                                  >
                                    <div className="d-flex">
                                      <div className="flex-shrink-0">
                                        <img
                                          src="assets/images/users/avatar-7.jpg"
                                          alt=""
                                          className="avatar-xs rounded-circle material-shadow"
                                        />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-1">
                                          Lewis Arnold
                                        </h6>
                                        <small className="text-muted">
                                          Create new project buildng product - 8
                                          month Ago
                                        </small>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  id="collapse15"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="heading15"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body ms-2 ps-5">
                                    <p className="text-muted mb-2">
                                      Every team project can have a velzon. Use
                                      the velzon to share information with your
                                      team to understand and contribute to your
                                      project.
                                    </p>
                                    <div className="avatar-group">
                                      <a
                                        href="javascript: void(0);"
                                        className="avatar-group-item material-shadow"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title=""
                                        data-bs-original-title="Christi"
                                      >
                                        <img
                                          src="assets/images/users/avatar-4.jpg"
                                          alt=""
                                          className="rounded-circle avatar-xs"
                                        />
                                      </a>
                                      <a
                                        href="javascript: void(0);"
                                        className="avatar-group-item material-shadow"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title=""
                                        data-bs-original-title="Frank Hook"
                                      >
                                        <img
                                          src="assets/images/users/avatar-3.jpg"
                                          alt=""
                                          className="rounded-circle avatar-xs"
                                        />
                                      </a>
                                      <a
                                        href="javascript: void(0);"
                                        className="avatar-group-item material-shadow"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title=""
                                        data-bs-original-title=" Ruby"
                                      >
                                        <div className="avatar-xs">
                                          <div className="avatar-title rounded-circle bg-light text-primary">
                                            R
                                          </div>
                                        </div>
                                      </a>
                                      <a
                                        href="javascript: void(0);"
                                        className="avatar-group-item material-shadow"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title=""
                                        data-bs-original-title="more"
                                      >
                                        <div className="avatar-xs">
                                          <div className="avatar-title rounded-circle">
                                            2+
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*end accordion*/}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*end card-body*/}
                  </div>
                  {/*end card*/}
                </div>
                {/*end tab-pane*/}

                {/*end tab-pane*/}
              </div>
              {/*end tab-content*/}
            </div>
          </div>
          {/*end col*/}
        </div>
        {/*end row*/}
      </div>
    </>
  );
};
const ModalHandler = (prop) => ({
  value: prop.modalHandler
})

export default connect(ModalHandler)(BatchManagement);
