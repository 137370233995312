import React, { useContext, useEffect, useState } from 'react'
import DemoData from '../../data/DemoData'
import UserItem from './UserItem'
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import ENDPOINT from '../../context/EndPoint';

const UsersList = ({permission}) => {

  

  const { user } = useContext(AuthContext);
  const userdata = user;

  const [category, setcategory] = useState("all");

  const [query, setquery] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [internships, setinternships] = useState(null);

  useEffect(() => {
    getUsers();
  }, [category]);

  const [loading, setloading] = useState(true);

  const getUsers = async (status) => {
  
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append("session", user.SessionId);

    if (category !== null) {
      formData.append("status", category);
    }

    if (query !== null) {
      formData.append("query", query);
    }

    const res = await axios.post(ENDPOINT + "admin-get-users.php", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    if (res) {
      setloading(false);
      setquery(null);

      if (res.data.status === "success") {
     setinternships(res.data)
        setResponse(res.data.data);
        setInititalData(res.data.data)
      } else {
      } 
    } else {
      setloading(false);
    }
  };

   // console.log(internships?internships.total_user:null)

  const [AddPoints, setAddPoints] = useState(false);

  const showAddPoints = () => {
    setAddPoints(true);
  };

  const closeAddPoints = () => {
    setAddPoints(false);
  };

  const ChangeAccountStatus = async ({ user_id, status }) => {
    const formData = new FormData();
    formData.append("session", user.SessionId);
    formData.append("user_id", user_id);
    formData.append("status", status);

    const res = await axios.post(
      ENDPOINT + "admin-change-account-status.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    if (res) {
    }
    if (res.data.status === "success") {
      settoasttitle(res.data.title);
      settoastmessage(res.data.message);
      settoast(true);
      getUsers();
    } else {
      settoasttitle(res.data.title);
      settoastmessage(res.data.message);
      settoast(true);
    }
  };

  const DeleteUser = async ({ user_id }) => {
    const formData = new FormData();
    formData.append("session", user.SessionId);
    formData.append("user_id", user_id);
    if (query) {
      formData.append("query", query);
    }

    const res = await axios.post(ENDPOINT + "admin-delete-user.php", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });

    if (res) {
      //  // console.log(" deleted "+JSON.stringify(res))
    }
    if (res.data.status === "success") {
      settoasttitle("User Deleted !");
      settoastmessage("User account successfully deleted");
      settoast(true);
      getUsers();
    } else {
      settoasttitle("Something went trong !");
      settoastmessage("Account deletion failed . Please try after sometime .");
      settoast(true);
    }
  };

  const SendCode = async ({ user_email }) => {
    const formData = new FormData();
    formData.append("session", user.SessionId);
    formData.append("user_email", user_email);

    const res = await axios.post(ENDPOINT + "admin-send-otp.php", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });

    if (res) {
    }
    if (res.data.status === "success") {
      settoasttitle("OTP Sent !");
      settoastmessage("OTP has been successfully sent .");
      settoast(true);
      //  // console.log("deleted  "+user_email)
      getUsers();
    } else {
      settoasttitle("Something went wrong !");
      settoastmessage("Please try after sometime");
      settoast(true);
    }
  };

  const [currentuser, setcurrentuser] = useState(null);
  const [activity, setactivity] = useState(false);

  const userActivity = ({ userdata }) => {
    setcurrentuser(userdata);
    setactivity(true);
  };

  const userProfile = ({ userdata }) => {
    setcurrentuser(userdata);
    setprofile(true);
  };
  const [toast, settoast] = useState(false);
  const [toasttitle, settoasttitle] = useState(null);
  const [toastmessage, settoastmessage] = useState(null);
  const [profile, setprofile] = useState(false);


  const [initialData,setInititalData] = useState(null)
  
  const [response, setResponse] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();

    const filteredData = initialData.filter((item) => {
      return (
       
        item.user_id.includes(term) ||
        item.first_name.toLowerCase().includes(term) ||
        item.last_name.toLowerCase().includes(term) ||
        item.status.toLowerCase().includes(term) 
      );
    });

    setResponse(filteredData);
    setSearchTerm(term);
  };



  return (
<>

<div className="container-fluid">

<div className="row">
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                <i className="ri-arrow-down-circle-fill align-middle" />
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                Total users{" "}
                              </p>
                              <h4 className=" mb-0">
                                {internships?internships.total_user.total_user:null}
                                <span
                                  className="counter-value"
                                  data-target="14799.44"
                                >
                                 
                                </span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0 align-self-end">
                              <span className="badge bg-danger-subtle text-danger">
                                <i className="ri-arrow-down-s-fill align-middle me-1" />
                                4.80 %<span> </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}

                    {/* end col */}
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                <i className="ri-arrow-down-circle-fill align-middle" />
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                Active users{" "}
                              </p>
                              <h4 className=" mb-0">
                                {internships?internships.active_user.active_user:null}
                                <span
                                  className="counter-value"
                                  data-target="14799.44"
                                >
                               
                                </span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0 align-self-end">
                              <span className="badge bg-danger-subtle text-danger">
                                <i className="ri-arrow-down-s-fill align-middle me-1" />
                                4.80 %<span> </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}

                    {/* end col */}
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                <i className="ri-arrow-down-circle-fill align-middle" />
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                             inactive users {" "}
                              </p>
                              <h4 className=" mb-0">
                                
                                <span
                                  className="counter-value"
                                  data-target="14799.44"
                                >
                              {internships?internships.inactive_user.inactive_user:null}
                                </span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0 align-self-end">
                              <span className="badge bg-danger-subtle text-danger">
                                <i className="ri-arrow-down-s-fill align-middle me-1" />
                                4.80 %<span> </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}

                    {/* end col */}
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                <i className="ri-arrow-down-circle-fill align-middle" />
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                               Avg monthly{" "}
                              </p>
                              <h4 className=" mb-0">
                                
                                <span
                                  className="counter-value"
                                  data-target="14799.44"
                                >
                                  {internships?internships.no_of_users_month.no_of_user_month:null}
                                </span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0 align-self-end">
                              <span className="badge bg-danger-subtle text-danger">
                                <i className="ri-arrow-down-s-fill align-middle me-1" />
                                4.80 %<span> </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}
                  </div>
         
          <div className="row">
            <div className="col-lg-12">
              <div className="card" id="customerList">
                <div className="card-header border-bottom-dashed">
                  <div className="row g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Users List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex flex-wrap align-items-start gap-2">
                        <button
                          className="btn btn-soft-danger"
                          id="remove-actions"
                          onclick="deleteMultiple()"
                        >
                          <i className="ri-delete-bin-2-line" />
                        </button>
                        <button
                          type="button"
                          className="btn btn-success add-btn"
                          data-bs-toggle="modal"
                          id="create-btn"
                          data-bs-target="#showModal"
                        >
                          <i className="ri-add-line align-bottom me-1" /> Add
                          User
                        </button>
                        <button type="button" className="btn btn-info">
                          <i className="ri-file-download-line align-bottom me-1" />{" "}
                          Import
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body border-bottom-dashed border-bottom">
                  <form>
                    <div className="row g-3">
                      <div className="col-xl-6">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                
                            onChange={handleSearch}
                            placeholder="Search for name, email or user id "
                          />
                          <i className="ri-search-line search-icon" />
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xl-6">
                        <div className="row g-3">
                          <div className="col-sm-4">
                            <div className="">
                              <input
                                type="date"
                                onChange={handleSearch}
                                className="form-control flatpickr-input"
                                id="datepicker-range"
                                data-provider="flatpickr"
                                data-date-format="d M, Y"
                                data-range-date="true"
                                placeholder="Select date"
                             
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          <div className="col-sm-4">
                            <div>
                              <div
                                className="choices"
                                data-type="select-one"
                                tabIndex={0}
                                role="listbox"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <div className="">
                                  <select
                                    className="form-control choices__input"
                                    data-plugin="choices"
                                    data-choices=""
                                    data-choices-search-false=""
                                    name="choices-single-default"
                                    id="idStatus"
                                    hidden=""
                                    tabIndex={-1}
                                    data-choice="active"
                                  >
                                    <option
                                      value="all"  >
                                      All
                                    </option>
                                  </select>
                                  
                                </div>
                              
                              </div>
                            </div>
                          </div>
                          {/*end col*/}
                          <div className="col-sm-4">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary w-100"
                                onclick="SearchData();"
                              >
                                {" "}
                                <i className="ri-equalizer-fill me-2 align-bottom" />
                                Filters
                              </button>
                            </div>
                          </div>
                          {/*end col*/}
                        </div>
                      </div>
                    </div>
                    {/*end row*/}
                  </form>
                </div>
                <div className="card-body">
                  <div>
                    <div className="table-responsive table-card mb-1">
                      <table className="table align-middle" id="customerTable">
                        <thead className="table-light text-muted">
                          <tr>
                            <th scope="col" style={{ width: 50 }}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="checkAll"
                                  defaultValue="option"
                                />
                              </div>
                            </th>
                            <th className="sort" data-sort="customer_name">
                              Customer
                            </th>
                            <th className="sort" data-sort="email">
                              Email
                            </th>
                            <th className="sort" data-sort="phone">
                              Phone
                            </th>
                            <th className="sort" data-sort="date">
                              Joining Date
                            </th>
                            <th className="sort" data-sort="status">
                              Status
                            </th>
                            <th className="sort" data-sort="action">
                              Action
                            </th>
                          </tr>
                        </thead>
                    <tbody>

{response?
<>

{response.map((item)=>(
  <>
  <UserItem permission={permission} item={item}/>
  
  </>
))}
</>:
<>

</>}

                    </tbody>
                      </table>
                      <div className="noresult" style={{ display: "none" }}>
                        <div className="text-center">
                          <lord-icon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            trigger="loop"
                            colors="primary:#121331,secondary:#08a88a"
                            style={{ width: 75, height: 75 }}
                          />
                          <h5 className="mt-2">Sorry! No Result Found</h5>
                          <p className="text-muted mb-0">
                            We've searched more than 150+ customer We did not
                            find any customer for you search.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div
                        className="pagination-wrap hstack gap-2"
                        style={{ display: "flex" }}
                      >
                        <a
                          className="page-item pagination-prev disabled"
                          href="#"
                        >
                          Previous
                        </a>
                        <ul className="pagination listjs-pagination mb-0">
                          <li className="active">
                            <a
                              className="page"
                              href="#"
                              data-i={1}
                              data-page={8}
                            >
                              1
                            </a>
                          </li>
                          <li>
                            <a
                              className="page"
                              href="#"
                              data-i={2}
                              data-page={8}
                            >
                              2
                            </a>
                          </li>
                        </ul>
                        <a className="page-item pagination-next" href="#">
                          Next
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id="showModal"
                    tabIndex={-1}
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header bg-light p-3">
                          <h5 className="modal-title" id="exampleModalLabel" />
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="close-modal"
                          />
                        </div>
                        <form className="tablelist-form" autoComplete="off">
                          <div className="modal-body">
                            <input type="hidden" id="id-field" />
                            <div
                              className="mb-3"
                              id="modal-id"
                              style={{ display: "none" }}
                            >
                              <label htmlFor="id-field1" className="form-label">
                                ID
                              </label>
                              <input
                                type="text"
                                id="id-field1"
                                className="form-control"
                                placeholder="ID"
                                readOnly=""
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="customername-field"
                                className="form-label"
                              >
                                Customer Name
                              </label>
                              <input
                                type="text"
                                id="customername-field"
                                className="form-control"
                                placeholder="Enter name"
                                required=""
                              />
                              <div className="invalid-feedback">
                                Please enter a customer name.
                              </div>
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="email-field"
                                className="form-label"
                              >
                                Email
                              </label>
                              <input
                                type="email"
                                id="email-field"
                                className="form-control"
                                placeholder="Enter email"
                                required=""
                              />
                              <div className="invalid-feedback">
                                Please enter an email.
                              </div>
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="phone-field"
                                className="form-label"
                              >
                                Phone
                              </label>
                              <input
                                type="text"
                                id="phone-field"
                                className="form-control"
                                placeholder="Enter phone no."
                                required=""
                              />
                              <div className="invalid-feedback">
                                Please enter a phone.
                              </div>
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="date-field"
                                className="form-label"
                              >
                                Joining Date
                              </label>
                              <input
                                type="text"
                                id="date-field"
                                className="form-control flatpickr-input"
                                data-provider="flatpickr"
                                data-date-format="d M, Y"
                                required=""
                                placeholder="Select date"
                                readOnly="readonly"
                              />
                              <div className="invalid-feedback">
                                Please select a date.
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="status-field"
                                className="form-label"
                              >
                                Status
                              </label>
                              <div
                                className="choices"
                                data-type="select-one"
                                tabIndex={0}
                                role="combobox"
                                aria-autocomplete="list"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <div className="choices__inner">
                                  <select
                                    className="form-control choices__input"
                                    data-choices=""
                                    data-choices-search-false=""
                                    name="status-field"
                                    id="status-field"
                                    required=""
                                    hidden=""
                                    tabIndex={-1}
                                    data-choice="active"
                                  >
                                    <option
                                      value=""
                                      data-custom-properties="[object Object]"
                                    >
                                      Status
                                    </option>
                                  </select>
                                  <div className="choices__list choices__list--single">
                                    <div
                                      className="choices__item choices__placeholder choices__item--selectable"
                                      data-item=""
                                      data-id={1}
                                      data-value=""
                                      data-custom-properties="[object Object]"
                                      aria-selected="true"
                                    >
                                      Status
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="choices__list choices__list--dropdown"
                                  aria-expanded="false"
                                >
                                  <input
                                    type="search"
                                    name="search_terms"
                                    className="choices__input choices__input--cloned"
                                    autoComplete="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                    role="textbox"
                                    aria-autocomplete="list"
                                    aria-label="Status"
                                    placeholder=""
                                  />
                                  <div className="choices__list" role="listbox">
                                    <div
                                      id="choices--status-field-item-choice-3"
                                      className="choices__item choices__item--choice is-selected choices__placeholder choices__item--selectable is-highlighted"
                                      role="option"
                                      data-choice=""
                                      data-id={3}
                                      data-value=""
                                      data-select-text="Press to select"
                                      data-choice-selectable=""
                                      aria-selected="true"
                                    >
                                      Status
                                    </div>
                                    <div
                                      id="choices--status-field-item-choice-1"
                                      className="choices__item choices__item--choice choices__item--selectable"
                                      role="option"
                                      data-choice=""
                                      data-id={1}
                                      data-value="Active"
                                      data-select-text="Press to select"
                                      data-choice-selectable=""
                                    >
                                      Active
                                    </div>
                                    <div
                                      id="choices--status-field-item-choice-2"
                                      className="choices__item choices__item--choice choices__item--selectable"
                                      role="option"
                                      data-choice=""
                                      data-id={2}
                                      data-value="Block"
                                      data-select-text="Press to select"
                                      data-choice-selectable=""
                                    >
                                      Block
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <div className="hstack gap-2 justify-content-end">
                              <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                type="submit"
                                className="btn btn-success"
                                id="add-btn"
                              >
                                Add Customer
                              </button>
                              {/* <button type="button" class="btn btn-success" id="edit-btn">Update</button> */}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
             
                </div>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>

</>
  )
}

export default UsersList