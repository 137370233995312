import styled from "styled-components";
import PieChart from "../../components/charts/PieChart";
import Users from "../users/Users";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import ENDPOINT from "../../context/EndPoint";
import { Info } from "react-bootstrap-icons";
import { NavItem } from "react-bootstrap";
import ClassesCalender from "../classes/ClassesCalender";
import axios from "axios";
import ClassesList from "../classes/ClassesList";
import { Avatar } from "@mui/material";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ClassesManagement from "../classes/ClassesManagement";
import { Link } from "react-router-dom";

function ClassManagerDashboard() {
  const { user } = useContext(AuthContext);
  const [stats, setStats] = useState(null);
  const [classes, setclasses] = useState(null);
  const getChapters = async () => {
    const formData = new FormData();

    //formData.append('whyus',document.getElementById('whyus').value)

    formData.append("session", user.SessionId);

    const res = await axios.post(
      ENDPOINT + "get-all-class-schedules.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    if (res) {
      if (res.data.status === "success") {
        setclasses(res.data.data);
      } else {
      }
    }


    const response = await axios.post(
      ENDPOINT + "class-dashboard.php",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    if (response) {
      if (response.data.status === "success") {
        setStats(response.data);
      } else {
      }
    }
  };

  useEffect(() => {
    getChapters()
  }, []);

  return (
    <>
      {stats ? (
        <>
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="h-100">
                  {/*end row*/}
                  <div className="row">
                    <div className="col-xl-3 col-md-6 col-sm-6 col-6 p-2">
                      {/* card */}
                      <div className="card card-animate">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-muted text-dark text-truncate mb-0">
                                {" "}
                                Total Class Taken This Year
                              </p>
                            </div>

                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-4">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-4">

                                <span
                                  className="counter-value"
                                  data-target="559.25"
                                >
                                  {stats?.year_count??0}
                                </span>

                              </h4>

                            </div>
                            <div className='grn px-1 rounded-pill'>
                              <TrendingUpIcon />
                              <b>90%</b>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}
                    <div className="col-xl-3 col-md-6 col-sm-6 col-6 p-2">
                      {/* card */}
                      <div className="card card-animate">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                Total Class Taken This Month
                              </p>
                            </div>

                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-4">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                <span
                                  className="counter-value"
                                  data-target={36894}
                                >
                                  {stats?.month_count??0}
                                </span>
                              </h4>
                              
                            </div>
                            <div className='grn px-1 rounded-pill'>
                              <TrendingUpIcon />
                              <b>90%</b>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>

                    {/* end col */}
                    <div className="col-xl-3 col-md-6 col-sm-6 col-6 p-2">
                      {/* card */}
                      <div className="card card-animate">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                Upcoming Classes
                              </p>
                            </div>

                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-4">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                <span
                                  className="counter-value"
                                  data-target="183.35"
                                >
                                  {stats?.upcoming_count??0}
                                </span>
                              </h4>
                             
                            </div>
                            <div className='grn px-1 rounded-pill'>
                              <TrendingUpIcon />
                              <b>90%</b>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}

                    {/* end col */}
                    <div className="col-xl-3 col-md-6 col-sm-6 col-6 p-2">
                      {/* card */}
                      <div className="card card-animate">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                Pending Classes
                              </p>
                            </div>

                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-4">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                <span
                                  className="counter-value"
                                  data-target="183.35"
                                >
                                  {stats?.pending_count??0}
                                </span>
                              </h4>
                              
                            </div>
                            <div className='grn px-1 rounded-pill'>
                              <TrendingUpIcon />
                              <b>90%</b>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}
                  </div>{" "}
                  {/* end row*/}
                  <div className="row">
                    <div className="d-flex flex-wrap  mt-4">
                      <div className="col-lg-5 col-md-9 col-sm-9 col-12">
                        <div className="card w-100">
                          <div className="card-header border-0 align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">
                              Classes Calender
                            </h4>
                            <div>
                              <div className="d-flex">
                                <button
                                  type="button"
                                  className="btn btn-soft-secondary btn-sm material-shadow-none me-1"
                                >
                                  7D
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-soft-secondary btn-sm material-shadow-none me-1"
                                >
                                  1M
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-soft-secondary btn-sm material-shadow-none me-1"
                                >
                                  1Y
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-soft-primary btn-sm material-shadow-none"
                                >
                                  ALL
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* end card header */}
                          <div className="card-body pt-0">
                            {classes ? (
                              <>
                                <ClassesCalender classSchedules={classes} />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>{" "}
                      </div>

                      {/*Graphical data */}


                      <div className="col-lg-4 col-md-6 col-sm-8 col-12 ps-2">
                        <div className="card">
                          <div className="card-header align-items-center d-flex">
                            <div className="d-flex justify-content-between w-100">
                              <h4 className="card-title mb-0 ">
                                Upcoming Classes
                              </h4>
                            
                            </div>
                            <div className="flex-shrink-0">
                             <Link to={"/classes"} className="text-decoration-none h5 text-primary">VIEW ALL</Link>
                            </div>
                          </div>
                          {/* end card header */}
               <ClassesManagement options={{search:false,action:false,title:false,limit:5}}/>
                          {/* end card body */}
                        </div>

                        <div className="card card-height-100 d-none">
                          <div className="card-header border-0 align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">
                              <div className="dropdown">
                                <button
                                  className="btn btn-soft-primary btn-sm material-shadow-none"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <span className="text-uppercase">
                                    Student Doubt Requests
                                    <i className="mdi mdi-chevron-down align-middle ms-1" />
                                  </span>
                                </button>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <a className="dropdown-item" href="#">
                                    Customer Support Requests
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    Trainer Support Requests
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    College Support Requests
                                  </a>

                                  <a className="dropdown-item" href="#">
                                    Leave Requests
                                  </a>
                                </div>
                              </div>
                            </h4>
                            <div></div>
                          </div>

                          <PieChart />
                          {/* end cardheader */}
                          <div className="card-body">
                            <div
                              id="portfolio_donut_charts"
                              data-colors='["--vz-primary", "--vz-info", "--vz-warning", "--vz-success"]'
                              data-colors-minimal='["--vz-primary", "--vz-primary-rgb, 0.85", "--vz-primary-rgb, 0.65", "--vz-primary-rgb, 0.50"]'
                              data-colors-interactive='["--vz-primary", "--vz-primary-rgb, 0.85", "--vz-primary-rgb, 0.65", "--vz-primary-rgb, 0.50"]'
                              data-colors-corporate='["--vz-primary", "--vz-secondary", "--vz-info", "--vz-success"]'
                              data-colors-galaxy='["--vz-primary", "--vz-primary-rgb, 0.85", "--vz-primary-rgb, 0.65", "--vz-primary-rgb, 0.50"]'
                              className="apex-charts"
                              dir="ltr"
                            />
                            <ul className="list-group list-group-flush border-dashed mb-0 mt-3 pt-2">
                              <li className="list-group-item px-0">
                                <div className="d-flex">
                                  <div className="flex-shrink-0 avatar-xs">
                                    <span className="avatar-title bg-light p-1 rounded-circle material-shadow">
                                      <img
                                        src="assets/images/svg/crypto-icons/btc.svg"
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="flex-grow-1 ms-2">
                                    <h6 className="mb-1">Bitcoin</h6>
                                    <p className="fs-12 mb-0 text-muted">
                                      <i className="mdi mdi-circle fs-10 align-middle text-primary me-1" />
                                      BTC{" "}
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0 text-end">
                                    <h6 className="mb-1">BTC 0.00584875</h6>
                                    <p className="text-success fs-12 mb-0">
                                      $19,405.12
                                    </p>
                                  </div>
                                </div>
                              </li>
                              {/* end */}
                              <li className="list-group-item px-0">
                                <div className="d-flex">
                                  <div className="flex-shrink-0 avatar-xs">
                                    <span className="avatar-title bg-light p-1 rounded-circle material-shadow">
                                      <img
                                        src="assets/images/svg/crypto-icons/eth.svg"
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="flex-grow-1 ms-2">
                                    <h6 className="mb-1">Ethereum</h6>
                                    <p className="fs-12 mb-0 text-muted">
                                      <i className="mdi mdi-circle fs-10 align-middle text-info me-1" />
                                      ETH{" "}
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0 text-end">
                                    <h6 className="mb-1">ETH 2.25842108</h6>
                                    <p className="text-danger fs-12 mb-0">
                                      $40552.18
                                    </p>
                                  </div>
                                </div>
                              </li>
                              {/* end */}
                              <li className="list-group-item px-0">
                                <div className="d-flex">
                                  <div className="flex-shrink-0 avatar-xs">
                                    <span className="avatar-title bg-light p-1 rounded-circle material-shadow">
                                      <img
                                        src="assets/images/svg/crypto-icons/ltc.svg"
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="flex-grow-1 ms-2">
                                    <h6 className="mb-1">Litecoin</h6>
                                    <p className="fs-12 mb-0 text-muted">
                                      <i className="mdi mdi-circle fs-10 align-middle text-warning me-1" />
                                      LTC{" "}
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0 text-end">
                                    <h6 className="mb-1">LTC 10.58963217</h6>
                                    <p className="text-success fs-12 mb-0">
                                      $15824.58
                                    </p>
                                  </div>
                                </div>
                              </li>
                              {/* end */}
                              <li className="list-group-item px-0 pb-0">
                                <div className="d-flex">
                                  <div className="flex-shrink-0 avatar-xs">
                                    <span className="avatar-title bg-light p-1 rounded-circle material-shadow">
                                      <img
                                        src="assets/images/svg/crypto-icons/dash.svg"
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="flex-grow-1 ms-2">
                                    <h6 className="mb-1">Dash</h6>
                                    <p className="fs-12 mb-0 text-muted">
                                      <i className="mdi mdi-circle fs-10 align-middle text-success me-1" />
                                      DASH{" "}
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0 text-end">
                                    <h6 className="mb-1">
                                      DASH 204.28565885
                                    </h6>
                                    <p className="text-success fs-12 mb-0">
                                      $30635.84
                                    </p>
                                  </div>
                                </div>
                              </li>
                              {/* end */}
                            </ul>
                            {/* end */}
                          </div>
                          {/* end card body */}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-8 col-12 ps-2">
                        <div className="card">
                          <div className="card-header align-items-center d-flex">
                            <div className="d-flex justify-content-between w-100">
                              <h4 className="card-title mb-0 ">
                                Students Attendence
                              </h4>
                            
                            </div>
                            <div className="flex-shrink-0">
                              <div className="dropdown card-header-dropdown">
                                <a
                                  className="text-reset dropdown-btn"
                                  href="#"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <span className="text-muted fs-18">
                                    <i className="mdi mdi-dots-vertical" />
                                  </span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <a className="dropdown-item" href="#">
                                    Edit
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    Remove
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* end card header */}
                          <div className="card-body pt-0">
                            <ul className="list-group list-group-flush border-dashed">




                              <li className="list-group-item ps-0">
                                <div className="d-flex align-items-center justify-content-between ">
                                  <div className="d-flex align-items-center">
                                    <Avatar className="me-2" />
                                    <div className="col">
                                      <a
                                        href="#"
                                        className="text-reset fs-14 mb-0"
                                      >
                                        {stats?.today_total_classes??0} Introduction

                                      </a>
                                      <h5 className="text-muted mt-0 mb-1 fs-13">
                                        Website development
                                      </h5>

                                    </div>
                                  </div>
                                  <h5 className="text-muted mt-0 mb-1 fs-13">
                                    {stats?.total_pending_classes??0} 50%
                                  </h5>

                                </div>
                                {/* end row */}
                              </li>
                              {/* end */}



                              <li className="list-group-item ps-0">
                                <div className="d-flex align-items-center justify-content-between ">
                                  <div className="d-flex align-items-center">
                                    <Avatar className="me-2" />
                                    <div className="col">
                                      <a
                                        href="#"
                                        className="text-reset fs-14 mb-0"
                                      >
                                        {stats?.today_total_classes??0} Introduction

                                      </a>
                                      <h5 className="text-muted mt-0 mb-1 fs-13">
                                        Website development
                                      </h5>

                                    </div>
                                  </div>
                                  <h5 className="text-muted mt-0 mb-1 fs-13">
                                    {stats?.total_pending_classes??0} 50%
                                  </h5>

                                </div>
                                {/* end row */}
                              </li>
                              {/* end */}



                              <li className="list-group-item ps-0">
                                <div className="d-flex align-items-center justify-content-between ">
                                  <div className="d-flex align-items-center">
                                    <Avatar className="me-2" />
                                    <div className="col">
                                      <a
                                        href="#"
                                        className="text-reset fs-14 mb-0"
                                      >
                                        {stats?.today_total_classes??0} Introduction

                                      </a>
                                      <h5 className="text-muted mt-0 mb-1 fs-13">
                                        Website development
                                      </h5>

                                    </div>
                                  </div>
                                  <h5 className="text-muted mt-0 mb-1 fs-13">
                                    {stats?.total_pending_classes??0} 50%
                                  </h5>

                                </div>
                                {/* end row */}
                              </li>
                              {/* end */}



                              <li className="list-group-item ps-0">
                                <div className="d-flex align-items-center justify-content-between ">
                                  <div className="d-flex align-items-center">
                                    <Avatar className="me-2" />
                                    <div className="col">
                                      <a
                                        href="#"
                                        className="text-reset fs-14 mb-0"
                                      >
                                        {stats?.today_total_classes??0} Introduction

                                      </a>
                                      <h5 className="text-muted mt-0 mb-1 fs-13">
                                        Website development
                                      </h5>

                                    </div>
                                  </div>
                                  <h5 className="text-muted mt-0 mb-1 fs-13">
                                    {stats?.total_pending_classes??0} 50%
                                  </h5>

                                </div>
                                {/* end row */}
                              </li>
                              {/* end */}



                              <li className="list-group-item ps-0">
                                <div className="d-flex align-items-center justify-content-between ">
                                  <div className="d-flex align-items-center">
                                    <Avatar className="me-2" />
                                    <div className="col">
                                      <a
                                        href="#"
                                        className="text-reset fs-14 mb-0"
                                      >
                                        {stats?.today_total_classes??0} Introduction

                                      </a>
                                      <h5 className="text-muted mt-0 mb-1 fs-13">
                                        Website development
                                      </h5>

                                    </div>
                                  </div>
                                  <h5 className="text-muted mt-0 mb-1 fs-13">
                                    {stats?.total_pending_classes??0} 50%
                                  </h5>

                                </div>
                                {/* end row */}
                              </li>
                              {/* end */}











                            </ul>
                            {/* end */}
                         
                          </div>
                          {/* end card body */}
                        </div>

                        <div className="card card-height-100 d-none ">
                          <div className="card-header border-0 align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">
                              <div className="dropdown">
                                <button
                                  className="btn btn-soft-primary btn-sm material-shadow-none"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <span className="text-uppercase">
                                    Student Doubt Requests
                                    <i className="mdi mdi-chevron-down align-middle ms-1" />
                                  </span>
                                </button>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <a className="dropdown-item" href="#">
                                    Customer Support Requests
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    Trainer Support Requests
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    College Support Requests
                                  </a>

                                  <a className="dropdown-item" href="#">
                                    Leave Requests
                                  </a>
                                </div>
                              </div>
                            </h4>
                            <div></div>
                          </div>

                          <PieChart />
                          {/* end cardheader */}
                          <div className="card-body">
                            <div
                              id="portfolio_donut_charts"
                              data-colors='["--vz-primary", "--vz-info", "--vz-warning", "--vz-success"]'
                              data-colors-minimal='["--vz-primary", "--vz-primary-rgb, 0.85", "--vz-primary-rgb, 0.65", "--vz-primary-rgb, 0.50"]'
                              data-colors-interactive='["--vz-primary", "--vz-primary-rgb, 0.85", "--vz-primary-rgb, 0.65", "--vz-primary-rgb, 0.50"]'
                              data-colors-corporate='["--vz-primary", "--vz-secondary", "--vz-info", "--vz-success"]'
                              data-colors-galaxy='["--vz-primary", "--vz-primary-rgb, 0.85", "--vz-primary-rgb, 0.65", "--vz-primary-rgb, 0.50"]'
                              className="apex-charts"
                              dir="ltr"
                            />
                            <ul className="list-group list-group-flush border-dashed mb-0 mt-3 pt-2">
                              <li className="list-group-item px-0">
                                <div className="d-flex">
                                  <div className="flex-shrink-0 avatar-xs">
                                    <span className="avatar-title bg-light p-1 rounded-circle material-shadow">
                                      <img
                                        src="assets/images/svg/crypto-icons/btc.svg"
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="flex-grow-1 ms-2">
                                    <h6 className="mb-1">Bitcoin</h6>
                                    <p className="fs-12 mb-0 text-muted">
                                      <i className="mdi mdi-circle fs-10 align-middle text-primary me-1" />
                                      BTC{" "}
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0 text-end">
                                    <h6 className="mb-1">BTC 0.00584875</h6>
                                    <p className="text-success fs-12 mb-0">
                                      $19,405.12
                                    </p>
                                  </div>
                                </div>
                              </li>
                              {/* end */}
                              <li className="list-group-item px-0">
                                <div className="d-flex">
                                  <div className="flex-shrink-0 avatar-xs">
                                    <span className="avatar-title bg-light p-1 rounded-circle material-shadow">
                                      <img
                                        src="assets/images/svg/crypto-icons/eth.svg"
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="flex-grow-1 ms-2">
                                    <h6 className="mb-1">Ethereum</h6>
                                    <p className="fs-12 mb-0 text-muted">
                                      <i className="mdi mdi-circle fs-10 align-middle text-info me-1" />
                                      ETH{" "}
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0 text-end">
                                    <h6 className="mb-1">ETH 2.25842108</h6>
                                    <p className="text-danger fs-12 mb-0">
                                      $40552.18
                                    </p>
                                  </div>
                                </div>
                              </li>
                              {/* end */}
                              <li className="list-group-item px-0">
                                <div className="d-flex">
                                  <div className="flex-shrink-0 avatar-xs">
                                    <span className="avatar-title bg-light p-1 rounded-circle material-shadow">
                                      <img
                                        src="assets/images/svg/crypto-icons/ltc.svg"
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="flex-grow-1 ms-2">
                                    <h6 className="mb-1">Litecoin</h6>
                                    <p className="fs-12 mb-0 text-muted">
                                      <i className="mdi mdi-circle fs-10 align-middle text-warning me-1" />
                                      LTC{" "}
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0 text-end">
                                    <h6 className="mb-1">LTC 10.58963217</h6>
                                    <p className="text-success fs-12 mb-0">
                                      $15824.58
                                    </p>
                                  </div>
                                </div>
                              </li>
                              {/* end */}
                              <li className="list-group-item px-0 pb-0">
                                <div className="d-flex">
                                  <div className="flex-shrink-0 avatar-xs">
                                    <span className="avatar-title bg-light p-1 rounded-circle material-shadow">
                                      <img
                                        src="assets/images/svg/crypto-icons/dash.svg"
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="flex-grow-1 ms-2">
                                    <h6 className="mb-1">Dash</h6>
                                    <p className="fs-12 mb-0 text-muted">
                                      <i className="mdi mdi-circle fs-10 align-middle text-success me-1" />
                                      DASH{" "}
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0 text-end">
                                    <h6 className="mb-1">
                                      DASH 204.28565885
                                    </h6>
                                    <p className="text-success fs-12 mb-0">
                                      $30635.84
                                    </p>
                                  </div>
                                </div>
                              </li>
                              {/* end */}
                            </ul>
                            {/* end */}
                          </div>
                          {/* end card body */}
                        </div>
                      </div>

                    </div>
                    {/* end col */}
                  </div>
                 {" "}
                  {/* end row*/}
                </div>{" "}
                {/* end .h-100*/}
              </div>{" "}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default ClassManagerDashboard;

const StyledSuperAdminDashboard = styled.div`
  h1 {
    color: red;
  }
`;
