import React, { useEffect, useState } from 'react'
import { useActionsContext } from '../../context/ActionsContext'
import { useParams } from 'react-router-dom'
import dateFormat from 'dateformat'
import { Avatar } from '@mui/material'
const AllEmployeesLeaveRequests = () => {
const params = useParams()
const intern_id = params.user_id
const internship_id = params.internship_id

  const {internsleaves,fetchInternsLeaves} = useActionsContext()
  useEffect(()=>{
fetchInternsLeaves({payload:{
  intern_id:intern_id,
  internship_id:internship_id
}})
  },[])
  const [initialData,setInitialData] = useState()

const[leave,setLeaves]=useState(null)
const [response, setResponse] = useState(initialData);
const [searchTerm, setSearchTerm] = useState('');

const handleSearch = (e) => {
const term = e.target.value.toLowerCase();

const filteredData = initialData.filter((item) => {
  return (


    item.first_name.toLowerCase().includes(term) ||
    item.last_name.toLowerCase().includes(term) ||
    item.leave_type.toLowerCase().includes(term) ||
    item.start_time.includes(term) ||
    item.status.toLowerCase().includes(term)
  );
});

setResponse(filteredData);
setSearchTerm(term);
};
  

useEffect(()=>{

  if(internsleaves&&internsleaves.leaves){

    setResponse(internsleaves.leaves)
    setInitialData(internsleaves.leaves)
    setLeaves(internsleaves)
  }
},[internsleaves])

 // console.log(leave?leave.today_leaves:null)
  return (
<>
<div className="tab-pane" id="documents" role="tabpanel">

<div className="row">
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                <i className="ri-arrow-down-circle-fill align-middle" />
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                Today-leaves{" "}
                              </p>
                              <h4 className=" mb-0">
                                
                                <span
                                  className="counter-value"
                                  data-target="14799.44"
                                >
                                 {leave?leave.today_leaves.today_leaves:null}
                                </span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0 align-self-end">
                              <span className="badge bg-danger-subtle text-danger">
                                <i className="ri-arrow-down-s-fill align-middle me-1" />
                                4.80 %<span> </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}

                    {/* end col */}
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                <i className="ri-arrow-down-circle-fill align-middle" />
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                Today-leaves{" "}
                              </p>
                              <h4 className=" mb-0">
                                
                                <span
                                  className="counter-value"
                                  data-target="14799.44"
                                >
                               
                                </span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0 align-self-end">
                              <span className="badge bg-danger-subtle text-danger">
                                <i className="ri-arrow-down-s-fill align-middle me-1" />
                                4.80 %<span> </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}

                    {/* end col */}
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                <i className="ri-arrow-down-circle-fill align-middle" />
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                             Pending-leaves {" "}
                              </p>
                              <h4 className=" mb-0">
                                
                                <span
                                  className="counter-value"
                                  data-target="14799.44"
                                >
                              
                                </span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0 align-self-end">
                              <span className="badge bg-danger-subtle text-danger">
                                <i className="ri-arrow-down-s-fill align-middle me-1" />
                                4.80 %<span> </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}

                    {/* end col */}
                    <div className="col-lg-3 col-md-3 col-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                                <i className="ri-arrow-down-circle-fill align-middle" />
                              </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                specialization students{" "}
                              </p>
                              <h4 className=" mb-0">
                                
                                <span
                                  className="counter-value"
                                  data-target="14799.44"
                                >
                                  {}
                                </span>
                              </h4>
                            </div>
                            <div className="flex-shrink-0 align-self-end">
                              <span className="badge bg-danger-subtle text-danger">
                                <i className="ri-arrow-down-s-fill align-middle me-1" />
                                4.80 %<span> </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                      {/* end card */}
                    </div>
                    {/* end col */}
                  </div>
                    <div className="card">
                      <div className="card-body">
                      <div className="card-body border border-dashed border-end-0 border-start-0">
                  <form>
                    <div className="row g-3">
                      <div className="col-xxl-3 col-sm-4">
                        <div className="search-box">
                          <input
                            type="text"

onChange={handleSearch}                            className="form-control search"
                            placeholder="Search for order ID, customer, order status or something..."
                          />
                          <i className="ri-search-line search-icon" />
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-2 col-sm-6">
                        <div>
                          <input
                            type="date"
                            onChange={handleSearch}
                            className="form-control"
                            data-provider="flatpickr"
                            data-date-format="d M, Y"
                            data-range-date="true"
                            id="demo-datepicker"
                          />
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-2 col-sm-4">
                        <div>
                          <select
                            className="form-control"
                            onChange={handleSearch}
                            data-choices=""
                            data-choices-search-false=""
                            name="choices-single-default"
                            id="idStatus"
                          >
                            <option value="">Status</option>
                          
                            <option value="pending">Pending</option>
                            <option value="accepted">Accepted</option>
                         
                          </select>
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-2 col-sm-4 d-none">
                        <div>
                          <select
                            className="form-control"
                            data-choices=""
                            data-choices-search-false=""
                            name="choices-single-default"
                            id="idPayment"
                          >
                            <option value="">Select Payment</option>
                            <option value="all" selected="">
                              All
                            </option>
                            <option value="Mastercard">Mastercard</option>
                            <option value="Paypal">Paypal</option>
                            <option value="Visa">Visa</option>
                            <option value="COD">COD</option>
                          </select>
                        </div>
                      </div>
                      {/*end col*/}
                      <div className="col-xxl-3 col-sm-4">
                        <div className='d-flex d-none'>
                          <button
                            type="button"
                            className="btn me-2 btn-primary "
                            onclick="SearchData();"
                          >
                            {" "}
                            <i className="ri-equalizer-fill me-1 align-bottom" />
                            Filters
                          </button>

                          <button className='btn btn-danger w-fit h-fit' >Add new Task</button>
                         
                        </div>
                      </div>
                      {/*end col*/}
                    </div>
                    {/*end row*/}
                  </form>
                </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="table-responsive">
                              <table className="table table-borderless align-middle mb-0">
                                <thead className="table-light">
                                  <tr>
                                  <th scope="col">Employee</th>
                                    <th scope="col">Leave Type</th>
                                    <th scope="col">Start Date</th>
                                    <th scope="col">End Date</th>
                                    <th scope="col">Requested Time</th>
                                    <th scope="col">Duration</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Actions</th>
                                  </tr>
                                </thead>
                             <>
                             {response&&response?
                             <>
                             {response.map((item)=>(
                              <>
                        

<tr>  
    
    
<td>
                               <div className='d-flex flex-grow-1 align-items-center'>
                               <Avatar className='me-2' src={item.profile_pic}></Avatar>
                               <div>
                                <h5 >{item.first_name+" "+item.last_name}</h5>
                              
                               </div>
                               </div>
                              </td>
     <td>{item.leave_type}</td> 
                                    <td>
                                      <div className="d-flex align-items-center">
                                       
                                        <div className="ms-3 flex-grow-1">
                                          <h6 className="fs-15 mb-0">
                                            <a href="javascript:void(0)">
                                             {dateFormat(item.start_time,"dS mmm yyyy")}
                                            </a>
                                          </h6>
                                        </div>
                                      </div>
                                    </td>
                                    <td>{item.end_time?dateFormat(item.end_time,"dS mmm yyyy hh:mm tt"):null}</td>
                                    <td>{dateFormat(item.create_datetime,"dS mmm yyyy hh:mm tt")}</td>
                                  
                                 
                                     <td>{item.duration}</td>
                                     
                                     <td>{item.status}</td>
                                    <td>
                                      <div className="dropdown">
                                        <a
                                          href="javascript:void(0);"
                                          className="btn btn-light btn-icon"
                                          id="dropdownMenuLink15"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="true"
                                        >
                                          <i className="ri-equalizer-fill" />
                                        </a>
                                        <ul
                                          className="dropdown-menu dropdown-menu-end"
                                          aria-labelledby="dropdownMenuLink15"
                                        >
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <i className="ri-eye-fill me-2 align-middle text-muted" />
                                              View
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <i className="ri-download-2-fill me-2 align-middle text-muted" />
                                              Download
                                            </a>
                                          </li>
                                          <li className="dropdown-divider" />
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <i className="ri-delete-bin-5-line me-2 align-middle text-muted" />
                                              Delete
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                              </>
                             ))}
                             
                             </>:
                             <>
                             
                             
                             </>}
                             </>
                              </table>
                            </div>
                            <div className="text-center mt-3">
                              <a
                                href="javascript:void(0);"
                                className="text-success"
                              >
                                <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2" />{" "}
                                Load more{" "}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

</>
  )
}

export default AllEmployeesLeaveRequests